import styled from "styled-components";
import moment from "moment";

const DOBBox = ({ name, setPayload, value }) => {
  let year = '';
  let month = '';
  let day = '';
  if(value){
    try {
      const dob = value.toDate();
      year = dob.getFullYear();
      month = dob.getMonth()+1;
      day = dob.getDate();
    } catch(e) {

    }
  }
  return (
    <Content>
      <Title>Date of Birth</Title>
      <Input 
        type="number"
        min="1"
        max="31"
        placeholder="DD"
        name="1"
        value={day}
        onChange= { e => { handleChange(e, name, setPayload) } }
      />
      <Input 
        type="number"
        min="1"
        max="12"
        placeholder="MM"
        name="0"
        value={month}
        onChange= { e => { handleChange(e, name, setPayload) } }
      />
      <Input
        type="number"
        min="1953"
        max="2003"
        placeholder="YYYY"
        name="2"
        value={year}
        onChange= { e => { handleChange(e, name, setPayload) } }
      />
    </Content>
  );
};

let DOB = []

const handleChange = (e, name, setPayload) => {
  DOB[e.target.name] = e.target.value;
  if (DOB.length === 3) {
    const dobDate = new Date(DOB.join("/"));
    setPayload(name, dobDate);
  }
}

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const Title = styled.span`
  line-height: 50px;
`;

const Label = styled.label`
`;

const Input = styled.input`
  padding; 0 6px;
`;

export default DOBBox;
