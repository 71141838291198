import styled from "styled-components";
import React, { useState } from 'react';

const GenderBox = ({ name, setPayload, value }) => {
  const [val, setVal] = useState(value);
  
  const handleChange = (e) => {
    setVal(e.target.value);
    setPayload(e.target.name, e.target.value);
  };

  return (
    <Content>
      <Title>Gender</Title>
      <div>
        <Input
          type = 'radio'
          name = { name }
          value = 'MALE'
          id= 'male'
          checked = { val == 'MALE' ? true : false }
          onChange= { e => { handleChange(e) } }
        />
        <Label htmlFor='male'>Male</Label>
      </div>
      <div>
        <Input
          type = 'radio'
          name = { name }
          value = 'FEMALE'
          id = 'female'
          checked = { val == 'FEMALE' ? true : false }
          onChange= { e => { handleChange(e) } }
        />
        <Label htmlFor='female'>Female</Label>
      </div>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const Title = styled.span`
`;

const Label = styled.label`
`;

const Input = styled.input`
  
`;

export default GenderBox;
