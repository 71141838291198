import styled from "styled-components";

import banner from "../images/banner/1.jpeg"

const Banner = () => {
  return (
    <Row id="home">
       <Image src={banner}></Image>
    </Row>
  );
};

const Row = styled.div`
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
}
`;

const Image = styled.img`
  width: 100%;
  height: auto;

  @media (max-width: 768px) {
    margin-top: 66px;
  }
`;

export default Banner;
