import styled from "styled-components";
import { connect } from "react-redux";
import { adminLogin } from '../../store/actions/adminAction';
import React, {useState} from 'react';

const Container = styled.div`
  padding: 0px;
`;

const Section = styled.section`
  display: flex;
  align-content: start;
  min-height: 700px;
  padding-bottom: 138px;
  padding-top: 40px;
  padding: 60px 0;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1128px;
  align-items: center;
  margin: auto;
  @media (max-width: 768px) {
    margin: auto;
    min-height: 0px;
  }
`;

const Form = styled.div`
  margin-top: 100px;
  width: 408px;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const Login = (props) => {
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    
    const handleUserIdChange = (event) => {
        setUserId(event.target.value);
    }
    
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleLogin = () => {
        props.adminLogin(userId, password);
    }

    if(props.user) {
      return null;
    } else {
      return (
        <Container>
        <Section>
            <Form>
            <div><input type="text" id="userName" onChange={handleUserIdChange}/></div>
            <div><input type="text" id="password" onChange={handlePasswordChange}/></div>
            <div><input type="button" id="login" value="Login" onClick={handleLogin}/></div>
            </Form>
        </Section>
        </Container>
    );
    }
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};

const mapDipatchToProps = (dispatch) => ({
    adminLogin: (userId, password) => dispatch(adminLogin(userId, password))
});

export default connect(mapStateToProps, mapDipatchToProps)(Login);
