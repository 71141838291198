import styled from "styled-components";
import React from 'react';
import { connect } from "react-redux";

import { getAllProfiles, setMenu } from '../../store/actions';

const Nav = styled.div`
  padding: 0px;
  font-size: 18px;
  color: #FFFFFF;

  ul, li {
    list-style: none;
    cursor: pointer;
    padding 4px 20px;
  }
  li:hover {
    color: #333333;
    text-decoration: underline;
  }
`;

const Menu = (props) => {
  const handleClick = (e) => {
    props.getAllProfiles();
    props.setMenu(e.currentTarget.id);
  }

  if(props.user && props.user.email === 'test@admin.com') {
    return (
      <Nav>
      <ul>
        <li id="profile" onClick={ handleClick }>Member Profiles</li>
        <li id="profile4approval" onClick={ handleClick }>Profiles For Approval</li>
        <li id="ActivateProfile" onClick={ handleClick }>Reactivate User</li>
        <li id="feature_profile" onClick={ handleClick }>Featured Profiles</li>
        <li>Send Letter</li>
        <li id="searchProfile" onClick={ handleClick }>Member Search</li>
        <li>Edit Profile</li>
      </ul>
      </Nav>
    );
  } else {
    return null;
  }
};

const mapDipatchToProps = (dispatch) => ({
  getAllProfiles: () => dispatch(getAllProfiles()),
  setMenu: (menu) => dispatch(setMenu(menu))
});

export default connect(null, mapDipatchToProps)(Menu);
