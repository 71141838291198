import { auth } from "../../firebase";
import db from "../../firebase";
import { SET_PROFILES, SET_ADMIN_MENU } from "./actionType";

export function adminLogin(userId, password) {
    return (dispatch) => {
        auth.signInWithEmailAndPassword(userId, password).then((payload) => {
            //console.log(payload);
        })
        .catch((error) => alert(error));
    };
}

export function adminLogout() {
    return (dispatch) => {
        auth.signOut()
            .then(() => {
               
            })
            .catch((error) => alert(error));
    };
}

export const setProfiles = (payload) => ({
    type: SET_PROFILES,
    profiles: payload,
});

export function getAllProfiles() {
    return (dispatch) => {
        db.collection("profiles")
            .get()
            .then(querySnapshot => {
                const documents = querySnapshot.docs.map(doc => doc.data());
                dispatch(setProfiles(documents));
            });
    }
}

export const setMenu = (menu) => ({
    type: SET_ADMIN_MENU,
    menu,
});
