import styled from "styled-components";
import { connect } from "react-redux";
import React, { useState } from 'react';

import Dropdown from "../../Shared/Dropdown";
import TextBox from "../../Shared/TextBox";

import { CITIES, COUNTRY, STATE } from "../../../helpers/dbFields";
import { getStates, getCities } from "../../../store/actions";

const Step5 = ({ setPayload, getStates, getCities, states, cities }) => {
  const [country, setCountry] = useState('');
  const [state1, setState1] = useState('');

  const changeCountry = (key, val) => {
    setCountry(val);
    setPayload(key, val);
    getStates(val);
  }
  const changeState1 = (key, val) => {
    setState1(val);
    setPayload(key, val);
    setPayload('state_name', states[val]);
    getCities(country, val);
  }

  return (
    <Content id="step">
      <TextBox placeholder='Flat/House Name, Apartment/Area' setPayload={setPayload} name="address" />
      <label htmlFor="country">Country ***</label>
      <Dropdown 
        placeholder='Country *'
        setPayload={ changeCountry }
        name = "country"
        options= { COUNTRY }
        errorMessage="Please enter country"
        errorType="required"
      />
      <label htmlFor="state">State ***</label>
      <Dropdown 
        placeholder='State'
        setPayload={ changeState1 }
        name = "state"
        options= { states }
        errorMessage="Please enter state"
        errorType="required"
      />
      <label htmlFor="city">City ***</label>
      <Dropdown 
        placeholder='City'
        setPayload={ setPayload }
        name = "city"
        options= { cities }
        errorMessage="Please enter city"
        errorType="required"
      />
      <TextBox
        placeholder='Zip Code ***'
        setPayload={setPayload}
        name="zip_code"
        errorMessage="Please enter zip code"
        errorType="required"
      />
    </Content>
  );
};

const Content = styled.div`
  width: 100%;
`;

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    profile: state.profileState.profile,
    states: state.countriesState.states,
    cities: state.countriesState.cities
  };
};

const mapDipatchToProps = (dispatch) => ({
  getStates: (key) => dispatch(getStates(key)),
  getCities: (county, state) => dispatch(getCities(county, state)),
});

export default connect(mapStateToProps, mapDipatchToProps)(Step5);
