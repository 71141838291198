import { combineReducers } from 'redux';

import useReducer from "./userReducer";
import profileReducer from "./profileReducer";
import adminReducer from "./adminReducer";
import countriesReducer from "./countriesReducer";

const rootReducer = combineReducers({
  userState: useReducer,
  profileState: profileReducer,
  adminState: adminReducer,
  countriesState: countriesReducer,
});

export default rootReducer;
