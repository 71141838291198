import styled from "styled-components";

const TextArea = ({ placeholder, name, setPayload }) => {
  return (
    <Area
      placeholder = { placeholder }
      name = { name }
      onChange= { e => { handleChange(e, setPayload) } }
    ></Area>
  );
};

const handleChange = (e, setPayload) => {
  setPayload(e.target.name, e.target.value);
}

const Area = styled.textarea`
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;

export default TextArea;
