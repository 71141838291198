import db from "../../firebase";
import {
    GET_PROFILE_INTERESTS,
    SET_PROFILE,
    SET_PROFILE_CONTACTS,
    SET_SEARCH_RESULTS,
    SET_SELECTED_PROFILE,
    SET_SELECTED_PROFILE_CONTACTS,
    CLEAR_SEARCH_RESULTS,
    CLEAR_SET_SELECTED_PROFILE
} from "./actionType";

export const setProfile = (payload) => ({
    type: SET_PROFILE,
    profile: payload,
})

export const setProfileContacts = (payload) => ({
    type: SET_PROFILE_CONTACTS,
    profileContacts: payload,
})

export const setSearchResult = (payload) => ({
    type: SET_SEARCH_RESULTS,
    profiles: payload,
})

export const setSelectedProfile = (profile) => ({
    type: SET_SELECTED_PROFILE,
    profile: profile,
})

export const setSelectedProfileContacts = (payload) => ({
    type: SET_SELECTED_PROFILE_CONTACTS,
    profileContacts: payload,
})

export const clearSearchResult = (payload) => ({
    type: CLEAR_SEARCH_RESULTS,
})

export const clearSelectedProfile = (profile) => ({
    type: CLEAR_SET_SELECTED_PROFILE,
})

export function getProfile(user) {
    return (dispatch) => {
        let profile = null;
        db.collection("profiles").where("uid", "==", user.uid)
            .limit(1)
            .get()
            .then(querySnapshot => {
                const documents = querySnapshot.docs.map(doc => doc.data())
                if (documents.length > 0) {
                    const docId = querySnapshot.docs.map(doc => doc.id)
                    profile = {
                        ...{ docId: docId[0] },
                        ...documents[0]
                    };
                    dispatch(getProfileContacts(user, "OWN"));
                    dispatch(setProfile(profile));
                }
            });
    }
}

export function getProfileContacts(user, who) {
    return (dispatch) => {
        let profileContacts = null;
        db.collection("profile_contacts").where("uid", "==", user.uid)
            .limit(1)
            .get()
            .then(querySnapshot => {
                const documents = querySnapshot.docs.map(doc => doc.data())
                if (documents.length > 0) {
                    const docId = querySnapshot.docs.map(doc => doc.id)
                    profileContacts = {
                        ...{ docId: docId[0] },
                        ...documents[0]
                    };
                    if(who === "OWN") {
                        dispatch(setProfileContacts(profileContacts));
                    } else {
                        dispatch(setSelectedProfileContacts(profileContacts));
                    }
                }
            });
    }
}

export function getProfileDocId(user) {
    return Promise.resolve(db.collection("profiles").where("uid", "==", user.uid)
        .limit(1)
        .get()
        .then(querySnapshot => {
            const documents = querySnapshot.docs.map(doc => doc.data())
            if (documents.length > 0) {
                const docId = querySnapshot.docs.map(doc => doc.id)
                return docId[0];
            }
            return null;
        })
    );
}

export function addNewProfile(user) {
    const initProfile = {
        display_name: user.displayName,
        uid: user.uid,
        step: 1,
        approved: false,
        premium: false,
    };
    return Promise.resolve(db.collection("profiles").add(initProfile).then((docRef) => {
        return docRef;
    }).catch((err) => {
        return err
    }));
}

export function addProfile(user) {
    return (dispatch) => {
        addNewProfile(user).then(() => {
            dispatch(addProfileContacts(user.uid, '', user.email, ''));
            dispatch(getProfile(user));
        });
    }
}

export function updateProfile(user, profile, data) {
    return (dispatch) => {
        if(data.mobile && data.mobile !== '') {
            const mobile = data.mobile;
            dispatch(updateProfileContacts(user.uid, mobile));
            data.mobile = '';
        }

        db.collection("profiles").doc(profile.docId).update(data)
            .then((doc) => {
                dispatch(getProfile(user));
            }).catch((err) => {
                console.error(err);
            })
    }
}

export function addUserNameLookup(userName, email) {
    const userNameLookup = {
        userName,
        email,
    };
    return Promise.resolve(db.collection("username_lookup").add(userNameLookup).then((docRef) => {
        return docRef;
    }).catch((err) => {
        return err
    }));
}

/* export function updateProfilePic(user, profile, data) {
    return (dispatch) => {
        db.collection("profiles").doc(profile.docId).update({'profile_pic':data})
            .then((doc) => {
                dispatch(getProfile(user));
            })
    }
} */

export function simpleSearch(selections) {
    return (dispatch) => {
        db.collection("profiles")
            .where("gender", "==", selections.gender)
            .where("religion", "==", selections.religion)
            .where("caste", "==", selections.caste)
            .where("dob", "<=", new Date(selections.dob1))
            .where("dob", ">", new Date(selections.dob2))
            .where("approved", "==", true)
            .get()
            .then(querySnapshot => {
                const profiles = querySnapshot.docs.map(doc => doc.data())
                dispatch(setSearchResult(profiles));
            });
    }
}

export function advancedSearch(selections) {
    return (dispatch) => {
        db.collection("profiles")
            .where("gender", "==", selections.gender)
            .where("religion", "==", selections.religion)
            .where("caste", "==", selections.caste)
            .where("dob", "<=", new Date(selections.dob1))
            .where("dob", ">", new Date(selections.dob2))
            .where("approved", "==", true)
            .get()
            .then(querySnapshot => {
                const profiles = querySnapshot.docs.map(doc => doc.data())
                dispatch(setSearchResult(profiles));
            });
    }
}

export function getInterestedProfiles(uids) {
    return (dispatch) => {
        if (uids) {
            db.collection("profiles")
                .where("uid", "in", uids.slice(0,9))
                .get()
                .then(querySnapshot => {
                    const profiles = querySnapshot.docs.map(doc => doc.data())
                    dispatch(setSearchResult(profiles));
                })
                .catch(err => {
                    console.error(err);
                });
        }
    };
}

export const setProfileInterests = (interests) => ({
    type: GET_PROFILE_INTERESTS,
    interests,
})

export function getInterests(myUid) {
    return (dispatch) => {
        db.collection("interests")
            .where("uid", "==", myUid)
            .get()
            .then(querySnapshot => {
                const interests = querySnapshot.docs.map(doc => doc.data())
                dispatch(setProfileInterests(interests[0]));
            })
            .catch(err => {
                console.error(err);
            });
    }
}

export function sendInterest(uid, myUid, interest) {
    return (dispatch) => {
        db.collection("interests")
            .where("uid", "==", uid)
            .get()
            .then(querySnapshot => {
                const interestsDoc = querySnapshot.docs.map(doc => doc.data());
                if(interestsDoc.length > 0) {
                    const interests = interestsDoc[0].interests;
                    if (!interests.find(({senderUid}) => senderUid === myUid))
                    {
                        const docId = querySnapshot.docs.map(doc => doc.id)
                        interests.push(interest);
                        dispatch(updateInterest(docId[0], interests));
                    }
                } else {
                    dispatch(insertInterest(uid, myUid, interest));
                }
            });
    }
}

export function insertInterest(uid, myUid, interest) {
    return (dispatch) => {
        db.collection("interests").add({
                uid,
                interests: [interest]
            })
            .then((doc) => {
                //dispatch(getInterests(myUid));
            }).catch((err) => {
                console.error(err);
            })
    }
}

export function updateInterest(docId, interests) {
    return (dispatch) => {
        db.collection("interests").doc(docId).update({interests: interests})
            .then((doc) => {
                //dispatch(getInterests(myUid));
            }).catch((err) => {
                console.error(err);
            })
    }
}

export function addProfileContacts(uid, userName, email, mobile) {
    return () => {
        const profileContacts = {
            uid,
            userName,
            email,
            mobile,
        };
        db.collection("profile_contacts").add(profileContacts).then((docRef) => {
            //no action
        }).catch((err) => {
            return err
        });
    }
}

export function updateProfileContacts(uid, mobile) {
    return () => {
        const profileContacts = {
            mobile,
        };
        db.collection("profile_contacts")
            .where("uid", "==", uid)
            .get()
            .then(querySnapshot => {
                const profile_contact = querySnapshot.docs.map(doc => doc.data());
                if(profile_contact.length > 0) {
                    const docIds = querySnapshot.docs.map(doc => doc.id);
                    const docId = docIds[0];
                    db.collection("profile_contacts").doc(docId).update(profileContacts).then((docRef) => {
                        //no action
                    }).catch((err) => {
                        return err
                    });
                }
            }
        );
    }
}
