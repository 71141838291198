import styled from "styled-components";
import { connect } from "react-redux";
import { signInAPI, signOutAPI, showLoginModal } from "../store/actions";
import { Redirect } from 'react-router';
import  Registration from "./Registration";

const Header = (props) => {
  const step = props.profile ? props.profile.step : 0;
  return (
    <Row>
      {
        (props.user && step === 6 && !props.hideRegistrationPopup) &&
        <Redirect to='/account' />
      }
      <Content>
        <Logo>
          <a href="#home">
            Vinayaka Matrimony
          </a>
        </Logo>
        <Nav>
          <NavListWrap>
            <Navitem>
              <a href="#home">
                Home
              </a>
            </Navitem>
            <Navitem>
              <a href="#about">
                About
              </a>
            </Navitem>
            <Navitem>
              <a href="#contact">
                Contact
              </a>
            </Navitem>
            <User>
              <a className='user_icon' href="/account">
                {props.user && props.user.photoURL ? (
                  <img src={props.user.photoURL} alt="" />
                ) : (
                  <img src="/images/user.svg" alt="" />
                )}
              </a>
              {props.user ? (
                <SignOut onClick={() => props.signOut()}>
                  <a >Log Out</a>
                </SignOut>
              ) : (
                <SignOut onClick={() => props.showLoginModal()}>
                  <a >Log in</a>
                </SignOut>
              )}
            </User>
          </NavListWrap>
        </Nav>
      </Content>
      {!props.hideRegistrationPopup && <Registration/>}
    </Row>
  );
};

const Row = styled.div`
  height: 54px;
  position: fixed;
  width: 100%;
  border-color: #fff;
  box-shadow: 0px 2px 15px rgb(18 66 101 / 8%);
  background: #f76c6c;
  color: #fff;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;

  @media (max-width: 768px) {
    font-size: 12px;
    height: 66px;
  }
`;

const Content = styled.div`
  width: 80%;
  display: flex;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`;

const Logo = styled.h1`
  text-align: left;
  height: 50px;
  z-index: 9;
  margin: 6px 20px;

  @media (max-width: 768px) {
    margin: 6px 14px;
  }

  a {
    color: #FFFFFF;
    font-size: 28px; 
    font-weight: 600;
    letter-spacing: 0.5px;
    font-family: "Poppins", sans-serif;
    text-decoration: none;

    @media (max-width: 768px) {
      font-size: 22px;
    }
  }
`;

const Nav = styled.nav`
  margin-left: auto;
  display: block;
`;

const NavListWrap = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  list-style-type: none;
  margin: 0;
`;

const NavList = styled.li`
  display: flex;
  align-items: center;
  a {
    align-items: center;
    background: transparent;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 400;
    justify-content: center;
    line-height: 1.5;
    position: relative;
    text-decoration: none;
    margin: 10px;
    cursor: pointer;

    span {
      color: #FFFFFF;
      display: flex;
      align-items: center;
    }

    @media (max-width: 768px) {
      min-width: 70px;
    }
  }

  &:hover,
  &:active {
    a {
      span {
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
`;

const SignOut = styled.div`
  @media (max-width: 768px) {
    padding-right: 6px;
  }
  a {
    font-size: 14px;
    background: #F8E9A1;
    margin-right: 10px;
    cursor: pointer;
    color: #374785;
    border-radius: 5px;
    padding: 6px 30px;
    white-space: nowrap;

    @media (max-width: 768px) {
      padding: 6px;
    }

  }
`;

const User = styled(NavList)`
  border-radius: 50px 0 0 50px;
  padding-left: 10px;
  z-index: 9;

  a > svg {
    width: 24px;
    border-radius: 50%;
  }

  a > img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  .user_icon {
    @media (max-width: 768px) {
      display: none;
    }
  }

  span {
    display: flex;
    align-items: center;
  }
`;

const Navitem = styled(NavList)`
  border-radius: 50px 0 0 50px;
  padding-left: 10px;
  z-index: 9;

  a > svg {
    width: 24px;
    border-radius: 50%;
  }

  a > img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  a:visited {
    color: #fff;
  }

  span {
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    profile: state.profileState.profile,
  };
};

const mapDipatchToProps = (dispatch) => ({
  signIn: () => dispatch(signInAPI()),
  signOut: () => dispatch(signOutAPI()),
  showLoginModal: () => dispatch(showLoginModal())
});

export default connect(mapStateToProps, mapDipatchToProps)(Header);
