import { connect } from "react-redux";
import styled from "styled-components";

import ProfileDetails from './Shared/ProfileDetails';
import ProfileList from "./Profile/ProfileList";

import { clearSelectedProfile } from "../store/actions";

const ProfileInterests = (props) => {

  const backToInterestedProfiles = () => {
    props.clearSelectedProfile();
  }

  return (
    <Container>
      { (props.profiles && !props.selectedProfile) &&
        <Content>
          <ProfileList></ProfileList>
        </Content>
      }
      {
        props.selectedProfile &&
        <Content>
          <a onClick={backToInterestedProfiles}>Back to Interested Profiles</a>
          <ProfileDetails props={ { profile: props.selectedProfile } }></ProfileDetails>
        </Content>
      }
    </Container>
  );
};

const Container = styled.div`
width: 100%;
`;

const Content = styled.div`
  a {
    font-size: 20px;
    font-weight: bold;
    color: #f76c6c;
    padding: 20px;
    line-height: 40px;
    text-decoration: underline;
  }
  
  button {
    background-color: #F8E9A1;
    color: #444444;
    font-size: 16px;
    border: 1px solid #eee;
    padding: 10px;
    margin: 0 20px;
  }
`;

const mapDipatchToProps = (dispatch) => ({
  clearSelectedProfile: () => dispatch(clearSelectedProfile()),
});

const mapStateToProps = (state) => {
  return {
    profile: state.profileState.profile,
    profiles: state.profileState.searchResults,
    selectedProfile: state.profileState.selectedProfile,
    user: state.userState.user,
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(ProfileInterests);
