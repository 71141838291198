'use strict';

const { CITIES, COUNTRY, STATE } = require('./countries');

const GENDER = {
  MALE: 'Groom',
  FEMALE: 'Bride',
};

const COMPLEXION = {
  VERY_FAIR: "Very Fair",
  FAIR: "Fair",
  WHEATISH: "Wheatish",
  MEDIUM: "Medium",
  DARK: "Dark",
}

const HEIGHT = (() => {
  const heightObj = {};
  for(var i=120; i<222; i++) {
    heightObj[i] = i;
  }
  return heightObj;
})()

const AGE = (() => {
  const ageObj = {};
  for(var i=18; i<70; i++) {
    ageObj[i] = i;
  }
  return ageObj;
})()


const MOTHER_TOUNGE = {
  MALAYALAM: "Malayalam",
  TAMIL: "Tamil",
  KANNADA: "Kannada",
  HINDI: "Hindi",
  URDU: "Urdu",
  ENGLISH: "English",
  TELUGU: "Telugu",
}

const PROFILE_CREATED = {
  SELF: "Self",
  PARENT: "Parents",
  SIBLING: "Sibling",
  RELATIVE: "Relative",
  FRIEND: "Friend",
  OTHERS: "Others",
};

const PHYSICAL_STATUS = {
  NORMAL: "Normal",
  PHYSICALLY_CHALLENGED: "Physically Challenged",
};

const MARITAL_STATUS = {
  NEVER_MARRIED: "Never Married",
  WIDOW: "Widow/Widower",
  DIVORCED: "Divorced",
  AWAITING_DIVORCED: "Awaiting Divorce",
};


const EDUCATION = {
  BACHELORS_ENGINEERING: "Bachelors - Engineering/ Computers",
  MASTERS_ENGINEERING: "Masters - Engineering/ Computers",
  BACHELORS_ARTS: "Bachelors - Arts/ Science/ Commerce/ Others",
  MASTERS_ARTS: "Masters - Arts/ Science/ Commerce/ Others",
  MANAGEMENT: "Management - BBA/ MBA/ Others",
  MEDICINE: "Medicine - General/ Dental/ Surgeon/ Others",
  LEGAL: "Legal - BL/ ML/ LLB/ LLM/ Others",
  SERVICE: "Service - IAS/ IPS/ Others",
  PHD: "Phd",
  DIPLOMA: "Diploma",
  HIGHER_SECONDARY: "Higher Secondary/ Secondary",
}

const EMPLOYED_IN = {
  GOVERNMENT: "Government",
  PRIVATE: "Private",
  BUSINESS: "Business",
  DEFENCE: "Defence",
  NOT_WORKING: "Not working",
}

const FAMILY_TYPE = {
  JOINT_FAMILY: "Joint family",
  NUCLEAR_FAMILY: "Nuclear Family"
};

const FAMILY_STATUS = {
  MIDDLE_CLASS: "Middle class",
  UPPER_MIDDLE_CLASS: "Upper middle class",
  RICH: "Rich",
  LOWER_INCOME: "Lower income",
}

const RELIGION = {
  HINDU: "Hindu",
  CHRISTIAN: "Christain",
  INTER_RELIGION: "InterReligion",
  JAIN: "Jain",
  MUSLIM: "Muslim",
  PARSI: "Parsi",
  ANY: "Any",
}

const CASTE = {
  HINDU: {
    AMBALAVASI: "Ambalavasi",
    BRAHMIN: "Brahmin",
    CHEKKALA_NAIR: "Chekkala Nair",
    CHERAMAR: "Cheramar",
    CHETTIAR: "Chettiar",
    DHEEVARA: "Dheevara",
    EZHAVA: "Ezhava",
    EZHUTHACHAN: "Ezhuthachan",
    EZHAVATHI: "Ezhavathi",
    GANAKAR: "Ganakar",
    GSB: "GSB",
    INTERCASTE: "InterCaste",
    IYER: "Iyer",
    KAKKALAN: "Kakkalan",
    KAMMALA: "Kammala",
    KANAKKA: "Kanakka",
    KANIYAN: "Kaniyan",
    KARTHA: "Kartha",
    KSHATRIYA_VARMA: "Kshatriya/Varma",
    KUDUMBI: "Kudumbi",
    KURAVA: "Kurava",
    KURUP: "Kurup",
    MANNADIAR: "Mannadiar",
    MANNAN: "Mannan",
    MANIYANI: "Maniyani",
    MARAR: "Marar",
    MARUTHUVAR: "Maruthuvar",
    MENON: "Menon",
    MOOTHAN: "Moothan",
    MUDALIAR: "Mudaliar",
    NADAR: "Nadar",
    NAIR: "Nair",
    NAICKEN: "Naicken",
    NAMBIAR: "nambiar",
    NAMBISAN: "Nambisan",
    NAMBOOTHIRI_BHATHATHIRI: "Namboothiri/Bhathathiri",
    PANDITHAR: "Pandithar",
    PANICKER: "Panicker",
    PARAVAN: "Paravan",
    PATTARYA: "Pattarya",
    PILLAI: "Pillai",
    PISHARODY: "Pisharody",
    PODUVAL: "Poduval",
    PULAYA: "Pulaya",
    REDDIAR: "Reddiar",
    SAIVA_VELLALA: "Saiva Vellala",
    SALIYA: "Saliya",
    SAMBAVA: "Sambava",
    SARASWAT_BRAHMIN: "Saraswat Brahmin",
    THACHAR: "Thachar",
    THANDAN: "Thandan",
    THARAKAN: "Tharakan",
    THIYA: "Thiya",
    VADUKA: "Vaduka",
    VALLALA: "Vallala",
    VANIYA: "Vaniya",
    VANNAN: "Vannan",
    VEERASAIVA: "Veerasaiva",
    VELAN: "Velan",
    VELAR: "Velar",
    VELLUTHEDATHU_NAIR: "Velluthedathu Nair",
    VILAKITHALA_NAIR: "Vilakithala Nair",
    VISHWAKARMA: "Vishwakarma",
    VISWAKARMA_CARPENTER: "Viswakarma-Carpenter",
    VISWAKARMA_GOLDSMITH: "Viswakarma-Goldsmith",
    VISWAKARMA_BLACKSMITH: "Viswakarma-Blacksmith",
    VISWAKARMA_MASON: "Viswakarma-Mason",
    WARRIER: "Warrier",
    GUPTAN: "Guptan",
    SC: "SC",
    ST: "ST",
    OTHERS: "Others",
  },
  CHRISTIAN: {
    ANY: "Any",
    BASEL_MISSION: "Basel Mission",
    BORN_AGAIN: "Born Again",
    BRETHREN: "Brethren",
    CANNONITE: "Cannonite",
    CATHOLIC: "Catholic",
    CHALDEAN_SYRIAN: "Chaldean Syrian",
    CHERAMAR: "Cheramar",
    CHRISTIAN_NADAR: "Christian Nadar",
    CNI: "CNI",
    CONVERT: "Convert",
    CSI: "CSI",
    EVANGALICAL: "Evangalical",
    INTERCASTE: "InterCaste",
    IPC: "IPC",
    JACOBITE: "Jacobite",
    KNANAYA: "Knanaya",
    KNANAYA_CATHOLIC: "Knanaya Catholic",
    KNANAYA_JACOBITE: "Knanaya Jacobite",
    KNANAYA_PENTECOSTAL: "Knanaya Pentecostal",
    LATIN_CATHOLIC: "Latin Catholic",
    MALANKARA: "Malankara",
    MALANKARA_CATHOLIC: "Malankara Catholic",
    MARTHOMA: "Marthoma",
    METHODIST: "Methodist",
    MORMON: "Mormon",
    ORTHODOX: "Orthodox",
    PENTECOST: "Pentecost",
    PRESBYTERIAN: "Presbyterian",
    PROTESTANT: "Protestant",
    RC: "RC",
    RCSC: "RCSC",
    SALVATION_ARMY: "Salvation Army",
    SEVENTH_DAY_ADVENTIST: "Seventh day Adventist",
    SYRIAN_CATHOLIC: "Syrian Catholic",
    SYRO_MALABAR: "Syro Malabar",
    ANGLO_INDIAN: "Anglo Indian",
    SC: "SC",
    ST: "ST",
    SAMBAVA: "Sambava",
    OTHERS: "Others",
  },
  INTER_RELIGION: {
    ANY: "Any",
  },
  JAIN: {
    ANY: "Any",
  },
  MUSLIM: {
    ANY: "Any",
    SHIYA: "Shiya",
    SUNNI: "Sunni",
    INTERCASTE: "InterCaste",
    OTHERS: "Others",
  },
  PARSI: {
    ANY: "Any",
  },
  ANY: {
    ANY: "Any",
  },
}

const STARS = {
  ASWATHI: "Aswathi",
  BHARANI: "Bharani",
  KAARTHIKA: "Kaarthika",
  ROHINI: "Rohini",
  MAKEERAM: "Makeeram",
  THIRUVATHIRA: "Thiruvathira",
  PUNARTHAM: "Punartham",
  POOYAM: "Pooyam",
  AAYILYAM: "Aayilyam",
  MAKAM: "Makam",
  POORAM: "Pooram",
  UTHRAM: "Uthram",
  ATHAM: "Atham",
  CHITHRA: "Chithra",
  CHOTHI: "Chothi",
  VISAAKHAM: "Visaakham",
  ANIZHAM: "Anizham",
  THRIKKETTA: "Thrikketta",
  MOOLAM: "Moolam",
  POORAATAM: "Pooraatam",
  UTHRAATAM: "Uthraatam",
  THIRUVONAM: "Thiruvonam",
  AVITTAM: "Avittam",
  CHATHAYAM: "Chathayam",
  POORATATHI: "Pooratathi",
  UTHRATTAATHI: "Uthrattaathi",
  REVATHI: "Revathi",
  ANY: "Any",
}

const ANNUAL_INCOME = {
  UPTO_1L: "Upto 1 Lakh",
  FROM_1L_TO_2L: "1 Lakh To 2 Lakh",
  FROM_2L_TO_5L: "2 Lakh To 5 Lakh",
  FROM_5L_TO_8L: "5 Lakh To 8 Lakh",
  FROM_8L_TO_10L: "8 Lakh To 10 Lakh",
  FROM_10L_TO_15L: "10 Lakh To 15 Lakh",
  FROM_15L_TO_25L: "15 Lakh To 25 Lakh",
  FROM_25L_TO_50L: "25 Lakh To 50 Lakh",
  FROM_50L_TO_1CR: "50 Lakh To 1 Crore",
  FROM_1CR_AND_MORE: "1 Crore and More",
}

module.exports = {
  ANNUAL_INCOME,
  AGE,
  CASTE,
  CITIES,
  COMPLEXION,
  COUNTRY,
  EDUCATION,
  EMPLOYED_IN,
  FAMILY_STATUS,
  FAMILY_TYPE,
  GENDER,
  HEIGHT,
  MARITAL_STATUS,
  MOTHER_TOUNGE,
  PROFILE_CREATED,
  PHYSICAL_STATUS,
  RELIGION,
  STARS,
  STATE,
};
