import styled from "styled-components";

const Dropdown = ({ placeholder, name, setPayload, options, errorMessage, errorType }) => {
  return (
    <Select
      type = 'text'
      placeholder = { placeholder }
      name = { name }
      onChange= { e => { handleChange(e, setPayload) } }
      data-error-message = { errorMessage }
      data-error-type = { errorType }
    >
      <option value=''>--- Select ---</option>
      {getOptions(options)}
    </Select>
  );
};

const getOptions = (options) => {
  if(options) {
    return Object.keys(options).map((key) =>
      <option value={ key } key= { key }>{ options[key] }</option>
    );
  } else {
    return null;
  }
}

const handleChange = (e, setPayload) => {
  setPayload(e.target.name, e.target.value);
}

const Select = styled.select`
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;

export default Dropdown;
