import { connect } from "react-redux";
import styled from "styled-components";
import { signOutAPI } from "../../store/actions";

const { menuItems } = require('./MenuItems');

const Menu = styled.div`
.topnav {
  overflow: hidden;
  background-color: #f76c6c;
  border-top: 1px solid #ffffff;
}

.topnav a {
  float: left;
  display: block;
  color: #F8E9A1;
  text-align: center;
  padding: 10px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav .icon {
  display: none;
}

.topnav a:hover {
  background-color: #f76c6c;
  color: white;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .topnav.responsive {float: none;}
  .topnav.responsive {position: relative;}
  .topnav.responsive {
    display: block;
    width: 100%;
    text-align: left;
  }
}
`;

const menuClick = () => {
  var topnav = document.getElementById("topnav");
  if (topnav.className === "topnav") {
    topnav.className += " responsive";
  } else {
    topnav.className = "topnav";
  }
}

const menuItemClick = (menuItem) => {
  menuItem.onClick();
  var selectedTitle = document.getElementById('selected-title');
  selectedTitle.text = menuItem.name;
  menuClick();
};

const TopMenu = (props) => {
  const list = menuItems(props).map((menuItem) => 
    <a key={menuItem.name} id={menuItem.id} onClick={ ()=>menuItemClick(menuItem) }>
      {menuItem.name}
    </a>
  )
  return (
    <Menu>
      <div className="topnav" id="topnav">
        <a id='selected-title'>My Account</a>
        {list}
        <a href="#" className="icon" onClick={menuClick}>&#9776;</a>
      </div>
    </Menu>
  )
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    profile: state.profileState.profile,
  };
};

const mapDipatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOutAPI()),
});

export default connect(mapStateToProps, mapDipatchToProps)(TopMenu);
