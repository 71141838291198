import styled from "styled-components";

const LogoText = styled.span`
  color: #FFF;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-family: "Poppins", sans-serif;
  text-align: center;
`;

const Logo = ({props}) => {
  return (
    <LogoText>Vinayaka Matrimony</LogoText>
  )
};

export default Logo;