import styled from "styled-components";

const JoinUs = (props) => {
  return (
    <Container>
      <H2>So please Join us</H2>
      <H1>"TOGETHER WE CAN ACHIEVE OUR MISSION"</H1>
    </Container>
  );
};  

const Container = styled.div`
  width: 100%;
  background: #F76C6C;
  background-size: cover;
  padding: 20px 0;
`;

const H1 = styled.h1`
  text-align:center;
  color: #FFFFFF;
  font-family: "Raleway", sans-serif;
  font-size: 28px;
  font-weight: 700;
`;

const H2 = styled.h2`
  text-align:center;
  color: #F8E9A1;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
`;

export default JoinUs;
