import styled from "styled-components";
import { connect } from "react-redux";
import { signInEmailAPI } from "../../store/actions";
import React, { useState } from 'react';
import { validate } from "../Shared/inputValidator";

const QuickRegistration = (props) => {

  const [fullname, setFullname] = useState('');
  const [gender, setGender] = useState('MALE');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [dob, setDob] = useState([0,0,0]);
  const [isInprocess, setIsInprocess] = useState(false);

  const handleFullNameChange = (event) => {
    setFullname(event.target.value);
  }

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const handleDobChange = (event) => {
    switch (event.target.name) {
      case 'DD_quick': {
        dob[1] = event.target.value;
      }
      case 'MM_quick': {
        dob[0] = event.target.value;
      }
      case 'YY_quick': {
        dob[2] = event.target.value;
      }
    }
    setDob(dob);
  }

  const singIn = (event) => {
    if(validate(["fullname_quick", "email_quick", "mobile_quick", "password_quick", "password1_quick", "DD_quick", "MM_quick", "YY_quick"])) {
      setIsInprocess(true);
      props.signInEmailAPI(fullname, email, password, gender, dob.join('/'), mobile);
    }
  }

  return (
    <Container>
      <div>
        <h2>Begin your success story</h2>
      </div>
      <div>
        <input
          id="test"
          type="text"
          name="fullname_quick"
          placeholder="Full name"
          value={ fullname }
          required
          data-error-message="Only alphabets are allowed in full name"
          data-error-type="onlyAlphabets"
          onChange= { handleFullNameChange }/>
      </div>
      <div>
        <section>
          <span>Gender</span>
          <input
            type="radio"
            name="gender1"
            value="MALE"
            onChange= { handleGenderChange }
            checked = { gender === "MALE" }
          />
          <label>Male</label>
          <input
            type="radio"
            name="gender1"
            value="FEMALE"
            onChange= { handleGenderChange }
            checked = { gender === "FEMALE" }
          />
          <label>Female</label>
        </section>
      </div>
      <div>
        <input
          type="email" 
          name="email_quick" 
          placeholder="Email Address" 
          value={ email } 
          required
          data-error-message="Email format not valid"
          data-error-type="email"
          onChange= { handleEmailChange }
        />
      </div>
      <div>
        <input
          type = "text"
          name = "mobile_quick"
          placeholder = "Mobile No."
          value = { mobile }
          required = ""
          data-error-message = "Mobile number format not valid"
          data-error-type = "phone"
          onChange= { handleMobileChange }
        />
      </div>
      <div>
        <input
          type="password"
          name="password_quick"
          placeholder="Password"
          value= { password }
          required=""
          data-error-message="Password must have 8 characters"
          data-error-type="password"
          onChange= { handlePasswordChange }
        />
      </div>
      <div>
        <input
          type="password"
          name="password1_quick"
          placeholder="Confirm Password"
          required=""
          data-error-message="Password is does not match"
          data-error-type="confirmPassword"
          fieldToCompare="password_quick"
        />
      </div>
      <div>
        <label htmlFor="dob" className="dob">Date of Birth</label>
        <section>
          <input
            type="number"
            min="1"
            max="31"
            placeholder="DD"
            name="DD_quick"
            required=""
            data-error-message="Day of Birth is Mandatory"
            data-error-type="required"
            onChange= { handleDobChange }
          />
          <input
            type="number"
            min="1"
            max="12"
            placeholder="MM"
            name="MM_quick"
            required=""
            data-error-message="Day of Birth is Mandatory"
            data-error-type="required"
            onChange= { handleDobChange }
          />
          <input
            type="number"
            min="1953"
            max="2003"
            placeholder="YYYY"
            name="YY_quick"
            required=""
            data-error-message="Day of Birth is Mandatory"
            data-error-type="required"
            onChange= { handleDobChange }
          />
        </section>
      </div>
      <div>
        <button onClick={ singIn }>{ isInprocess ? "Registration In Progress" : "CREATE AN ACCOUNT FOR FREE" }</button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  font-family: "Open Sans", sans-serif;
  color: #444444;
  justify-content: center;
  max-width: 600px;
  width: 35%;

  @media (max-width: 768px) {
    width: 100%;

    input[type="number"] {
      width: 38px !important;
    }

    label[htmlFor="dob"] {
      line-height: 26px !important;
    }

    margin-bottom: 30px;
  }

  div {
    padding: 6px 0px;
    text-decoration: none;
    text-align: left;

    h2 {
      font-weight: 500;
      line-height: 1.2;
      font-size: 1.75rem;
      font-family: "Raleway", sans-serif;
    }

    input {
      width: 96%;
      min-width: 12px;
      height: calc(1em + .75rem + 1px);
      padding: .375rem .75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    input[type="radio"] {
      width: 20px;
      margin-left: 70px;
    }

    button {
      background: #f76c6c;
      border-color: #f76c6c;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      vertical-align: middle;
      border: 1px solid transparent;
      padding: .375rem .75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: .25rem;
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      margin: auto;
      width: 100%;
    }
  }

  section {
    display: flex;
    justify-content: center;
    align-items: center;

    input[type="number"] {
      margin: 0 6px;
      width: 54px;
      font-size: 14px;
    }
  }

  label[htmlFor="dob"] {
    line-height: 50px;
  }
`;

const mapDipatchToProps = (dispatch) => ({
  signInEmailAPI: (fullname, email, password, gender, dob, mobile) =>
    dispatch(signInEmailAPI(fullname, email, password, gender, dob, mobile)),
});

export default connect(null, mapDipatchToProps)(QuickRegistration);
