import styled from "styled-components";
import React, {useState} from 'react';
import { connect } from "react-redux";

const Nav = styled.div`
  padding: 0px;
  font-size: 18px;
  color: #333;

  ul, li {
    list-style: none;
    cursor: pointer;
    padding 4px 20px;
  }
`;

const Profile = styled.div`
  border: solid 1px #333;
  width: 100%;

  div {
      display: inline;
      border: solid 1px #333;
      padding: 0 4px;
  }
`;

const ActivateProfile = (props) => {
  const [searchKey, setSearchKey] = useState('');
  if(props.profiles && props.menu === 'searchProfile') {
    const { profiles } = props;
    const handleChange = (e) => {
      setSearchKey(e.currentTarget.value);
    }
    return (
      <Nav>
        <div><input type="text" onChange={ handleChange } /></div>
      <ul>
      {profiles.filter(profile => profile.full_name && profile.full_name.includes(searchKey) === true).map(profile => (
        <li key={profile.uid}>
            <Profile>
                <div>
                    {profile.full_name}
                </div>
                <div>
                    {profile.gender}
                </div>
                <div>
                  {profile.approved.toString()}
                </div>
                <div>
                    {profile.premium}
                </div>
            </Profile>
        </li>
      ))}
      </ul>
      </Nav>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => {
  return {
    profiles: state.adminState.profiles,
    menu: state.adminState.menu,
  };
};
  
const mapDipatchToProps = (dispatch) => ({
});
  
export default connect(mapStateToProps, mapDipatchToProps)(ActivateProfile);  
