import styled from "styled-components";

const TextBox = ({ placeholder, name, setPayload, value, errorType, errorMessage }) => {
  return (
    <Input
      type = 'text'
      placeholder = { placeholder }
      name = { name }
      value = { value }
      onChange = { e => { handleChange(e, setPayload) } }
      data-error-message = { errorMessage }
      data-error-type = { errorType }
    />
  );
};

const handleChange = (e, setPayload) => {
  setPayload(e.target.name, e.target.value);
}

const Input = styled.input`
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;

export default TextBox;
