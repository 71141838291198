import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";

const PhotoContainer = styled.div`
  display: flex;
  padding: 2px;
  flex-wrap: wrap;
`;

const Photo = styled.img`
width: 40px;
min-width: 200px;
  height: auto;
  margin: 10px 4px 10px 4px;
`;
const PhotoDiv = styled.div`
border: solid 2px #fff;
margin: 1%;
padding: 1%;
`;
const PhotoList = ({ props }) => {
  const photos = props.props.profile.photos;
  const setProfilePic = (payload) => {
		props.props.updateProfile(props.props.user, props.props.profile, {'profile_pic':payload});
	};
  const listItems = Array.isArray(photos) ? photos.map((url) =>
    <PhotoDiv>
      <div>
        <Photo
          src={url} /></div>
      <Button variant="outlined" onClick={()=>setProfilePic(url)}>Set As Profile Pic</Button>
    </PhotoDiv>
  ) : null;

  return (
    <PhotoContainer>
      {listItems}
    </PhotoContainer>
  )
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    profile: state.profileState.profile,
  };
};

export default connect(mapStateToProps, null)(PhotoList);