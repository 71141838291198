import { SET_STATES, SET_CITIES } from "./actionType";
import { COUNTRY_ID_MAP, STATE, CITIES } from "../../helpers/countries";

export const setStates = (payload) => ({
    type: SET_STATES,
    states: payload,
});

export const setCities = (payload) => ({
    type: SET_CITIES,
    cities: payload,
});

export function getStates(countryCode) {
    return (dispatch) => {
        dispatch(setCities({}));
        if(countryCode === 'INDIA') {
            const states = STATE[countryCode];
            dispatch(setStates(states));
        } else {
            const headers = new Headers();
            headers.append("X-CSCAPI-KEY", "SGN3Y1lsRHZ2eGllOVY2aFBpRGU4Nmo0T09UdlJ6NXZNV1hqUVo2Tg==");

            const requestOptions = {
                method: 'GET',
                headers: headers,
                redirect: 'follow'
            };
            const param = COUNTRY_ID_MAP[countryCode];
            fetch("https://api.countrystatecity.in/v1/countries/"+param+"/states", requestOptions)
            .then(response => response.json())
            .then(result => {
                const states = {};
                if(result.length > 0) {
                    result.forEach((state) => {
                        states[state.iso2] = state.name;
                    });
                }
                dispatch(setStates(states));
            })
            .catch(error => {
                console.log('error', error)
            });
        }
    };
}

export function getCities(countryCode, stateCode) {
    return (dispatch) => {
        dispatch(setCities({}));
        if(countryCode === 'INDIA') {
            const cities = CITIES[stateCode];
            dispatch(setCities(cities));
        } else {
            const headers = new Headers();
            headers.append("X-CSCAPI-KEY", "SGN3Y1lsRHZ2eGllOVY2aFBpRGU4Nmo0T09UdlJ6NXZNV1hqUVo2Tg==");

            const requestOptions = {
                method: 'GET',
                headers: headers,
                redirect: 'follow'
            };
            const countryId = COUNTRY_ID_MAP[countryCode];
            fetch("https://api.countrystatecity.in/v1/countries/"+countryId+"/states/"+stateCode+"/cities", requestOptions)
            .then(response => response.json())
            .then(result => {
                const cities = {};
                if(result.length > 0) {
                    result.forEach((city) => {
                        cities[city.name.toUpperCase().replace(/ /g, "_")] = city.name;
                    });
                }
                dispatch(setCities(cities));
            })
            .catch(error => {
                console.log('error', error)
            });
        }
    };
}
