import { connect } from "react-redux";
import styled from "styled-components";
import { signOutAPI } from "../../store/actions";

const { menuItems } = require('./MenuItems');

const UI = styled.ul`
  @media (max-width: 768px) {
    display: none;
  }
`;

const LI = styled.li`
  list-style: none;
  padding: 4px 0;
  text-decoration: none;
  color: #FFF;
  cursor: pointer;
`;

const Menu = (props) => {
  const list = menuItems(props).map((menuItem) => 
    <LI key={menuItem.name} onClick={menuItem.onClick}>
      {menuItem.name}
    </LI>
  )
  return (
    <UI>{list}</UI>
  )
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    profile: state.profileState.profile,
  };
};

const mapDipatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOutAPI()),
});

export default connect(mapStateToProps, mapDipatchToProps)(Menu);
