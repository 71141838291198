import { connect } from "react-redux";

const ProfilePic = ({ props }) => {
  const dummyPhotoURL = 
    props.profile.gender === 'MALE' ? 
      'https://firebasestorage.googleapis.com/v0/b/vinayaka-matrimony.appspot.com/o/Male.jpeg?alt=media&token=9d4771bc-745d-4a25-9148-a3f79c880cdb'
    : 'https://firebasestorage.googleapis.com/v0/b/vinayaka-matrimony.appspot.com/o/Female.jpeg?alt=media&token=cb4d3f60-c9ea-461a-9c95-269412c7e3c7';
  
  const photoURL = props.profile.profile_pic ? props.profile.profile_pic : dummyPhotoURL;
  return (
    <img id="profile-pic" src={photoURL}></img>
  )
};
const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    profile: state.profileState.profile,
  };
};

export default connect(mapStateToProps, null)(ProfilePic);