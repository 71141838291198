import styled from "styled-components";

const About = (props) => {
  return (
    <Containter id="about">
      <h2>ABOUT VINAYAKA MATRIMONY</h2>
      Pioneered by Vinayaka Foundation a decade before as part of its social commitment. Vinayaka matrimony has helped hundreds to find matches from across different communities. The services provided by our matrimonial wing have made the site the most popular one among keralites.
      <h4>Why to Choose Vinayaka Matrimony</h4>
        The most dependable matrimony service<br/>
        100% verified mobile numbers<br/>
        Trusted service for more than 12 years<br/>
        The profiles are genuinely verified<br/>
        Helps to find the right partner and family<br/>
        Personalized searching options available<br/>
      <h2>VINAYAKA FOUNDATION</h2>
      <h4>The Team Behind Vinayaka Matrimony</h4>
      Vinayaka Foundation is an organization registered under societies act, meant for doing charitable activities to the poor and needy in our society. The activities of the Vinayaka Foundation were formally Inaugurated by world famous sports person Olympian , Padmashree Dr.P.T.Usha on 1st of November 2008.
      <Section>
        <Objectives>
          <h3>Our objectives include</h3>
          <p>
            1. Carry out community development projects.<br/>
            2. Establish, conduct, manage and participate in educational, charitable, social and cultural activities by forming societies or similar associated concerns or organizations.<br/>
            3. Subscribe aid, donate, or subsidize any work, organizations or institutes having same or similar objectives in promotion of the objectives of the society.<br/>
            4. Organize events for the promotion of sports, arts, literary and other programmes among the members.<br/>
            6. Provide opportunity for the upcoming talented peoples from the community.<br/>
            7. Convene family gatherings on a regular basis to motivate all sections of the people especially women and children for greater accomplishments in their life.<br/>
          </p>
        </Objectives>
        <Services>
          <h3>Our Services</h3>
          STATE RESOURCE CENTRE STUDY CENTRE<br/>
          VINAYAKA MATRIMONIAL<br/>
          CAREER FORUM<br/>
          MEDICAL CAMPS<br/>
          SCHOLARSHIPS<br/>
          WOMENS FORUM<br/>
          CAMPAIGNS<br/>
        </Services>
      </Section>
      <Additional>
        <h3>We request your support and help of all nature in carrying out our mission..</h3>
        <p>
          We welcome all good hearted friends with a desire for service from the Saliya / pattarya/ devanga/ pillai etc.community to become our members. There are three types of membership Patron member, life member and ordinary member. For more details please contact any of our office bearer or any of our member close to your place.
          <br/><br/>
        </p>

        <h5>Chairman</h5>
        O.Mohanan (Managing Director, G Sons Group)<br/><br/>

        <h5>Chief Patron</h5>
        Shri C.Jayachandran (Managing Partner, Mascot Industries & Textile Exports)<br/>
        Shri Pala Balan (Textile Consultant)<br/><br/>

        <h5>Vice Chairman</h5>
        Madhu Aeran (Surveyor/Loss Assessor)<br/><br/>

        <h5>General Secretary</h5>
        Shri C Ramakrishnan (Managing Director , C R Group of Companies)<br/><br/>

        <h5>Secretaries</h5>
        T. Padmanabhan (Head MasterRtd.)<br/>
        TS Ramachandran(Thiruvangadan Group Of Companies)<br/><br/>

        <h5>Treasurer</h5>
        Dinesh Alingal(Mg. Partner, Dream Home)<br/><br/>

        <h5>Board/Executive members</h5>
        Rajan Azhikodan (Chartered Engineer)<br/>
        P Chandran (Ex-NRI)<br/>
        Mohana Chandran(Manager, Oriental Insurance).<br/>
        K Unnikrishnan (Advocate)<br/><br/>

      </Additional>
    </Containter>
  );
};  

const Containter = styled.div`
  text-align: center;
  width: 100%;
  font-size: 14px;
  color: #919191;
`;

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    display: block;
  }
`;

const Objectives = styled.div`
  width: 60%;
  text-align: left;
  padding-right: 15px;
`;

const Services = styled.div`
  width: 40%;
  text-align: left;
  padding-left: 15px;
`;

const Additional = styled.div`
  text-align: left;
  font-size: 14px;
  color: #919191;
`;

export default About;
