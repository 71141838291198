import { auth, provider, timestamp } from "../../firebase";
import { SET_USER, SHOW_LOGIN_MODAL, RESET_PASSWORD_SUCCESS } from "./actionType";
import db from "../../firebase";
import {
    addNewProfile,
    addProfile,
    setProfile,
    getProfile,
    getProfileDocId,
    updateProfile,
    addProfileContacts
} from './profileAction';


export const setUser = (payload) => ({
    type: SET_USER,
    user: payload,
})

export function signInAPI() {
    return (dispatch) => {
        auth.signInWithPopup(provider).then((payload) => {
            dispatch(hideLoginModal());
            dispatch(setUser(payload.user));
            getProfileDocId(payload.user).then((docId) => {
                if(docId) {
                    dispatch(getProfile(payload.user));
                } else {
                    dispatch(addProfile(payload.user));
                }
            });
        })
        .catch((error) => alert(error));
    };
}

export function signInEmailAPI(fullname, email, password, gender, dob, mobile) {
    return (dispatch) => {
        auth.createUserWithEmailAndPassword(email, password).then((userCredentials) => {
            const user = userCredentials.user;
            const dobDate = new Date(dob)
            dispatch(hideLoginModal());
            addNewProfile(user).then(() => {
                getProfileDocId(user).then((docId) => {
                    dispatch(addProfileContacts(user.uid, '', email, mobile));
                    dispatch(updateProfile(user, {docId}, {uid: user.uid, full_name: fullname, email, gender, dob: dobDate, mobile, reg_date: timestamp}));
                });
            });
        })
    }
}

export function sendPasswordResetEmail(email) {
    return (dispatch) => {
        auth.sendPasswordResetEmail(email).then(() => {
            dispatch(resetPasswordStatus(true));
        }).catch((err) => {
            alert(err.message);
        })
    }
}

export const resetPasswordStatus = (restPwdStatus) => ({
    type: RESET_PASSWORD_SUCCESS,
    restPwdStatus,
})

export function loginWithEmailAndPassword(email, password) {
    return (dispatch) => {
        auth.signInWithEmailAndPassword(email, password).then((userCredentials) => {
            const user = userCredentials.user;
            dispatch(setUser(user));
            getProfileDocId(user).then((docId) => {
                if(docId) {
                    dispatch(getProfile(user));
                } else {
                    dispatch(addProfile(user));
                }
                dispatch(hideLoginModal());
            })
        }).catch((error) => alert(error));
    }
}

export function signOutAPI() {
    return (dispatch) => {
        auth.signOut()
            .then(() => {
                dispatch(setUser(null));
                dispatch(setProfile(null));
            })
            .catch((error) => alert(error));
    };
}

export function getUserAuth() {
    return (dispatch) => {
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                dispatch(setUser(user));
                dispatch(getProfile(user));
            }
        })
    };
}

export function loginWithVnkId(vnkId, password) {
    return (dispatch) => {
        db.collection("username_lookup").where("userName", "==", vnkId)
            .limit(1)
            .get()
            .then(querySnapshot => {
                const documents = querySnapshot.docs.map(doc => doc.data())
                if (documents.length > 0) {
                    const userLookup = documents[0];
                    dispatch(loginWithEmailAndPassword(userLookup.email, password));
                } else {
                    alert("VNK ID not found, Please check you Id.")
                }
            });
    }
}

export const showLoginModal = () => ({
    type: SHOW_LOGIN_MODAL,
    show: true,
})

export const hideLoginModal = () => ({
    type: SHOW_LOGIN_MODAL,
    show: false,
})
