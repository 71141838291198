import styled from "styled-components";
import { connect } from "react-redux";

import Dropdown from "../../Shared/Dropdown";
import TextArea from "../../Shared/TextArea";

import { COMPLEXION, HEIGHT, MOTHER_TOUNGE, PROFILE_CREATED, PHYSICAL_STATUS, MARITAL_STATUS } from "../../../helpers/dbFields";

const Step2 = ({ setPayload }) => {
  return (
    <Content id="step">
      <label htmlFor="profile_created">Profile Created ***</label>
      <Dropdown 
        placeholder='Profile created by'
        setPayload={ setPayload }
        name = "profile_created"
        options= { PROFILE_CREATED }
        errorMessage="Please select profile created by"
        errorType="required"
      />
      <label htmlFor="physical_status">Physical status ***</label>
      <Dropdown 
        placeholder='Physical status'
        setPayload={ setPayload }
        name = "physical_status"
        options= { PHYSICAL_STATUS }
        errorMessage="Please select physical status"
        errorType="required"
      />
      <label htmlFor="physical_status">Marital Status ***</label>
      <Dropdown 
        placeholder='Marital Status'
        setPayload={ setPayload }
        name = "marital_status"
        options= { MARITAL_STATUS }
        errorMessage="Please select marital status"
        errorType="required"
      />
      <label htmlFor="height">Height ***</label>
      <Dropdown 
        placeholder='Height'
        setPayload={ setPayload }
        name = "height"
        options= { HEIGHT }
        errorMessage="Please select height"
        errorType="required"
      />
      <label htmlFor="complexion">Complexion ***</label>
      <Dropdown 
        placeholder='Complexion'
        setPayload={ setPayload }
        name = "complexion"
        options= { COMPLEXION }
        errorMessage="Please select complexion"
        errorType="required"
      />
      <label htmlFor="mother_tounge">Mother Tongue ***</label>
      <Dropdown 
        placeholder='Mother Tounge'
        setPayload={ setPayload }
        name = "mother_tounge"
        options= { MOTHER_TOUNGE }
        errorMessage="Please select mother tongue"
        errorType="required"
      />
      <label htmlFor="about_myself">About Myself</label>
      <TextArea 
        placeholder='About Myself'
        setPayload={ setPayload }
        name = "about_myself"
      />
    </Content>
  );
};

const Content = styled.div`
  width: 100%;
`;

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    profile: state.profileState.profile,
  };
};

const mapDipatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDipatchToProps)(Step2);
