import styled from "styled-components";
import { connect } from "react-redux";

import Logo from '../Shared/Logo';
import Login from './Login';
import Menu from './Menu';
import Profiles from './Pages/MemberProfiles';
import ProfilesForApproval from "./Pages/ProfilesForApproval";
import FeatureProfiles from "./Pages/FeatureProfiles";
import ActivateProfile from "./Pages/ActivateProfiles";
import SearchProfiles from "./Pages/searchProfiles";

const Container = styled.div`
  display: flex;
  height: 100vw;
  justify-content: flex-end;
`;

const LeftBox = styled.div`
  background-color: #f76c6c;
  width: 20%;
  display: flex;
  flex-direction: column;
`;

const RightBox = styled.div`
  background-color: #eee;
  width: 80%;
`;

const Admin = (props) => {
  return (
    <Container>
      <LeftBox>
        <Logo/>
        <Menu user={props.user}/>
      </LeftBox>
      <RightBox>
        <Login user={props.user}/>
        <Profiles/>
        <ProfilesForApproval/>
        <FeatureProfiles/>
        <ActivateProfile/>
        <SearchProfiles/>
      </RightBox>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};

export default connect(mapStateToProps)(Admin);

