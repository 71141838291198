import styled from "styled-components";
import { connect } from "react-redux";
import Header from "../Header";
import Footer from "../Footer";
import ContactUs from "../ContactUs";
import Login from "../Login/index";

const Home = (props) => {
  return (
    <>
      <Header hideRegistrationPopup = "true"/>
      <Container>
        <Content>
            <Heading>Membership Plans</Heading><br/>
            <Plan>6 months premium membership - Rs 600</Plan><br/>
            <Plan>12 months premium membership - Rs 1200</Plan><br/><br/>
            <Heading>Account details</Heading><br/>
            <Plan>Account Name: VINAYAKAFOUNDATION</Plan><br/>
            <Plan>A/C No : 3878000101029926</Plan><br/>
            <Plan>IFS Code: PUNB0387800</Plan><br/>
            <Plan>Bank: Punjab National Bank</Plan><br/>
            <Plan>Branch: South Bazar, Kannur - 670002</Plan><br/><br/>
            <Plan>Contact +91 79073 00686 for any queries</Plan>
        </Content>
      </Container>
      <Container>
        <ContactUs/>
      </Container>
      <Footer/>
      { props.showLogin &&
        <Login/>
      }
    </>
  );
};

const Container = styled.div`
  padding-top: 10px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const Content = styled.div`
  margin-top: 80px;
`;

const Heading = styled.span`
  letter-spacing: 1px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
`;

const Plan = styled.span`
  font-family: "Poppins", sans-serif;
`;

const mapStatetoProps = (state) => {
  return {
    user: state.userState.user,
    showLogin: state.userState.showLogin
  }
}

export default connect(mapStatetoProps)(Home);
