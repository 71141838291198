import styled from "styled-components";

import {
  CASTE,
  COMPLEXION,
  EDUCATION,
  EMPLOYED_IN,
  FAMILY_TYPE,
  FAMILY_STATUS,
  GENDER,
  MOTHER_TOUNGE,
  PHYSICAL_STATUS,
  PROFILE_CREATED,
  RELIGION,
  STARS 
} from "../../helpers/dbFields";

const Content = styled.div`
  width: 100%;
  padding: 20px;
`;

const Line = styled.span`
  display: block;
  padding: 4px 20px;
`;

const LineBreak = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0px;
  border-bottom: 1px solid #fff;
`;


const ProfileDetails = ({props}) => {
  const profile = props.profile;
  const profileContacts = props.profileContacts;
  const profileType = profile.premium ? 'Premium' : 'Normal';
  const religion = profile.religion ? RELIGION[profile.religion] : '';
  const caste = profile.caste && profile.religion ? CASTE[profile.religion][profile.caste]: '';
  const star = profile.star ? STARS[profile.star] : '';
  const gender = profile.gender ? GENDER[profile.gender]: '';
  const complexion = profile.complexion ? COMPLEXION[profile.complexion] : '';
  const motherTounge = profile.mother_tounge ? MOTHER_TOUNGE[profile.mother_tounge] : '';
  const profileCreated = profile.profile_created ? PROFILE_CREATED[profile.profile_created] : '';
  const physicalStatus = profile.physical_status ? PHYSICAL_STATUS[profile.physical_status] : '';
  const education = profile.education ? EDUCATION[profile.education] : '';
  const employedIn = profile.employed ? EMPLOYED_IN[profile.employed] : '';
  const familyType = profile.family_type ? FAMILY_TYPE[profile.family_type] : '';
  const familyStatus = profile.family_status ? FAMILY_STATUS[profile.family_status] : '';
  const dob = profile.dob ? profile.dob.toDate().toDateString() : '';
  const mobile = profileContacts ? profileContacts.mobile : '';
  const email = profileContacts? profileContacts.email : '';
  const displayName = profile.display_name ? profile.display_name : '';
  const fullName = profile.full_name ? profile.full_name : '';
  const height = profile.height ? profile.height : '';
  const aboutMyself = profile.about_myself ? profile.about_myself : '';
  const aboutFamily = profile.about_family ? profile.about_family : '';
  const city = profile.city ? profile.city : '';
  const state = profile.state ? profile.state : '';
  const country = profile.country ? profile.country : '';
  const zipCode = profile.zip_code ? profile.zip_code : '';
  const jobName = profile.job_name ? profile.job_name : '';
  const nameOfEmployer = profile.name_of_employer ? profile.name_of_employer : '';
  const specialisation = profile.specialisation ? profile.specialisation : '';
  const annualIncome = profile.annual_income ? profile.annual_income : '';
  const dosham = profile.dosham ? profile.dosham : '';
  const preferredAgeFrom = profile.preferred_age_from ? profile.preferred_age_from : '';
  const preferredAgeTo = profile.preferred_age_to ? profile.preferred_age_to : '';
  const preferredEducation = profile.preferred_education ? profile.preferred_education : '';
  const preferredEmployment = profile.preferred_employment ? profile.preferred_employment : '';
  const preferredHeightFrom = profile.preferred_height_from ? profile.preferred_height_from : '';
  const preferredHeightTo = profile.preferred_height_to ? profile.preferred_height_to : '';
  const starsPreferred = profile.stars_preferred ? profile.stars_preferred : '';
  const aboutPreferredPartner = profile.about_preferred_partner ? profile.about_preferred_partner : '';



  return (
    <Content>
      please contact us to update your profile.
      <br/><br/>
      Call : +91 79073 00686
      Whatsapp: +91 79073 00686
    </Content>
  )
};

export default ProfileDetails;
