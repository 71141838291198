import styled from "styled-components";
import { connect } from "react-redux";
import React, { useState } from 'react';

import { hideLoginModal } from "../../store/actions";

import Email from "./Email";
import Google from "./Google";

const Login = (props) => {

  return (
    <Container>
        <Content>
            <Close onClick={props.hideLoginModal}>Close</Close>
            <Email/>
        </Content>
    </Container>
  );
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
`;

const Content = styled.div`
    background-color: #ffffff;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 10px 20px; 
`;

const Close = styled.div`
    float: right;
    cursor: pointer;
`;

const mapDipatchToProps = (dispatch) => ({
    hideLoginModal: () => dispatch(hideLoginModal())
});

export default connect(null, mapDipatchToProps)(Login);
