import { SET_PROFILES, SET_ADMIN_MENU } from "../actions/actionType";

const INITIAL_STATE = {
  user: null,
};

const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PROFILES:
      return {
        ...state,
        profiles: action.profiles,
      }
    case SET_ADMIN_MENU:
      return {
        ...state,
        menu: action.menu,
      }
    default:
      return state;
  }
}



export default adminReducer;
