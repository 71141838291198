import styled from "styled-components";
import { connect } from "react-redux";
import React, { useState } from 'react';

import { loginWithEmailAndPassword, loginWithVnkId, sendPasswordResetEmail } from "../../store/actions";
import { validate } from "../Shared/inputValidator";

const Email = (props) => {

  const [vnkId, setVnkId] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [showOption, setShowOption] = useState('login');
  const [forgotEmail, setForgotEmail] = useState('');

  const handleVnkIdChange = (event) => {
    setVnkId(event.target.value);
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const loginClick = () => {
    if(email === '' && vnkId!== '') {
      props.loginWithVnkId(vnkId.trim(), password.trim());
    } else if(email !== '') {
      props.loginWithEmailAndPassword(email.trim(), password.trim());
    }
  }

  const handleForgotEmailChange = (event) => {
    setForgotEmail(event.target.value);
  }

  const resetPasswordClick = () => {
    if(validate(["forgotEmail"])) {
      props.sendPasswordResetEmail(forgotEmail);
    }
  }

  const forgotPasswordClick = () => {
    setShowOption('password');
  }

  const loginLinkClick = () => {
    setShowOption('login');
  }

  return (
    <div>
      {showOption === 'login' &&
        <Container>
          <div>
            <h2>login</h2>
          </div>
          <div>
            <input
              type="text"
              name="vnkId"
              placeholder="VNK ID"
              value={ vnkId }
              onChange= { handleVnkIdChange }/>
          </div>
          <div>OR</div>
          <div>
            <input
              type="email" 
              name="email" 
              placeholder="Email Address" 
              value={ email } 
              onChange= { handleEmailChange }
            />
          </div>
          <div>
            <input
              type="password"
              name="password"
              placeholder="Password"
              value= { password }
              onChange= { handlePasswordChange }
            />
          </div>
          <div>
            <button onClick = { loginClick }>Login</button>
          </div>
          <Link onClick = { forgotPasswordClick }>Forgot Password</Link>
        </Container>
      }
      {showOption === 'password' &&
        <Container>
          <div>
            <h2>forgot password</h2>
          </div>
          <div>
            { !props.resetPasswordStatus &&
              <input
                type="email" 
                name="forgotEmail" 
                placeholder="Email Address" 
                value={ forgotEmail }
                data-error-message="Email format not valid"
                data-error-type="email"
                onChange= { handleForgotEmailChange }
              />
            }
            { props.resetPasswordStatus &&
              <span>We have share a password reset link to {forgotEmail}, Please use the link to reset your password</span>
            }
          </div>
          <div>
            { !props.resetPasswordStatus &&
              <button onClick = { resetPasswordClick }>Reset Password</button>
            }
          </div>
          <Link onClick = { loginLinkClick }>Login</Link>
        </Container>
      }
    </div>
  );
};

const Container = styled.div`
  font-family: "Open Sans", sans-serif;
  color: #444444;
  justify-content: center;

  div {
    padding: 6px 0px;
    text-align: left;
    display: flex;
    flex-direction: row;

    h2 {
      font-weight: 500;
      line-height: 1.2;
      font-size: 1.75rem;
      font-family: "Raleway", sans-serif;
    }

    input {
      width: 100%;
      min-width: 12px;
      height: calc(1em + .75rem + 1px);
      padding: .375rem .75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    button {
      background: #f76c6c;
      border-color: #f76c6c;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      vertical-align: middle;
      border: 1px solid transparent;
      padding: .375rem .75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: .25rem;
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      margin: auto;
      width: 100%;
      cursor: pointer;
    }
  }

  section {
    display: flex;
    justify-content: center;
    align-items: center;

    input[type="number"] {
      margin: 0 6px;
      width: 54px;
      font-size: 14px;
    }
  }

  label[htmlFor="dob"] {
    line-height: 50px;
  }
`;

const Link = styled.div`
  width: 100%;
  text-decoration: underline;
  color: #f76c6c;
  cursor: pointer;
`
const mapStateToProps = (state) => {
  return {
    resetPasswordStatus: state.userState.resetPasswordStatus,
  };
};

const mapDipatchToProps = (dispatch) => ({
  loginWithEmailAndPassword: (email, password) => dispatch(loginWithEmailAndPassword(email, password)),
  loginWithVnkId: (vnkId, password) => dispatch(loginWithVnkId(vnkId, password)),
  sendPasswordResetEmail: (email) => dispatch(sendPasswordResetEmail(email)),
});

export default connect(mapStateToProps, mapDipatchToProps)(Email);
