import styled from "styled-components";
import { connect } from "react-redux";
import React, {useState, useEffect} from 'react';

import Logo from '../Shared/Logo';
import ProfilePic from '../Shared/ProfilePic';
import ProfileDetails from '../Shared/ProfileDetails';
import EditProfileDetails from '../Shared/EditProfileDetails';
import PhotoUpload from '../Shared/PhotoUpload';
import AdvancedSearch from '../AdvancedSearch';
import ProfileInterests from '../ProfileInterests';
import Menu from './Menu';
import TopMenu from './TopMenu';
import Home from './Home';

import { getInterestedProfiles, updateProfile, clearSearchResult } from "../../store/actions";

const Container = styled.div`
  display: flex;
  height: 100vw;
  justify-content: flex-end;
`;

const LeftBox = styled.div`
  background-color: #f76c6c;
  width: 20%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    display:none;
  }
`;

const MobileHeader = styled.div`
  background-color: #f76c6c;
  width: 100%;
  display: flex;
  flex-direction: column;
  display:none;

  @media (max-width: 768px) {
    display:block;
    height:48px; 
    text-align: center;
    position: fixed;
    z-index: 99999;
  }
`;

const RightBox = styled.div`
  background-color: #eee;
  width: 80%;

  @media (max-width: 768px) {
    width: 100%;
    display:block;
    background-color: #fff;
    margin-top: 86px;
  }
`;

const ProfilePicContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10%;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ProfileName = styled.span`
  background-color: #F8E9A1;
  padding: 4px;
  letter-spacing: 1px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
`;

const Message = styled.div`
  padding: 20px;
`;

const MyAccount = (props) => {
  const [step, setStep] = useState('my_account');

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  if(!props.user) {
    window.location.href = "/";
    return ( <div>Thank You!!!</div>);
  } else {

    if(step === 'interests'){
      const interests = props.profile.interests;
      props.clearSearchResult();
      props.getInterestedProfiles(interests);
    }

    const isApprovedUser = props.profile.approved === true || props.profile.approved === '1';

    return (
      <Container>
        <MobileHeader>
          <Logo/>
          {props.profile &&
            <ProfilePicContainer>
              <ProfilePic props={ props }/>
              <ProfileName>{ props.profile.display_name }</ProfileName>
            </ProfilePicContainer>
          }
          <TopMenu setStep={setStep}/>
        </MobileHeader>
        <LeftBox>
          <Logo/>
          {props.profile &&
            <ProfilePicContainer>
              <ProfilePic props={ props }/>
              <ProfileName>{ props.profile.display_name }</ProfileName>
            </ProfilePicContainer>
          }
          <Menu setStep={setStep}/>
        </LeftBox>
        <RightBox>
          {step === 'my_account' &&
            <Home props={ props.user }/>
          }
          {step === 'profile' &&
            <ProfileDetails props={ props }/>
          }
          {step === 'editProfile' &&
            <EditProfileDetails props={ props }/>
          }
          {step === 'photos' &&
            <div>
              <PhotoUpload props={ props } />
            </div>
          }
          {step === 'search' && isApprovedUser &&
            <AdvancedSearch props={ props }/>
          }
          {step === 'search' && !isApprovedUser &&
            <Message>
              your profile is not verified, we will contact you soon for the verification. <br />
              For faster verification please contact us.
              <br/><br/>
              Call : +91 7907300686
              Whatsapp: +91 7907300686
            </Message>
          }
          {step === 'interests' &&
            <ProfileInterests/>
          }
        </RightBox>
      </Container>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    profile: state.profileState.profile,
    profileContacts: state.profileState.profileContacts,
  };
};

const mapDipatchToProps = (dispatch) => ({
  updateProfile: (user, profile, payload) => dispatch(updateProfile(user, profile, payload)),
  getInterestedProfiles: (uids) => dispatch(getInterestedProfiles(uids)),
  clearSearchResult: () => dispatch(clearSearchResult()),
});


export default connect(mapStateToProps, mapDipatchToProps)(MyAccount);
