import styled from "styled-components";
import { connect } from "react-redux";
import Header from "./Header";
import Banner from "./Banner";
import Footer from "./Footer";
import QuickRegistration from "./Registration/QuickRegistration";
import OurStories from "./OurStories";
import About from "./About";
import WellWishers from "./WellWishers";
import JoinUs from "./JoinUs";
import ContactUs from "./ContactUs";
import Login from "./Login/index";

//delete later once the search page is ready
import ProfileList from "./Profile/ProfileList";

const Home = (props) => {
  return (
    <>
      <Header/>
      <Banner/>
      <Container>
        <ProfileList/>
        <Section>
          <QuickRegistration/>
          <OurStories/>
        </Section>
        <About/>
        <WellWishers/>
      </Container>
      <JoinUs/>
      <Container>
        <ContactUs/>
      </Container>
      <Footer/>
      { props.showLogin &&
        <Login/>
      }
    </>
  );
};

const Container = styled.div`
  padding-top: 10px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
  }
`;

const mapStatetoProps = (state) => {
  return {
    user: state.userState.user,
    showLogin: state.userState.showLogin
  }
}

export default connect(mapStatetoProps)(Home);
