import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAeXtYrxpU2o6c6WkymFBmQe9pJzGIxny4",
  authDomain: "vinayaka-matrimony.firebaseapp.com",
  projectId: "vinayaka-matrimony",
  storageBucket: "vinayaka-matrimony.appspot.com",
  messagingSenderId: "39387931885",
  appId: "1:39387931885:web:5be1ba71bd14931565d8e4",
  measurementId: "G-EK9S5R4VLH"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db =  firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const emailProvider = new firebase.auth.EmailAuthProvider();
const storage = firebase.storage();
const timestamp = new firebase.firestore.Timestamp.now();

export { auth, provider, emailProvider, storage, timestamp };
export default db;