import { connect } from "react-redux";
import styled from "styled-components";

import ProfileBox from "./ProfileBox";

const ProfileList = (props) => {
	if (props.profiles) {
		return (
			<Container>
				{props.profiles.map((profile)=>{
					return <ProfileBox key={profile.uid} profile={profile} />
			})}
			</Container>
		);
	} else {
		return null;
	}
};

const Container = styled.div`
  background-color: #f8fbfe;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const mapStateToProps = (state) => {
  return {
    profiles: state.profileState.searchResults,
  };
};

export default connect(mapStateToProps, null)(ProfileList);
