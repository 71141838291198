import styled from "styled-components";
import { connect } from "react-redux";
import React, { useState } from 'react';

import Dropdown from "../../Shared/Dropdown";
import TextBox from "../../Shared/TextBox";
import TextArea from "../../Shared/TextArea";

import { FAMILY_TYPE, FAMILY_STATUS, RELIGION, CASTE, STARS } from "../../../helpers/dbFields";

const Step4 = ({ setPayload }) => {
  const [religion, setReligion] = useState('');
  const changeReligion = (key, val) => {
    setReligion(val);
    setPayload(key, val);
  }

  return (
    <Content id="step">
      <label htmlFor="family_type">Family Type ***</label>
      <Dropdown 
        placeholder='Family Type'
        setPayload={ setPayload }
        name = "family_type"
        options= { FAMILY_TYPE }
        errorMessage="Please select family type"
        errorType="required"
      />
      <label htmlFor="family_status">Family Status ***</label>
      <Dropdown 
        placeholder='Family Status'
        setPayload={ setPayload }
        name = "family_status"
        options= { FAMILY_STATUS }
        errorMessage="Please select family status"
        errorType="required"
      />
      <TextArea 
        placeholder='Few lines about my family'
        setPayload={ setPayload }
        name = "about_family"
      />
      <label htmlFor="religion">Religion ***</label>
      <Dropdown 
        placeholder='Religion'
        setPayload={ changeReligion }
        name = "religion"
        options= { RELIGION }
        errorMessage="Please select religion"
        errorType="required"
      />
      <label htmlFor="caste">Caste ***</label>
      <Dropdown
        placeholder='Caste'
        setPayload={ setPayload }
        name = "caste"
        options= { CASTE[religion] }
        errorMessage="Please select caste"
        errorType="required"
      />
      <label htmlFor="star">Star ***</label>
      <Dropdown
        placeholder='Star'
        setPayload={ setPayload }
        name = "star"
        options= { STARS }
        errorMessage="Please select star"
        errorType="required"
      />
      <TextBox placeholder='Dosham in Horoscope if any' setPayload={setPayload} name="dosham" />
    </Content>
  );
};

const Content = styled.div`
  width: 100%;
`;

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    profile: state.profileState.profile,
  };
};

const mapDipatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDipatchToProps)(Step4);
