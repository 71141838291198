import styled from "styled-components";
import React, { useState } from 'react';
import { remove, uniq } from "lodash";

const MultiCheckBox = ({ name, setPayload, options, errorMessage, errorType }) => {
  const [val, setVal] = useState([]);

  const getOptions = (options, setPayload, name) => {
    if(options) {
      return Object.keys(options).map((key) =>
        <CheckBoxContainer>
          <CheckBox
            type="checkbox"
            value={ key }
            key= { key }
            onClick = { e => { handleClick(e, setPayload, name) } }
          />
          <span>{options[key]}</span>
        </CheckBoxContainer>
      );
    } else {
      return null;
    }
  }
  
  const handleClick = (e, setPayload, name) => {
    let payload = val;
    if(e.target.checked) {
      payload.push(e.target.value);
    } else {
      remove(payload, (n) => {
        return e.target.value === n;
      });
    }
    payload = uniq(payload);
    setVal(payload);
    setPayload(name, payload);
  }

  return (
    <Container>
      <CheckBoxContainer>
        <CheckBox type="checkbox" value="ANY" key= "ANY" onClick = { e => { handleClick(e, setPayload, name) } }/>
        <span>Any</span>
      </CheckBoxContainer>
      {getOptions(options, setPayload, name)}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;

const CheckBoxContainer = styled.div`
  display: inline-flex;
  padding: 0px 6px!important;
  margin: 0px!important;
`;

const CheckBox = styled.input`
  margin-top: 14px!important;
`;

export default MultiCheckBox;
