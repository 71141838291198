import { SET_STATES, SET_CITIES } from "../actions/actionType";

const INITIAL_STATE = {
  states: {},
};

const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STATES:
      return {
        ...state,
        states: action.states,
      }
    case SET_CITIES:
      return {
        ...state,
        cities: action.cities,
      }
    default:
      return state;
  }
}

export default adminReducer;
