import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect } from 'react-router';

import { updateProfile } from "../../store/actions";

import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5";
import Step6 from "./Steps/Step6";

import { validate } from "../Shared/inputValidator";

const Registration = (props) => {
  const step = props.profile ? props.profile.step : '';
  if (step !== '') {
    return (
      <Container>
        <Content>
          <Header><h2>Begin your success story</h2></Header>
          <Message>All *** marked fields are mandatory</Message>
          <Body>
            <GetSteps props={props} />
          </Body>
          <Footer>
            <button 
              onClick= { () => { nextClick(props) } }
            >Next</button>
          </Footer>
        </Content>
      </Container>
    );
  } else {
    return null;
  }
};

const GetSteps = ({ props }) => {
  const step = props.profile ? props.profile.step : '';

  switch (step) {
    case 0:
      return (
        <Step1 setPayload={ setPayload } payload={payload} />
      );
    case 1:
      return (
        <Step2 setPayload={ setPayload } payload={payload} />
      );
    case 2:
      return (
        <Step3 setPayload={ setPayload } payload={payload} />
      );
    case 3:
      return (
        <Step4 setPayload={ setPayload } payload={payload} />
      );
    case 4:
      return (
        <Step5 setPayload={ setPayload } payload={payload} />
      );
    case 5:
      return (
        <Step6 setPayload={ setPayload } payload={payload} />
      );
    case 6:
      return (
        <Redirect to='/account' />
      );
    default:
      return null;
  }
}

let payload = {};

const setPayload = (key, value) => {
  payload[key] = value;
}

const nextClick = (props) => {
  const fieldsToValidate = [];
  const stepContainer = document.getElementById("step");
  stepContainer.childNodes.forEach(childNode => {
    if(childNode.attributes['data-error-type']) {
      fieldsToValidate.push(childNode.name);
    }
  });
  if(validate(fieldsToValidate)) {
    const step = props.profile ? props.profile.step : 0;
    props.updateProfile(props.user, props.profile, {...payload, ...{step: step + 1}});
  }
}

const Container = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
`;

const Content = styled.div`
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px;
  width: 70%;
`;

const Header = styled.div`
  padding: 2px 16px;
  h2 {
    color: #f76c6c;
  }
`;

const Message = styled.div`
  padding: 2px 16px;
  font-size:12px;
  color: #333;
  font-style: italic;
`;

const Body = styled.div`
  padding: 2px 16px;
  color: #124265;

  select, textarea, input, div, label, span {
    margin: 4px 0;
    padding: 4px 2px;
  }

  input[type="number"] {
    margin: 0 4px;
    width: 50px;
  }
`;

const Footer = styled.div`
  padding: 2px 16px;
  display: flex;

  button {
    margin-left: auto;
    padding: 10px;
    width: 100px;
    background-color: #f76c6c;
    border: none;
    color: #ffffff;
    font-weight: bold;
    font-size: 14px;
  }
`;

const Close = styled.span`
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  
  :hover, :focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`;


const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    profile: state.profileState.profile,
  };
};

const mapDipatchToProps = (dispatch) => ({
  updateProfile: (user, profile, payload) => dispatch(updateProfile(user, profile, payload)),
});

export default connect(mapStateToProps, mapDipatchToProps)(Registration);
