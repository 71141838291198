export const validate = (names) => {
	let valid = true;
	names.forEach((name) => {
		const el = document.getElementsByName(name);
		if(el) {
			const inputVal = el[0].value;
			const type = el[0].attributes['data-error-type'].value;
			switch (type) {
				case 'required':
					if(inputVal === "") {
						valid = false;
						setErrorMessage(el[0]);
					} else {
						clearErrorMessage(el[0]);
					}
					break;
				case 'onlyAlphabets':
					if (!/^[a-zA-Z]+$/.test(inputVal.replace(/\s+/g, ""))) {
						valid = false;
						setErrorMessage(el[0]);
					} else {
						clearErrorMessage(el[0]);
					}
					break;
				case 'email':
					if (!validateEmail(inputVal)) {
						valid = false;
						setErrorMessage(el[0]);
					} else {
						clearErrorMessage(el[0]);
					}
					break;
				case 'phone':
					if (!validatePhoneNumber(inputVal)) {
						valid = false;
						setErrorMessage(el[0]);
					} else {
						clearErrorMessage(el[0]);
					}
					break;
				case 'password':
					if (!validPassword(inputVal)) {
						valid = false;
						setErrorMessage(el[0]);
					} else {
						clearErrorMessage(el[0]);
					}
					break;
				case 'confirmPassword':
					const fieldToCompare = el[0].attributes.fieldToCompare.value;
					const passwordField1 = document.getElementsByName(fieldToCompare);
					if(passwordField1[0].value !== el[0].value) {
						valid = false;
						setErrorMessage(el[0]);
					} else {
						clearErrorMessage(el[0]);
					}
			}
		}
	});
	return valid;
};

const setErrorMessage = (el) => {
	const errorDiv = el.name + "_error";
	if(document.getElementById(errorDiv)) {
		document.getElementById(errorDiv).remove();
	}
	const messageSpan = document.createElement("span");
	messageSpan.innerHTML = el.attributes['data-error-message'].value;
	messageSpan.setAttribute("id", errorDiv);
	messageSpan.setAttribute("class", 'error-message');
	el.parentNode.insertBefore(messageSpan, el);
}

const clearErrorMessage = (el) => {
	const errorDiv = el.name + "_error";
	if(document.getElementById(errorDiv)) {
		document.getElementById(errorDiv).remove();
	}
}

const validateEmail = (email) => {
	return String(email)
	  .toLowerCase()
	  .match(
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	  );
};

const validatePhoneNumber = (phoneNumber) => {
	return String(phoneNumber)
	  .match(
		/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
	  );
};

const validPassword = (inputVal) => {
	/*return String(password) must have 8 characters with upper lower and special chars
	  .match(
		/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
	);*/
	if(inputVal.length < 8) {
		return false;
	}
	return true;
}