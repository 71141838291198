import { connect } from "react-redux";
import styled from "styled-components";
import React, { useState } from 'react';
import moment from "moment";

import {
  advancedSearch,
  clearSearchResult,
  clearSelectedProfile,
  sendInterest,
  updateProfile
} from "../store/actions";
import { AGE, CASTE, GENDER, RELIGION } from "../helpers/dbFields";

import ProfileDetails from './Shared/ProfileDetails';
import ProfileList from "./Profile/ProfileList";

const AdvancedSearch = (props) => {

  const { profile } = props;
  
  const [selectedGender, setSelectedGender] = useState(
    profile.gender === 'MALE' ? 'FEMALE' : 'MALE'
  );
  const [selectedAge1, setSelectedAge1] = useState('18');
  const [selectedAge2, setSelectedAge2] = useState('18');
  const [selectedReligion, setSelectedReligion] = useState('HINDU');
  const [selectedCaste, setSelectedCaste] = useState('ANY');
  
  const genders = Object.keys(GENDER).map((key) => {
    if(profile.gender !== key) {
      return <option value={key}>{GENDER[key]}</option>
    }
  });

  const age1 = Object.keys(AGE).map((key) =>
    <option value={key}>{AGE[key]}</option>
  );

  const age2 = Object.keys(AGE).map((key) =>
    <option value={key}>{AGE[key]}</option>
  );

  const religions = Object.keys(RELIGION).map((key) =>
    <option value={key}>{RELIGION[key]}</option>
  );

  const castes = Object.keys(CASTE[selectedReligion]).map((key) =>
    <option value={key}>{CASTE[selectedReligion][key]}</option>
  );

  const handleReligionChange = (event) => {
    setSelectedReligion(event.target.value);
  }

  const handleCasteChange = (event) => {
    setSelectedCaste(event.target.value);
  }

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  }

  const handleAge1Change = (event) => {
    setSelectedAge1(event.target.value);
  }

  const handleAge2Change = (event) => {
    setSelectedAge2(event.target.value);
  }

  const handleSearch = () => {
    props.advancedSearch(
      {
        gender: selectedGender,
        religion: selectedReligion,
        caste: selectedCaste,
        dob1: moment().subtract(selectedAge1, 'years').format(),
        dob2: moment().subtract(selectedAge2, 'years').format(),
      }
    );
  }

  const backToSearch = () => {
    props.clearSearchResult();
  }

  const backToSearchResults = () => {
    props.clearSelectedProfile();
  }

  const sendInterest = (uid) => {
    const interests = Array.isArray(props.profile.interests) ? props.profile.interests : [];
    const interestedDate = new Date();
    const myUid = props.user.uid;
    const interest = {
      date: interestedDate,
      senderUid: myUid,
      receiverUid: uid
    };
    interests.push(interest);
    props.updateProfile(props.user, props.profile, { interests });
    props.sendInterest(uid, myUid, interest);
  }

  return (
    <Container>
      { (!props.profiles && !props.selectedProfile) &&
      <SearchBox>
        <div>
          <div>I'm looking for a</div>
          <div>
            <select onChange= { handleGenderChange }>
              { genders }
            </select>
          </div>
        </div>
        <div>
          <div>in the age group</div>
          <section>
            <div>
              <select onChange= { handleAge1Change }>
                { age1 }
              </select>
            </div>
            <div>to</div>
            <div>
              <select onChange= { handleAge2Change }>
                { age2 }
              </select>
            </div>
          </section>
        </div>
        <div>
          <div>of religion</div>
          <div>
            <select onChange={ handleReligionChange }>
              { religions }
            </select>
          </div>
        </div>
        <div>
          <div>of caste</div>
          <div>
            <select onChange={ handleCasteChange }>
              { castes }
            </select>
          </div>
        </div>
        <div>
          <div>VNK ID (Optional)</div>
          <div><input type="text" /></div>
        </div>
        <div>
          <Button type="button" value="Search" onClick={ handleSearch }/>
        </div>
      </SearchBox>
      }
      { (props.profiles && !props.selectedProfile) &&
        <Content>
          <a onClick={ backToSearch }>Modify Search</a>
          <ProfileList></ProfileList>
        </Content>
      }
      {
        props.selectedProfile &&
        <Content>
          <a onClick={ backToSearchResults }>Go back to search results</a>
          <button onClick={ ()=>sendInterest(props.selectedProfile.uid) }>Send Interest</button>
          <ProfileDetails props={ { profile: props.selectedProfile, profileContacts: props.setSelectedProfileContacts, me: props.profile } }></ProfileDetails>
        </Content>
      }
    </Container>
  );
};

const Container = styled.div`
width: 100%;
`;

const SearchBox = styled.div`
  background-color: #EDEAE5;
  font-family: "Open Sans", sans-serif;
  color: #444444;
  justify-content: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  padding: 20px;
`;

const Button = styled.input`
  color: #F8E9A1;
  background: #374785;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  border-radius: 5px;
  border-style: none;
  padding: 4px 20px;
  cursor: pointer;
`;

const Content = styled.div`
  a {
    font-size: 20px;
    font-weight: bold;
    color: #f76c6c;
    padding: 20px;
    line-height: 40px;
    text-decoration: underline;
  }
  
  button {
    background-color: #F8E9A1;
    color: #444444;
    font-size: 16px;
    border: 1px solid #eee;
    padding: 10px;
    margin: 0 20px;
  }
`;

const mapDipatchToProps = (dispatch) => ({
  advancedSearch: (selections) => dispatch(advancedSearch(selections)),
  clearSelectedProfile: () => dispatch(clearSelectedProfile()),
  clearSearchResult: () => dispatch(clearSearchResult()),
  sendInterest: (uid, myUid, interests) => dispatch(sendInterest(uid, myUid, interests)),
  updateProfile: (user, profile, interests) => dispatch(updateProfile(user, profile, interests)),
});

const mapStateToProps = (state) => {
  return {
    profile: state.profileState.profile,
    profiles: state.profileState.searchResults,
    selectedProfile: state.profileState.selectedProfile,
    setSelectedProfileContacts: state.profileState.setSelectedProfileContacts,
    user: state.userState.user,
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(AdvancedSearch);
