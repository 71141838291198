import styled from "styled-components";
import { connect } from "react-redux";
import React, { useState } from 'react';

import Dropdown from "../../Shared/Dropdown";
import TextBox from "../../Shared/TextBox";

import { EDUCATION, EMPLOYED_IN, ANNUAL_INCOME, COUNTRY } from "../../../helpers/dbFields";

import { getStates, getCities } from "../../../store/actions";

const Step3 = ({ setPayload, getStates, getCities, states, cities }) => {

  const [country, setCountry] = useState('');
  const [state1, setState1] = useState('');

  const changeCountry = (key, val) => {
    setCountry(val);
    setPayload(key, val);
    getStates(val);
  }
  const changeState1 = (key, val) => {
    setState1(val);
    setPayload(key, val);
    setPayload('state_of_work_name', states[val]);
    getCities(country, val);
  }

  return (
    <Content id="step">
      <label htmlFor="education">Education ***</label>
      <Dropdown 
        placeholder='Education'
        setPayload={ setPayload }
        name = "education"
        options= { EDUCATION }
        errorMessage="Please select education"
        errorType="required"
      />
      <TextBox
        placeholder='Specialisation ***'
        setPayload={setPayload}
        name="specialisation"
        errorMessage="Please enter specialisation"
        errorType="required"
      />
      <label htmlFor="employed">Employed in ***</label>
      <Dropdown 
        placeholder='Employed in'
        setPayload={ setPayload }
        name = "employed"
        options= { EMPLOYED_IN }
        errorMessage="Please select employed in"
        errorType="required"
      />
      <TextBox
        placeholder='Name of Employer ***'
        setPayload={setPayload}
        name="name_of_employer"
        errorMessage="Please enter name of the employer"
        errorType="required"
      />
      <label htmlFor="annual_income">Annual income (Indian - Rs.) ***</label>
      <Dropdown 
        placeholder='Annual income'
        setPayload={ setPayload }
        name = "annual_income"
        options= { ANNUAL_INCOME }
        errorMessage="Please select Annual income"
        errorType="required"
      />
      <TextBox
        placeholder='Job Name / Position ***'
        setPayload={setPayload}
        name="job_name"
        errorMessage="Please enter job name or position"
        errorType="required"
      />
      <label htmlFor="country">Country you are working</label>
      <Dropdown 
        placeholder='Country you are working'
        setPayload={ changeCountry }
        name = "country_of_work"
        options= { COUNTRY }
      />
      <label htmlFor="state">State you are working</label>
      <Dropdown 
        placeholder='State you are working'
        setPayload={ changeState1 }
        name = "state_of_work"
        options= { states }
      />
      <label htmlFor="city">City you are working</label>
      <Dropdown 
        placeholder='City you are working'
        setPayload={ setPayload }
        name = "city_of_work"
        options= { cities }
      />
    </Content>
  );
};

const Content = styled.div`
  width: 100%;
`;

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    profile: state.profileState.profile,
    states: state.countriesState.states,
    cities: state.countriesState.cities
  };
};

const mapDipatchToProps = (dispatch) => ({
  getStates: (key) => dispatch(getStates(key)),
  getCities: (county, state) => dispatch(getCities(county, state)),
});

export default connect(mapStateToProps, mapDipatchToProps)(Step3);
