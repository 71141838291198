export const menuItems = (props) => {
    return [
      {
        name: 'Profile',
        id: 'profile',
        onClick: ()=> {
          props.setStep('profile');
        },
      },
      {
        name: 'Edit Profile',
        id: 'editProfile',
        onClick: ()=> {
          props.setStep('editProfile');
        },
      },
      {
        name: 'Photos',
        id: 'photos',
        onClick: ()=> {
          props.setStep('photos');
        },
      },
      {
        name: 'Search',
        id: 'search',
        onClick: ()=> {
          props.setStep('search');
        },
      },
      {
        name: 'Profile Interests',
        id: 'profileInterest',
        onClick: ()=> {
          props.setStep('interests');
        },
      },
      {
        name: 'Logout',
        id: 'logout',
        onClick: props.signOut,
      }
    ];
  };