import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";

import { setSelectedProfile, getProfileContacts } from "../../store/actions";

import { EDUCATION } from "../../helpers/dbFields";

const ProfileBox = (props) => {
  const profile = props.profile;

  const dummyPhotoURL = 
    props.profile.gender === 'MALE' ? 
      'https://firebasestorage.googleapis.com/v0/b/vinayaka-matrimony.appspot.com/o/Male.jpeg?alt=media&token=9d4771bc-745d-4a25-9148-a3f79c880cdb'
    : 'https://firebasestorage.googleapis.com/v0/b/vinayaka-matrimony.appspot.com/o/Female.jpeg?alt=media&token=cb4d3f60-c9ea-461a-9c95-269412c7e3c7';
  
  const profilePic = profile.photos && profile.photos.length > 0
    ? profile.photos[0]
    : dummyPhotoURL;

  const age = profile.dob ? moment(profile.dob.toDate()).format('DD-MM-YYYY') : '';

  const isUid = (result) => {
    return result.uid === profile.uid;
  }

  const viewProfile = (uid) => {
    const selectedProfile = props.searchResults.find(isUid);
		props.setSelectedProfile(selectedProfile);
    props.getProfileContacts(selectedProfile, "OTHER");
	};

  return (
    <Container>
        <ImageBox>
          <img src={ profilePic } alt=""/>
        </ImageBox>
        <MemberInfo>
            <h4>{profile.display_name || profile.full_name}</h4>
            <H7>{profile.vnkId || profile.user_name}</H7><br/>
            <span>{profile.job_name}</span><br/>
            <span>{EDUCATION[profile.education]}</span><br/>
            <span>{age}</span><br/>
            <span>{profile.height}</span><br/>
            <span>{profile.city}</span><br/>
            <a onClick={()=>viewProfile(profile.uid)}>View Profile</a>
        </MemberInfo>
    </Container>
  );
};

const Container = styled.div`
  justify-content: center;
  padding-right: 15px;
  padding-left: 15px;
  font-family: "Open Sans", sans-serif;
  color: #444444;
  margin-bottom: 20px;
  overflow: hidden;
  text-align: center;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 15px rgb(18 66 101 / 8%);
  width: 230px;
  margin: 14px;
`;

const ImageBox = styled.div`
  overflow: hidden;
  height: 180px;

  img {
    max-width: 100%;
    height: auto;
  }
`;

const MemberInfo = styled.div`
  padding: 25px 15px;

  h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 18px;
    color: #124265;
  }

  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
  }
`;

const H7 = styled.span`
  font-size: 18px;
`;

const mapStateToProps = (state) => {
  return {
    searchResults: state.profileState.searchResults,
  };
};

const mapDipatchToProps = (dispatch) => ({
  setSelectedProfile: (selectedProfile) => dispatch(setSelectedProfile(selectedProfile)),
  getProfileContacts: (selectedProfile, who) => dispatch(getProfileContacts(selectedProfile, who)),
});

export default connect(mapStateToProps, mapDipatchToProps)(ProfileBox);
