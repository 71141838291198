import styled from "styled-components";
import { connect } from "react-redux";
import React, {useState} from 'react';

import { getInterests, getInterestedProfiles } from "../../store/actions";

import AdvancedSearch from '../AdvancedSearch';
import PhotoUpload from '../Shared/PhotoUpload';

const Container = styled.div`
  padding: 10px;
`;

const ProfilePicContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10%;

  @media (min-width: 500px) {
    display: none !important;
  }
`;

const Title = styled.div`
  padding: 10px;
  text-align: center;
  font-size: 17px;
  @media (min-width: 500px) {
    text-align: left;
  }
`;

const MyAccount = (props) => {
    const [isInterestFetched, setIsInterestFetched] = useState(false);
    const [isInterestProfileFetched, setIsInterestProfileFetched] = useState(false);
    if(props.profileInterests && !isInterestProfileFetched) {
        setIsInterestProfileFetched(true);
        const interests = props.profileInterests.interests;
        const interestedProfileIds = interests.reduce(function (result, item) {
            result.push(item.senderUid);
            return result;
        }, []);
        props.getInterestedProfiles(interestedProfileIds);
    } else if(!props.profileInterests && !isInterestFetched) {
        setIsInterestFetched(true);
        props.getInterests(props.user.uid);
    }
    const isProfilePicSet = props.profile.profile_pic ? true : false;
    return (
        <Container>
            <Title>Welcome to Vinayaka Matrimony!!!</Title>
            {!isProfilePicSet && 
              <div>
                Please upload and set a profile picture for better reach
                <PhotoUpload props={ props } />
              </div>
            }
        </Container>
    );
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    profile: state.profileState.profile,
    profileInterests: state.profileState.profileInterests
  };
};

const mapDipatchToProps = (dispatch) => ({
  getInterests: (uid) => dispatch(getInterests(uid)),
  getInterestedProfiles: (uids) => dispatch(getInterestedProfiles(uids)),
});


export default connect(mapStateToProps, mapDipatchToProps)(MyAccount);
