import styled from "styled-components";

const Footer = (props) => {
  return (
    <Container>
      © Copyright Vinayaka Matrimony. All Rights Reserved
    </Container>
  );
};  

const Container = styled.div`
  padding: 20px;
  text-align: center;
  background: #f8fbfe;
`;

export default Footer;
