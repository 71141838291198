const COUNTRY = {
  "AFGHANISTAN": "Afghanistan",
  "ALAND_ISLANDS": "Aland Islands",
  "ALBANIA": "Albania",
  "ALGERIA": "Algeria",
  "AMERICAN_SAMOA": "American Samoa",
  "ANDORRA": "Andorra",
  "ANGOLA": "Angola",
  "ANGUILLA": "Anguilla",
  "ANTARCTICA": "Antarctica",
  "ANTIGUA_AND_BARBUDA": "Antigua And Barbuda",
  "ARGENTINA": "Argentina",
  "ARMENIA": "Armenia",
  "ARUBA": "Aruba",
  "AUSTRALIA": "Australia",
  "AUSTRIA": "Austria",
  "AZERBAIJAN": "Azerbaijan",
  "BAHRAIN": "Bahrain",
  "BANGLADESH": "Bangladesh",
  "BARBADOS": "Barbados",
  "BELARUS": "Belarus",
  "BELGIUM": "Belgium",
  "BELIZE": "Belize",
  "BENIN": "Benin",
  "BERMUDA": "Bermuda",
  "BHUTAN": "Bhutan",
  "BOLIVIA": "Bolivia",
  "BONAIRE,_SINT_EUSTATIUS_AND_SABA": "Bonaire, Sint Eustatius and Saba",
  "BOSNIA_AND_HERZEGOVINA": "Bosnia and Herzegovina",
  "BOTSWANA": "Botswana",
  "BOUVET_ISLAND": "Bouvet Island",
  "BRAZIL": "Brazil",
  "BRITISH_INDIAN_OCEAN_TERRITORY": "British Indian Ocean Territory",
  "BRUNEI": "Brunei",
  "BULGARIA": "Bulgaria",
  "BURKINA_FASO": "Burkina Faso",
  "BURUNDI": "Burundi",
  "CAMBODIA": "Cambodia",
  "CAMEROON": "Cameroon",
  "CANADA": "Canada",
  "CAPE_VERDE": "Cape Verde",
  "CAYMAN_ISLANDS": "Cayman Islands",
  "CENTRAL_AFRICAN_REPUBLIC": "Central African Republic",
  "CHAD": "Chad",
  "CHILE": "Chile",
  "CHINA": "China",
  "CHRISTMAS_ISLAND": "Christmas Island",
  "COCOS_(KEELING)_ISLANDS": "Cocos (Keeling) Islands",
  "COLOMBIA": "Colombia",
  "COMOROS": "Comoros",
  "CONGO": "Congo",
  "COOK_ISLANDS": "Cook Islands",
  "COSTA_RICA": "Costa Rica",
  "COTE_D'IVOIRE_(IVORY_COAST)": "Cote D'Ivoire (Ivory Coast)",
  "CROATIA": "Croatia",
  "CUBA": "Cuba",
  "CURAÇAO": "Curaçao",
  "CYPRUS": "Cyprus",
  "CZECH_REPUBLIC": "Czech Republic",
  "DEMOCRATIC_REPUBLIC_OF_THE_CONGO": "Democratic Republic of the Congo",
  "DENMARK": "Denmark",
  "DJIBOUTI": "Djibouti",
  "DOMINICA": "Dominica",
  "DOMINICAN_REPUBLIC": "Dominican Republic",
  "EAST_TIMOR": "East Timor",
  "ECUADOR": "Ecuador",
  "EGYPT": "Egypt",
  "EL_SALVADOR": "El Salvador",
  "EQUATORIAL_GUINEA": "Equatorial Guinea",
  "ERITREA": "Eritrea",
  "ESTONIA": "Estonia",
  "ETHIOPIA": "Ethiopia",
  "FALKLAND_ISLANDS": "Falkland Islands",
  "FAROE_ISLANDS": "Faroe Islands",
  "FIJI_ISLANDS": "Fiji Islands",
  "FINLAND": "Finland",
  "FRANCE": "France",
  "FRENCH_GUIANA": "French Guiana",
  "FRENCH_POLYNESIA": "French Polynesia",
  "FRENCH_SOUTHERN_TERRITORIES": "French Southern Territories",
  "GABON": "Gabon",
  "GAMBIA_THE": "Gambia The",
  "GEORGIA": "Georgia",
  "GERMANY": "Germany",
  "GHANA": "Ghana",
  "GIBRALTAR": "Gibraltar",
  "GREECE": "Greece",
  "GREENLAND": "Greenland",
  "GRENADA": "Grenada",
  "GUADELOUPE": "Guadeloupe",
  "GUAM": "Guam",
  "GUATEMALA": "Guatemala",
  "GUERNSEY_AND_ALDERNEY": "Guernsey and Alderney",
  "GUINEA": "Guinea",
  "GUINEA-BISSAU": "Guinea-Bissau",
  "GUYANA": "Guyana",
  "HAITI": "Haiti",
  "HEARD_ISLAND_AND_MCDONALD_ISLANDS": "Heard Island and McDonald Islands",
  "HONDURAS": "Honduras",
  "HONG_KONG_S.A.R.": "Hong Kong S.A.R.",
  "HUNGARY": "Hungary",
  "ICELAND": "Iceland",
  "INDIA": "India",
  "INDONESIA": "Indonesia",
  "IRAN": "Iran",
  "IRAQ": "Iraq",
  "IRELAND": "Ireland",
  "ISRAEL": "Israel",
  "ITALY": "Italy",
  "JAMAICA": "Jamaica",
  "JAPAN": "Japan",
  "JERSEY": "Jersey",
  "JORDAN": "Jordan",
  "KAZAKHSTAN": "Kazakhstan",
  "KENYA": "Kenya",
  "KIRIBATI": "Kiribati",
  "KOSOVO": "Kosovo",
  "KUWAIT": "Kuwait",
  "KYRGYZSTAN": "Kyrgyzstan",
  "LAOS": "Laos",
  "LATVIA": "Latvia",
  "LEBANON": "Lebanon",
  "LESOTHO": "Lesotho",
  "LIBERIA": "Liberia",
  "LIBYA": "Libya",
  "LIECHTENSTEIN": "Liechtenstein",
  "LITHUANIA": "Lithuania",
  "LUXEMBOURG": "Luxembourg",
  "MACAU_S.A.R.": "Macau S.A.R.",
  "MACEDONIA": "Macedonia",
  "MADAGASCAR": "Madagascar",
  "MALAWI": "Malawi",
  "MALAYSIA": "Malaysia",
  "MALDIVES": "Maldives",
  "MALI": "Mali",
  "MALTA": "Malta",
  "MAN_(ISLE_OF)": "Man (Isle of)",
  "MARSHALL_ISLANDS": "Marshall Islands",
  "MARTINIQUE": "Martinique",
  "MAURITANIA": "Mauritania",
  "MAURITIUS": "Mauritius",
  "MAYOTTE": "Mayotte",
  "MEXICO": "Mexico",
  "MICRONESIA": "Micronesia",
  "MOLDOVA": "Moldova",
  "MONACO": "Monaco",
  "MONGOLIA": "Mongolia",
  "MONTENEGRO": "Montenegro",
  "MONTSERRAT": "Montserrat",
  "MOROCCO": "Morocco",
  "MOZAMBIQUE": "Mozambique",
  "MYANMAR": "Myanmar",
  "NAMIBIA": "Namibia",
  "NAURU": "Nauru",
  "NEPAL": "Nepal",
  "NETHERLANDS": "Netherlands",
  "NEW_CALEDONIA": "New Caledonia",
  "NEW_ZEALAND": "New Zealand",
  "NICARAGUA": "Nicaragua",
  "NIGER": "Niger",
  "NIGERIA": "Nigeria",
  "NIUE": "Niue",
  "NORFOLK_ISLAND": "Norfolk Island",
  "NORTH_KOREA": "North Korea",
  "NORTHERN_MARIANA_ISLANDS": "Northern Mariana Islands",
  "NORWAY": "Norway",
  "OMAN": "Oman",
  "PAKISTAN": "Pakistan",
  "PALAU": "Palau",
  "PALESTINIAN_TERRITORY_OCCUPIED": "Palestinian Territory Occupied",
  "PANAMA": "Panama",
  "PAPUA_NEW_GUINEA": "Papua new Guinea",
  "PARAGUAY": "Paraguay",
  "PERU": "Peru",
  "PHILIPPINES": "Philippines",
  "PITCAIRN_ISLAND": "Pitcairn Island",
  "POLAND": "Poland",
  "PORTUGAL": "Portugal",
  "PUERTO_RICO": "Puerto Rico",
  "QATAR": "Qatar",
  "REUNION": "Reunion",
  "ROMANIA": "Romania",
  "RUSSIA": "Russia",
  "RWANDA": "Rwanda",
  "SAINT_HELENA": "Saint Helena",
  "SAINT_KITTS_AND_NEVIS": "Saint Kitts And Nevis",
  "SAINT_LUCIA": "Saint Lucia",
  "SAINT_PIERRE_AND_MIQUELON": "Saint Pierre and Miquelon",
  "SAINT_VINCENT_AND_THE_GRENADINES": "Saint Vincent And The Grenadines",
  "SAINT-BARTHELEMY": "Saint-Barthelemy",
  "SAINT-MARTIN_(FRENCH_PART)": "Saint-Martin (French part)",
  "SAMOA": "Samoa",
  "SAN_MARINO": "San Marino",
  "SAO_TOME_AND_PRINCIPE": "Sao Tome and Principe",
  "SAUDI_ARABIA": "Saudi Arabia",
  "SENEGAL": "Senegal",
  "SERBIA": "Serbia",
  "SEYCHELLES": "Seychelles",
  "SIERRA_LEONE": "Sierra Leone",
  "SINGAPORE": "Singapore",
  "SINT_MAARTEN_(DUTCH_PART)": "Sint Maarten (Dutch part)",
  "SLOVAKIA": "Slovakia",
  "SLOVENIA": "Slovenia",
  "SOLOMON_ISLANDS": "Solomon Islands",
  "SOMALIA": "Somalia",
  "SOUTH_AFRICA": "South Africa",
  "SOUTH_GEORGIA": "South Georgia",
  "SOUTH_KOREA": "South Korea",
  "SOUTH_SUDAN": "South Sudan",
  "SPAIN": "Spain",
  "SRI_LANKA": "Sri Lanka",
  "SUDAN": "Sudan",
  "SURINAME": "Suriname",
  "SVALBARD_AND_JAN_MAYEN_ISLANDS": "Svalbard And Jan Mayen Islands",
  "SWAZILAND": "Swaziland",
  "SWEDEN": "Sweden",
  "SWITZERLAND": "Switzerland",
  "SYRIA": "Syria",
  "TAIWAN": "Taiwan",
  "TAJIKISTAN": "Tajikistan",
  "TANZANIA": "Tanzania",
  "THAILAND": "Thailand",
  "THE_BAHAMAS": "The Bahamas",
  "TOGO": "Togo",
  "TOKELAU": "Tokelau",
  "TONGA": "Tonga",
  "TRINIDAD_AND_TOBAGO": "Trinidad And Tobago",
  "TUNISIA": "Tunisia",
  "TURKEY": "Turkey",
  "TURKMENISTAN": "Turkmenistan",
  "TURKS_AND_CAICOS_ISLANDS": "Turks And Caicos Islands",
  "TUVALU": "Tuvalu",
  "UGANDA": "Uganda",
  "UKRAINE": "Ukraine",
  "UNITED_ARAB_EMIRATES": "United Arab Emirates",
  "UNITED_KINGDOM": "United Kingdom",
  "UNITED_STATES": "United States",
  "UNITED_STATES_MINOR_OUTLYING_ISLANDS": "United States Minor Outlying Islands",
  "URUGUAY": "Uruguay",
  "UZBEKISTAN": "Uzbekistan",
  "VANUATU": "Vanuatu",
  "VATICAN_CITY_STATE_(HOLY_SEE)": "Vatican City State (Holy See)",
  "VENEZUELA": "Venezuela",
  "VIETNAM": "Vietnam",
  "VIRGIN_ISLANDS_(BRITISH)": "Virgin Islands (British)",
  "VIRGIN_ISLANDS_(US)": "Virgin Islands (US)",
  "WALLIS_AND_FUTUNA_ISLANDS": "Wallis And Futuna Islands",
  "WESTERN_SAHARA": "Western Sahara",
  "YEMEN": "Yemen",
  "ZAMBIA": "Zambia",
  "ZIMBABWE": "Zimbabwe"
};

const COUNTRY_ID_MAP = {
  "AFGHANISTAN": "AF",
  "ALAND_ISLANDS": "AX",
  "ALBANIA": "AL",
  "ALGERIA": "DZ",
  "AMERICAN_SAMOA": "AS",
  "ANDORRA": "AD",
  "ANGOLA": "AO",
  "ANGUILLA": "AI",
  "ANTARCTICA": "AQ",
  "ANTIGUA_AND_BARBUDA": "AG",
  "ARGENTINA": "AR",
  "ARMENIA": "AM",
  "ARUBA": "AW",
  "AUSTRALIA": "AU",
  "AUSTRIA": "AT",
  "AZERBAIJAN": "AZ",
  "BAHRAIN": "BH",
  "BANGLADESH": "BD",
  "BARBADOS": "BB",
  "BELARUS": "BY",
  "BELGIUM": "BE",
  "BELIZE": "BZ",
  "BENIN": "BJ",
  "BERMUDA": "BM",
  "BHUTAN": "BT",
  "BOLIVIA": "BO",
  "BONAIRE,_SINT_EUSTATIUS_AND_SABA": "BQ",
  "BOSNIA_AND_HERZEGOVINA": "BA",
  "BOTSWANA": "BW",
  "BOUVET_ISLAND": "BV",
  "BRAZIL": "BR",
  "BRITISH_INDIAN_OCEAN_TERRITORY": "IO",
  "BRUNEI": "BN",
  "BULGARIA": "BG",
  "BURKINA_FASO": "BF",
  "BURUNDI": "BI",
  "CAMBODIA": "KH",
  "CAMEROON": "CM",
  "CANADA": "CA",
  "CAPE_VERDE": "CV",
  "CAYMAN_ISLANDS": "KY",
  "CENTRAL_AFRICAN_REPUBLIC": "CF",
  "CHAD": "TD",
  "CHILE": "CL",
  "CHINA": "CN",
  "CHRISTMAS_ISLAND": "CX",
  "COCOS_(KEELING)_ISLANDS": "CC",
  "COLOMBIA": "CO",
  "COMOROS": "KM",
  "CONGO": "CG",
  "COOK_ISLANDS": "CK",
  "COSTA_RICA": "CR",
  "COTE_D'IVOIRE_(IVORY_COAST)": "CI",
  "CROATIA": "HR",
  "CUBA": "CU",
  "CURAÇAO": "CW",
  "CYPRUS": "CY",
  "CZECH_REPUBLIC": "CZ",
  "DEMOCRATIC_REPUBLIC_OF_THE_CONGO": "CD",
  "DENMARK": "DK",
  "DJIBOUTI": "DJ",
  "DOMINICA": "DM",
  "DOMINICAN_REPUBLIC": "DO",
  "EAST_TIMOR": "TL",
  "ECUADOR": "EC",
  "EGYPT": "EG",
  "EL_SALVADOR": "SV",
  "EQUATORIAL_GUINEA": "GQ",
  "ERITREA": "ER",
  "ESTONIA": "EE",
  "ETHIOPIA": "ET",
  "FALKLAND_ISLANDS": "FK",
  "FAROE_ISLANDS": "FO",
  "FIJI_ISLANDS": "FJ",
  "FINLAND": "FI",
  "FRANCE": "FR",
  "FRENCH_GUIANA": "GF",
  "FRENCH_POLYNESIA": "PF",
  "FRENCH_SOUTHERN_TERRITORIES": "TF",
  "GABON": "GA",
  "GAMBIA_THE": "GM",
  "GEORGIA": "GE",
  "GERMANY": "DE",
  "GHANA": "GH",
  "GIBRALTAR": "GI",
  "GREECE": "GR",
  "GREENLAND": "GL",
  "GRENADA": "GD",
  "GUADELOUPE": "GP",
  "GUAM": "GU",
  "GUATEMALA": "GT",
  "GUERNSEY_AND_ALDERNEY": "GG",
  "GUINEA": "GN",
  "GUINEA-BISSAU": "GW",
  "GUYANA": "GY",
  "HAITI": "HT",
  "HEARD_ISLAND_AND_MCDONALD_ISLANDS": "HM",
  "HONDURAS": "HN",
  "HONG_KONG_S.A.R.": "HK",
  "HUNGARY": "HU",
  "ICELAND": "IS",
  "INDIA": "IN",
  "INDONESIA": "ID",
  "IRAN": "IR",
  "IRAQ": "IQ",
  "IRELAND": "IE",
  "ISRAEL": "IL",
  "ITALY": "IT",
  "JAMAICA": "JM",
  "JAPAN": "JP",
  "JERSEY": "JE",
  "JORDAN": "JO",
  "KAZAKHSTAN": "KZ",
  "KENYA": "KE",
  "KIRIBATI": "KI",
  "KOSOVO": "XK",
  "KUWAIT": "KW",
  "KYRGYZSTAN": "KG",
  "LAOS": "LA",
  "LATVIA": "LV",
  "LEBANON": "LB",
  "LESOTHO": "LS",
  "LIBERIA": "LR",
  "LIBYA": "LY",
  "LIECHTENSTEIN": "LI",
  "LITHUANIA": "LT",
  "LUXEMBOURG": "LU",
  "MACAU_S.A.R.": "MO",
  "MACEDONIA": "MK",
  "MADAGASCAR": "MG",
  "MALAWI": "MW",
  "MALAYSIA": "MY",
  "MALDIVES": "MV",
  "MALI": "ML",
  "MALTA": "MT",
  "MAN_(ISLE_OF)": "IM",
  "MARSHALL_ISLANDS": "MH",
  "MARTINIQUE": "MQ",
  "MAURITANIA": "MR",
  "MAURITIUS": "MU",
  "MAYOTTE": "YT",
  "MEXICO": "MX",
  "MICRONESIA": "FM",
  "MOLDOVA": "MD",
  "MONACO": "MC",
  "MONGOLIA": "MN",
  "MONTENEGRO": "ME",
  "MONTSERRAT": "MS",
  "MOROCCO": "MA",
  "MOZAMBIQUE": "MZ",
  "MYANMAR": "MM",
  "NAMIBIA": "NA",
  "NAURU": "NR",
  "NEPAL": "NP",
  "NETHERLANDS": "NL",
  "NEW_CALEDONIA": "NC",
  "NEW_ZEALAND": "NZ",
  "NICARAGUA": "NI",
  "NIGER": "NE",
  "NIGERIA": "NG",
  "NIUE": "NU",
  "NORFOLK_ISLAND": "NF",
  "NORTH_KOREA": "KP",
  "NORTHERN_MARIANA_ISLANDS": "MP",
  "NORWAY": "NO",
  "OMAN": "OM",
  "PAKISTAN": "PK",
  "PALAU": "PW",
  "PALESTINIAN_TERRITORY_OCCUPIED": "PS",
  "PANAMA": "PA",
  "PAPUA_NEW_GUINEA": "PG",
  "PARAGUAY": "PY",
  "PERU": "PE",
  "PHILIPPINES": "PH",
  "PITCAIRN_ISLAND": "PN",
  "POLAND": "PL",
  "PORTUGAL": "PT",
  "PUERTO_RICO": "PR",
  "QATAR": "QA",
  "REUNION": "RE",
  "ROMANIA": "RO",
  "RUSSIA": "RU",
  "RWANDA": "RW",
  "SAINT_HELENA": "SH",
  "SAINT_KITTS_AND_NEVIS": "KN",
  "SAINT_LUCIA": "LC",
  "SAINT_PIERRE_AND_MIQUELON": "PM",
  "SAINT_VINCENT_AND_THE_GRENADINES": "VC",
  "SAINT-BARTHELEMY": "BL",
  "SAINT-MARTIN_(FRENCH_PART)": "MF",
  "SAMOA": "WS",
  "SAN_MARINO": "SM",
  "SAO_TOME_AND_PRINCIPE": "ST",
  "SAUDI_ARABIA": "SA",
  "SENEGAL": "SN",
  "SERBIA": "RS",
  "SEYCHELLES": "SC",
  "SIERRA_LEONE": "SL",
  "SINGAPORE": "SG",
  "SINT_MAARTEN_(DUTCH_PART)": "SX",
  "SLOVAKIA": "SK",
  "SLOVENIA": "SI",
  "SOLOMON_ISLANDS": "SB",
  "SOMALIA": "SO",
  "SOUTH_AFRICA": "ZA",
  "SOUTH_GEORGIA": "GS",
  "SOUTH_KOREA": "KR",
  "SOUTH_SUDAN": "SS",
  "SPAIN": "ES",
  "SRI_LANKA": "LK",
  "SUDAN": "SD",
  "SURINAME": "SR",
  "SVALBARD_AND_JAN_MAYEN_ISLANDS": "SJ",
  "SWAZILAND": "SZ",
  "SWEDEN": "SE",
  "SWITZERLAND": "CH",
  "SYRIA": "SY",
  "TAIWAN": "TW",
  "TAJIKISTAN": "TJ",
  "TANZANIA": "TZ",
  "THAILAND": "TH",
  "THE_BAHAMAS": "BS",
  "TOGO": "TG",
  "TOKELAU": "TK",
  "TONGA": "TO",
  "TRINIDAD_AND_TOBAGO": "TT",
  "TUNISIA": "TN",
  "TURKEY": "TR",
  "TURKMENISTAN": "TM",
  "TURKS_AND_CAICOS_ISLANDS": "TC",
  "TUVALU": "TV",
  "UGANDA": "UG",
  "UKRAINE": "UA",
  "UNITED_ARAB_EMIRATES": "AE",
  "UNITED_KINGDOM": "GB",
  "UNITED_STATES": "US",
  "UNITED_STATES_MINOR_OUTLYING_ISLANDS": "UM",
  "URUGUAY": "UY",
  "UZBEKISTAN": "UZ",
  "VANUATU": "VU",
  "VATICAN_CITY_STATE_(HOLY_SEE)": "VA",
  "VENEZUELA": "VE",
  "VIETNAM": "VN",
  "VIRGIN_ISLANDS_(BRITISH)": "VG",
  "VIRGIN_ISLANDS_(US)": "VI",
  "WALLIS_AND_FUTUNA_ISLANDS": "WF",
  "WESTERN_SAHARA": "EH",
  "YEMEN": "YE",
  "ZAMBIA": "ZM",
  "ZIMBABWE": "ZW"
};

const STATE = {
  INDIA: {
    AN: "Andaman and Nicobar Islands",
    AP: "Andhra Pradesh",
    AR: "Arunachal Pradesh",
    AS: "Assam",
    BR: "Bihar",
    CH: "Chandigarh",
    CT: "Chhattisgarh",
    DH: "Dadra and Nagar Haveli and Daman and Diu",
    DL: "Delhi",
    GA: "Goa",
    GJ: "Gujarat",
    HR: "Haryana",
    HP: "Himachal Pradesh",
    JK: "Jammu and Kashmir",
    JH: "Jharkhand",
    KA: "Karnataka",
    KL: "Kerala",
    LA: "Ladakh",
    LD: "Lakshadweep",
    MP: "Madhya Pradesh",
    MH: "Maharashtra",
    MN: "Manipur",
    ML: "Meghalaya",
    MZ: "Mizoram",
    NL: "Nagaland",
    OR: "Odisha",
    PY: "Puducherry",
    PB: "Punjab",
    RJ: "Rajasthan",
    SK: "Sikkim",
    TN: "Tamil Nadu",
    TG: "Telangana",
    TR: "Tripura",
    UP: "Uttar Pradesh",
    UT: "Uttarakhand",
    WB: "West Bengal",
  }
}

const CITIES = {
  AN: {
    BAMBOO_FLAT: "Bamboo Flat",
    NICOBAR: "Nicobar",
    PORT_BLAIR: "Port Blair",
    SOUTH_ANDAMAN: "South Andaman",
  },
  AP: {
    ADDANKI: "Addanki",
    ADONI: "Adoni",
    AKASAHEBPET: "Akasahebpet",
    AKIVIDU: "Akividu",
    AKKARAMPALLE: "Akkarampalle",
    AMALAPURAM: "Amalapuram",
    AMUDALAVALASA: "Amudalavalasa",
    ANAKAPALLE: "Anakapalle",
    ANANTAPUR: "Anantapur",
    ATMAKUR: "Atmakur",
    ATTILI: "Attili",
    AVANIGADDA: "Avanigadda",
    BADVEL: "Badvel",
    BANGANAPALLE: "Banganapalle",
    BAPATLA: "Bapatla",
    BETAMCHERLA: "Betamcherla",
    BHATTIPROLU: "Bhattiprolu",
    BHIMAVARAM: "Bhimavaram",
    BHIMUNIPATNAM: "Bhimunipatnam",
    BOBBILI: "Bobbili",
    CHALLAPALLE: "Challapalle",
    CHEMMUMIAHPET: "Chemmumiahpet",
    CHILAKALURUPET: "Chilakalurupet",
    CHINNACHOWK: "Chinnachowk",
    CHIPURUPALLE: "Chipurupalle",
    CHIRALA: "Chirala",
    CHITTOOR: "Chittoor",
    CHODAVARAM: "Chodavaram",
    CUDDAPAH: "Cuddapah",
    CUMBUM: "Cumbum",
    DARSI: "Darsi",
    DHARMAVARAM: "Dharmavaram",
    DHONE: "Dhone",
    DIGUVAMETTA: "Diguvametta",
    EAST_GODAVARI: "East Godavari",
    ELAMANCHILI: "Elamanchili",
    ELLORE: "Ellore",
    EMMIGANUR: "Emmiganur",
    ERRAGUNTLA: "Erraguntla",
    ETIKOPPAKA: "Etikoppaka",
    GAJUWAKA: "Gajuwaka",
    GANGUVADA: "Ganguvada",
    GANNAVARAM: "Gannavaram",
    GIDDALUR: "Giddalur",
    GOKAVARAM: "Gokavaram",
    GORANTLA: "Gorantla",
    GOVINDAPURAM_CHILAKALURIPET_GUNTUR: "Govindapuram,Chilakaluripet,Guntur",
    GUDIVADA: "Gudivada",
    GUDLAVALLERU: "Gudlavalleru",
    GUDUR: "Gudur",
    GUNTAKAL_JUNCTION: "Guntakal Junction",
    GUNTUR: "Guntur",
    HINDUPUR: "Hindupur",
    ICHCHAPURAM: "Ichchapuram",
    JAGGAYYAPETA: "Jaggayyapeta",
    JAMMALAMADUGU: "Jammalamadugu",
    KADIRI: "Kadiri",
    KAIKALUR: "Kaikalur",
    KAKINADA: "Kakinada",
    KALYANDURG: "Kalyandurg",
    KAMALAPURAM: "Kamalapuram",
    KANDUKUR: "Kandukur",
    KANIGIRI: "Kanigiri",
    KANKIPADU: "Kankipadu",
    KANURU: "Kanuru",
    KAVALI: "Kavali",
    KOLANUKONDA: "Kolanukonda",
    KONDAPALLE: "Kondapalle",
    KORUKOLLU: "Korukollu",
    KOSIGI: "Kosigi",
    KOVVUR: "Kovvur",
    KRISHNA: "Krishna",
    KUPPAM: "Kuppam",
    KURNOOL: "Kurnool",
    MACHERLA: "Macherla",
    MACHILIPATNAM: "Machilipatnam",
    MADANAPALLE: "Madanapalle",
    MADUGULA: "Madugula",
    MANDAPETA: "Mandapeta",
    MANDASA: "Mandasa",
    MANGALAGIRI: "Mangalagiri",
    MARKAPUR: "Markapur",
    NAGARI: "Nagari",
    NAGIREDDIPALLI: "Nagireddipalli",
    NANDIGAMA: "Nandigama",
    NANDIKOTKUR: "Nandikotkur",
    NANDYAL: "Nandyal",
    NARASANNAPETA: "Narasannapeta",
    NARASAPUR: "Narasapur",
    NARASARAOPET: "Narasaraopet",
    NARASINGAPURAM: "Narasingapuram",
    NARAYANAVANAM: "Narayanavanam",
    NARSIPATNAM: "Narsipatnam",
    NAYUDUPET: "Nayudupet",
    NELLORE: "Nellore",
    NIDADAVOLE: "Nidadavole",
    NUZVID: "Nuzvid",
    ONGOLE: "Ongole",
    PAKALA: "Pakala",
    PALAKOLLU: "Palakollu",
    PALASA: "Palasa",
    PALKONDA: "Palkonda",
    PALLEVADA: "Pallevada",
    PALMANER: "Palmaner",
    PARLAKIMIDI: "Parlakimidi",
    PARVATIPURAM: "Parvatipuram",
    PAVULURU: "Pavuluru",
    PEDANA: "Pedana",
    PEDDA_NAKKALAPALEM: "pedda nakkalapalem",
    PEDDAPURAM: "Peddapuram",
    PENUGONDA: "Penugonda",
    PENUKONDA: "Penukonda",
    PHIRANGIPURAM: "Phirangipuram",
    PIPPARA: "Pippara",
    PITHAPURAM: "Pithapuram",
    POLAVARAM: "Polavaram",
    PONNUR: "Ponnur",
    PONNURU: "Ponnuru",
    PRAKASAM: "Prakasam",
    PRODDATUR: "Proddatur",
    PULIVENDLA: "Pulivendla",
    PUNGANURU: "Punganuru",
    PUTTAPARTHI: "Puttaparthi",
    PUTTUR: "Puttur",
    RAJAHMUNDRY: "Rajahmundry",
    RAMACHANDRAPURAM: "Ramachandrapuram",
    RAMANAYYAPETA: "Ramanayyapeta",
    RAMAPURAM: "Ramapuram",
    RAMPACHODAVARAM: "Rampachodavaram",
    RAYACHOTI: "Rayachoti",
    RAYADRUG: "Rayadrug",
    RAZAM: "Razam",
    RAZAMPETA: "Razampeta",
    RAZOLE: "Razole",
    RENIGUNTA: "Renigunta",
    REPALLE: "Repalle",
    SALUR: "Salur",
    SAMALKOT: "Samalkot",
    SATTENAPALLE: "Sattenapalle",
    SINGARAYAKONDA: "Singarayakonda",
    SOMPETA: "Sompeta",
    SRIKAKULAM: "Srikakulam",
    SRISAILAIN: "Srisailain",
    SULURU: "Suluru",
    TADEPALLE: "Tadepalle",
    TADEPALLEGUDEM: "Tadepallegudem",
    TADPATRI: "Tadpatri",
    TANUKU: "Tanuku",
    TEKKALI: "Tekkali",
    TIRUMALA: "Tirumala",
    TIRUPATI: "Tirupati",
    TUNI: "Tuni",
    URAVAKONDA: "Uravakonda",
    VADLAMURU: "vadlamuru",
    VADLAPUDI: "Vadlapudi",
    VENKATAGIRI: "Venkatagiri",
    VEPAGUNTA: "Vepagunta",
    VETAPALEM: "Vetapalem",
    VIJAYAWADA: "Vijayawada",
    VINUKONDA: "Vinukonda",
    VISAKHAPATNAM: "Visakhapatnam",
    VIZIANAGARAM: "Vizianagaram",
    VIZIANAGARAM_DISTRICT: "Vizianagaram District",
    VUYYURU: "Vuyyuru",
    WEST_GODAVARI: "West Godavari",
    YANAM: "Yanam",
    YANAMALAKUDURU: "Yanamalakuduru",
    YARADA: "Yarada",
  },
  AR: {
    ALONG: "Along",
    ANJAW: "Anjaw",
    BASAR: "Basar",
    BOMDILA: "Bomdila",
    CHANGLANG: "Changlang",
    DIBANG_VALLEY: "Dibang Valley",
    EAST_KAMENG: "East Kameng",
    EAST_SIANG: "East Siang",
    HAYULIANG: "Hayuliang",
    ITANAGAR: "Itanagar",
    KHONSA: "Khonsa",
    KURUNG_KUMEY: "Kurung Kumey",
    LOHIT_DISTRICT: "Lohit District",
    LOWER_DIBANG_VALLEY: "Lower Dibang Valley",
    LOWER_SUBANSIRI: "Lower Subansiri",
    MARGHERITA: "Margherita",
    NAHARLAGUN: "Naharlagun",
    PASIGHAT: "Pasighat",
    TAWANG: "Tawang",
    TEZU: "Tezu",
    TIRAP: "Tirap",
    UPPER_SIANG: "Upper Siang",
    UPPER_SUBANSIRI: "Upper Subansiri",
    WEST_KAMENG: "West Kameng",
    WEST_SIANG: "West Siang",
    ZIRO: "Ziro",
  },
  AS: {
    ABHAYAPURI: "Abhayapuri",
    AMGURI: "Amguri",
    BADARPUR: "Badarpur",
    BAKSA: "Baksa",
    BARPATHAR: "Barpathar",
    BARPETA: "Barpeta",
    BARPETA_ROAD: "Barpeta Road",
    BASUGAON: "Basugaon",
    BIHPURIAGAON: "Bihpuriagaon",
    BIJNI: "Bijni",
    BILASIPARA: "Bilasipara",
    BOKAJAN: "Bokajan",
    BOKAKHAT: "Bokakhat",
    BONGAIGAON: "Bongaigaon",
    CACHAR: "Cachar",
    CHABUA: "Chabua",
    CHAPAR: "Chapar",
    CHIRANG: "Chirang",
    DARRANG: "Darrang",
    DERGAON: "Dergaon",
    DHEKIAJULI: "Dhekiajuli",
    DHEMAJI: "Dhemaji",
    DHING: "Dhing",
    DHUBRI: "Dhubri",
    DIBRUGARH: "Dibrugarh",
    DIGBOI: "Digboi",
    DIMA_HASAO_DISTRICT: "Dima Hasao District",
    DIPHU: "Diphu",
    DISPUR: "Dispur",
    DULIAGAON: "Duliagaon",
    DUM_DUMA: "Dum Duma",
    GAURIPUR: "Gauripur",
    GOALPARA: "Goalpara",
    GOHPUR: "Gohpur",
    GOLAGHAT: "Golaghat",
    GOLAKGANJ: "Golakganj",
    GOSHAINGAON: "Goshaingaon",
    GUWAHATI: "Guwahati",
    HAFLONG: "Haflong",
    HAILAKANDI: "Hailakandi",
    HAJO: "Hajo",
    HOJAI: "Hojai",
    HOWLI: "Howli",
    JOGIGHOPA: "Jogighopa",
    JORHAT: "Jorhat",
    KAMRUP: "Kamrup",
    KAMRUP_METROPOLITAN: "Kamrup Metropolitan",
    KARBI_ANGLONG: "Karbi Anglong",
    KARIMGANJ: "Karimganj",
    KHARUPATIA: "Kharupatia",
    KOKRAJHAR: "Kokrajhar",
    LAKHIMPUR: "Lakhimpur",
    LAKHIPUR: "Lakhipur",
    LALA: "Lala",
    LUMDING_RAILWAY_COLONY: "Lumding Railway Colony",
    MAHUR: "Mahur",
    MAIBONG: "Maibong",
    MAKUM: "Makum",
    MANGALDAI: "Mangaldai",
    MARIANI: "Mariani",
    MORANHA: "Moranha",
    MORIGAON: "Morigaon",
    NAGAON: "Nagaon",
    NAHORKATIYA: "Nahorkatiya",
    NALBARI: "Nalbari",
    NAMRUP: "Namrup",
    NAZIRA: "Nazira",
    NORTH_GUWAHATI: "North Guwahati",
    NORTH_LAKHIMPUR: "North Lakhimpur",
    NUMALIGARH: "Numaligarh",
    PALASBARI: "Palasbari",
    RAHA: "Raha",
    RANGAPARA: "Rangapara",
    RANGIA: "Rangia",
    SAPATGRAM: "Sapatgram",
    SARUPATHAR: "Sarupathar",
    SIBSAGAR: "Sibsagar",
    SILAPATHAR: "Silapathar",
    SILCHAR: "Silchar",
    SOALKUCHI: "Soalkuchi",
    SONARI: "Sonari",
    SONITPUR: "Sonitpur",
    SORBHOG: "Sorbhog",
    TEZPUR: "Tezpur",
    TINSUKIA: "Tinsukia",
    TITABAR: "Titabar",
    UDALGURI: "Udalguri",
  },
  BR: {
    AMARPUR: "Amarpur",
    ARARIA: "Araria",
    ARRAH: "Arrah",
    ARWAL: "Arwal",
    ASARGANJ: "Asarganj",
    AURANGABAD: "Aurangabad",
    BAGAHA: "Bagaha",
    BAHADURGANJ: "Bahadurganj",
    BAIRAGNIA: "Bairagnia",
    BAISI: "Baisi",
    BAKHTIYARPUR: "Bakhtiyarpur",
    BANGAON: "Bangaon",
    BANKA: "Banka",
    BANMANKHI: "Banmankhi",
    BAR_BIGHA: "Bar Bigha",
    BARAULI: "Barauli",
    BARH: "Barh",
    BARHIYA: "Barhiya",
    BARIARPUR: "Bariarpur",
    BARUNI: "Baruni",
    BEGUSARAI: "Begusarai",
    BELSAND: "Belsand",
    BETTIAH: "Bettiah",
    BHABHUA: "Bhabhua",
    BHAGALPUR: "Bhagalpur",
    BHAGIRATHPUR: "Bhagirathpur",
    BHAWANIPUR: "Bhawanipur",
    BHOJPUR: "Bhojpur",
    BIHAR_SHARIF: "Bihar Sharif",
    BIHARIGANJ: "Bihariganj",
    BIKRAMGANJ: "Bikramganj",
    BIRPUR: "Birpur",
    BUDDH_GAYA: "Buddh Gaya",
    BUXAR: "Buxar",
    CHAKIA: "Chakia",
    CHAPRA: "Chapra",
    CHHATAPUR: "Chhatapur",
    COLGONG: "Colgong",
    DALSINGH_SARAI: "Dalsingh Sarai",
    DARBHANGA: "Darbhanga",
    DAUDNAGAR: "Daudnagar",
    DEHRI: "Dehri",
    DHAKA: "Dhaka",
    DIGHWARA: "Dighwara",
    DINAPORE: "Dinapore",
    DUMRA: "Dumra",
    DUMRAON: "Dumraon",
    FATWA: "Fatwa",
    FORBESGANJ: "Forbesganj",
    GAYA: "Gaya",
    GHOGA: "Ghoga",
    GOPALGANJ: "Gopalganj",
    HAJIPUR: "Hajipur",
    HILSA: "Hilsa",
    HISUA: "Hisua",
    ISLAMPUR: "Islampur",
    JAGDISPUR: "Jagdispur",
    JAHANABAD: "Jahanabad",
    JAMALPUR: "Jamalpur",
    JAMUI: "Jamui",
    JAYNAGAR: "Jaynagar",
    JEHANABAD: "Jehanabad",
    JHA_JHA: "Jha-Jha",
    JHANJHARPUR: "Jhanjharpur",
    JOGBANI: "Jogbani",
    KAIMUR_DISTRICT: "Kaimur District",
    KASBA: "Kasba",
    KATIHAR: "Katihar",
    KHAGARIA: "Khagaria",
    KHAGAUL: "Khagaul",
    KHARAGPUR: "Kharagpur",
    KHUSROPUR: "Khusropur",
    KISHANGANJ: "Kishanganj",
    KOATH: "Koath",
    KOELWAR: "Koelwar",
    LAKHISARAI: "Lakhisarai",
    LALGANJ: "Lalganj",
    LUCKEESARAI: "Luckeesarai",
    MADHEPURA: "Madhepura",
    MADHIPURA: "Madhipura",
    MADHUBANI: "Madhubani",
    MAHARAJGANI: "Maharajgani",
    MAIRWA: "Mairwa",
    MANER: "Maner",
    MANIHARI: "Manihari",
    MARHAURA: "Marhaura",
    MASAURHI_BUZURG: "Masaurhi Buzurg",
    MOHIUDDINNAGAR: "Mohiuddinnagar",
    MOKAMEH: "Mokameh",
    MONGHYR: "Monghyr",
    MOTHIHARI: "Mothihari",
    MUNGER: "Munger",
    MURLIGANJ: "Murliganj",
    MUZAFFARPUR: "Muzaffarpur",
    NABINAGAR: "Nabinagar",
    NALANDA: "Nalanda",
    NASRIGANJ: "Nasriganj",
    NAUGACHHIA: "Naugachhia",
    NAWADA: "Nawada",
    NIRMALI: "Nirmali",
    PASHCHIM_CHAMPARAN: "Pashchim Champaran",
    PATNA: "Patna",
    PIRO: "Piro",
    PUPRI: "Pupri",
    PURBA_CHAMPARAN: "Purba Champaran",
    PURNIA: "Purnia",
    RAFIGANJ: "Rafiganj",
    RAGHUNATHPUR: "Raghunathpur",
    RAJGIR: "Rajgir",
    RAMNAGAR: "Ramnagar",
    RAXAUL: "Raxaul",
    REVELGANJ: "Revelganj",
    ROHTAS: "Rohtas",
    RUSERA: "Rusera",
    SAGAULI: "Sagauli",
    SAHARSA: "Saharsa",
    SAMASTIPUR: "Samastipur",
    SARAN: "Saran",
    SHAHBAZPUR: "Shahbazpur",
    SHAHPUR: "Shahpur",
    SHEIKHPURA: "Sheikhpura",
    SHEOHAR: "Sheohar",
    SHERGHATI: "Sherghati",
    SILAO: "Silao",
    SITAMARHI: "Sitamarhi",
    SIWAN: "Siwan",
    SUPAUL: "Supaul",
    TEGHRA: "Teghra",
    TEKARI: "Tekari",
    THAKURGANJ: "Thakurganj",
    VAISHALI: "Vaishali",
    WARIS_ALIGANJ: "Waris Aliganj",
  },
  CH: {
    CHANDIGARH: "Chandigarh",
  },
  CT: {
    AKALTARA: "Akaltara",
    AMBAGARH_CHAUKI: "Ambagarh Chauki",
    AMBIKAPUR: "Ambikapur",
    ARANG: "Arang",
    BAIKUNTHPUR: "Baikunthpur",
    BALOD: "Balod",
    BALODA: "Baloda",
    BALODA_BAZAR: "Baloda Bazar",
    BASNA: "Basna",
    BASTAR: "Bastar",
    BEMETARA: "Bemetara",
    BHANPURI: "Bhanpuri",
    BHATAPARA: "Bhatapara",
    BHATGAON: "Bhatgaon",
    BHILAI: "Bhilai",
    BIJAPUR: "Bijapur",
    BILASPUR: "Bilaspur",
    CHAMPA: "Champa",
    CHHUIKHADAN: "Chhuikhadan",
    DEORI: "Deori",
    DHAMTARI: "Dhamtari",
    DONGARGAON: "Dongargaon",
    DONGARGARH: "Dongargarh",
    DURG: "Durg",
    GANDAI: "Gandai",
    GARIABAND: "Gariaband",
    GAURELA: "Gaurela",
    GHARGHODA: "Gharghoda",
    GIDAM: "Gidam",
    JAGDALPUR: "Jagdalpur",
    JANJGIR: "Janjgir",
    JANJGIR_CHAMPA: "Janjgir-Champa",
    JASHPUR: "Jashpur",
    JASHPURNAGAR: "Jashpurnagar",
    JUNAGARH: "Junagarh",
    KABEERDHAM: "Kabeerdham",
    KANKER: "Kanker",
    KATGHORA: "Katghora",
    KAWARDHA: "Kawardha",
    KHAIRAGARH: "Khairagarh",
    KHAMHARIA: "Khamharia",
    KHAROD: "Kharod",
    KHARSIA: "Kharsia",
    KIRANDUL: "Kirandul",
    KONDAGAON: "Kondagaon",
    KORBA: "Korba",
    KORIYA: "Koriya",
    KOTA: "Kota",
    KOTAPARH: "Kotaparh",
    KUMHARI: "Kumhari",
    KURUD: "Kurud",
    LORMI: "Lormi",
    MAHASAMUND: "Mahasamund",
    MUNGELI: "Mungeli",
    NARAYANPUR: "Narayanpur",
    NARHARPUR: "Narharpur",
    PANDARIA: "Pandaria",
    PANDATARAI: "Pandatarai",
    PASAN: "Pasan",
    PATAN: "Patan",
    PATHALGAON: "Pathalgaon",
    PENDRA: "Pendra",
    PITHORA: "Pithora",
    RAIGARH: "Raigarh",
    RAIPUR: "Raipur",
    RAJ_NANDGAON: "Raj Nandgaon",
    RAMANUJ_GANJ: "Ramanuj Ganj",
    RATANPUR: "Ratanpur",
    SAKTI: "Sakti",
    SARAIPALI: "Saraipali",
    SARANGARH: "Sarangarh",
    SEORINARAYAN: "Seorinarayan",
    SIMGA: "Simga",
    SURGUJA: "Surguja",
    TAKHATPUR: "Takhatpur",
    UMARKOT: "Umarkot",
    UTTAR_BASTAR_KANKER: "Uttar Bastar Kanker",
  },
  DH: {
    AMLI: "Amli",
    DADRA: "Dadra",
    DADRA_NAGAR_HAVELI: "Dadra & Nagar Haveli",
    DAMAN: "Daman",
    DAMAN_DISTRICT: "Daman District",
    DIU: "Diu",
    SILVASSA: "Silvassa",
  },
  DL: {
    ALIPUR: "Alipur",
    BAWANA: "Bawana",
    CENTRAL_DELHI: "Central Delhi",
    DELHI: "Delhi",
    DEOLI: "Deoli",
    EAST_DELHI: "East Delhi",
    KAROL_BAGH: "Karol Bagh",
    NAJAFGARH: "Najafgarh",
    NANGLOI_JAT: "Nangloi Jat",
    NARELA: "Narela",
    NEW_DELHI: "New Delhi",
    NORTH_DELHI: "North Delhi",
    NORTH_EAST_DELHI: "North East Delhi",
    NORTH_WEST_DELHI: "North West Delhi",
    PITAMPURA: "Pitampura",
    ROHINI: "Rohini",
    SOUTH_DELHI: "South Delhi",
    SOUTH_WEST_DELHI: "South West Delhi",
    WEST_DELHI: "West Delhi",
  },
  GA: {
    ALDONA: "Aldona",
    ARAMBOL: "Arambol",
    BAGA: "Baga",
    BAMBOLIM: "Bambolim",
    BANDORA: "Bandora",
    BENAULIM: "Benaulim",
    CALANGUTE: "Calangute",
    CANDOLIM: "Candolim",
    CARAPUR: "Carapur",
    CAVELOSSIM: "Cavelossim",
    CHICALIM: "Chicalim",
    CHINCHINIM: "Chinchinim",
    COLOVALE: "Colovale",
    COLVA: "Colva",
    CORTALIM: "Cortalim",
    CUNCOLIM: "Cuncolim",
    CURCHOREM: "Curchorem",
    CURTI: "Curti",
    DAVORLIM: "Davorlim",
    DICHOLI: "Dicholi",
    GOA_VELHA: "Goa Velha",
    GUIRIM: "Guirim",
    JUA: "Jua",
    KANKON: "Kankon",
    MADGAON: "Madgaon",
    MAPUCA: "Mapuca",
    MORJIM: "Morjim",
    MORMUGAO: "Mormugao",
    NAVELIM: "Navelim",
    NORTH_GOA: "North Goa",
    PALLE: "Palle",
    PANAJI: "Panaji",
    PERNEM: "Pernem",
    PONDA: "Ponda",
    QUEPEM: "Quepem",
    QUEULA: "Queula",
    RAIA: "Raia",
    SALIGAO: "Saligao",
    SANCOALE: "Sancoale",
    SANGUEM: "Sanguem",
    SANQUELIM: "Sanquelim",
    SANVORDEM: "Sanvordem",
    SERULA: "Serula",
    SOLIM: "Solim",
    SOUTH_GOA: "South Goa",
    TALEIGAO: "Taleigao",
    VAGATOR: "Vagator",
    VALPOY: "Valpoy",
    VARCA: "Varca",
    VASCO_DA_GAMA: "Vasco da Gama",
  },
  GJ: {
    ABRAMA: "Abrama",
    ADALAJ: "Adalaj",
    AGOL: "Agol",
    AHMEDABAD: "Ahmedabad",
    AHWA: "Ahwa",
    AKRUND: "Akrund",
    AMOD: "Amod",
    AMRELI: "Amreli",
    AMROLI: "Amroli",
    ANAND: "Anand",
    ANJAR: "Anjar",
    ANKLESHWAR: "Ankleshwar",
    BABRA: "Babra",
    BAGASARA: "Bagasara",
    BAGASRA: "Bagasra",
    BAKHARLA: "Bakharla",
    BALAGAM: "Balagam",
    BALASINOR: "Balasinor",
    BALISANA: "Balisana",
    BAMANBORE: "Bamanbore",
    BANAS_KANTHA: "Banas Kantha",
    BANDIA: "Bandia",
    BANTVA: "Bantva",
    BARDOLI: "Bardoli",
    BEDI: "Bedi",
    BHACHAU: "Bhachau",
    BHADRAN: "Bhadran",
    BHANDU: "Bhandu",
    BHANVAD: "Bhanvad",
    BHARUCH: "Bharuch",
    BHATHA: "Bhatha",
    BHAVNAGAR: "Bhavnagar",
    BHAYAVADAR: "Bhayavadar",
    BHILDI: "Bhildi",
    BHOJPUR_DHARAMPUR: "Bhojpur Dharampur",
    BHUJ: "Bhuj",
    BILIMORA: "Bilimora",
    BILKHA: "Bilkha",
    BORSAD: "Borsad",
    BOTAD: "Botad",
    CHAKLASI: "Chaklasi",
    CHALALA: "Chalala",
    CHALODA: "Chaloda",
    CHAMPANER: "Champaner",
    CHANASMA: "Chanasma",
    CHHALA: "Chhala",
    CHHOTA_UDEPUR: "Chhota Udepur",
    CHIKHLI: "Chikhli",
    CHOTILA: "Chotila",
    CHUDA: "Chuda",
    DABHODA: "Dabhoda",
    DABHOI: "Dabhoi",
    DAHEGAM: "Dahegam",
    DAHOD: "Dahod",
    DAKOR: "Dakor",
    DAMNAGAR: "Damnagar",
    DANDI: "Dandi",
    DANGS_INDIA: "Dangs (India)",
    DANTA: "Danta",
    DAYAPAR: "Dayapar",
    DELVADA: "Delvada",
    DELWADA: "Delwada",
    DETROJ: "Detroj",
    DEVBHUMI_DWARKA: "Devbhumi Dwarka",
    DEVGADH_BARIYA: "Devgadh Bariya",
    DHANDHUKA: "Dhandhuka",
    DHANERA: "Dhanera",
    DHANSURA: "Dhansura",
    DHARAMPUR: "Dharampur",
    DHARASANA: "Dharasana",
    DHARI: "Dhari",
    DHASA: "Dhasa",
    DHOLA: "Dhola",
    DHOLERA: "Dholera",
    DHOLKA: "Dholka",
    DHORAJI: "Dhoraji",
    DHRANGADHRA: "Dhrangadhra",
    DHROL: "Dhrol",
    DHUWARAN: "Dhuwaran",
    DISA: "Disa",
    DOHAD: "Dohad",
    DUMKHAL: "Dumkhal",
    DUNGARPUR: "Dungarpur",
    DWARKA: "Dwarka",
    GADHADA: "Gadhada",
    GANDEVI: "Gandevi",
    GANDHIDHAM: "Gandhidham",
    GANDHINAGAR: "Gandhinagar",
    GARIADHAR: "Gariadhar",
    GHODASAR: "Ghodasar",
    GHOGHA: "Ghogha",
    GIR_SOMNATH: "Gir Somnath",
    GODHRA: "Godhra",
    GONDAL: "Gondal",
    GORWA: "Gorwa",
    HALENDA: "Halenda",
    HALOL: "Halol",
    HALVAD: "Halvad",
    HANSOT: "Hansot",
    HARIJ: "Harij",
    HARSOL: "Harsol",
    HATHURAN: "Hathuran",
    HIMATNAGAR: "Himatnagar",
    IDAR: "Idar",
    JAKHAU: "Jakhau",
    JALALPORE: "Jalalpore",
    JALALPUR: "Jalalpur",
    JALIA: "Jalia",
    JAMBUDA: "Jambuda",
    JAMBUSAR: "Jambusar",
    JAMNAGAR: "Jamnagar",
    JAROD: "Jarod",
    JASDAN: "Jasdan",
    JETALPUR: "Jetalpur",
    JETALSAR: "Jetalsar",
    JETPUR: "Jetpur",
    JETPUR_NAVAGADH: "Jetpur (Navagadh)",
    JHALOD: "Jhalod",
    JHULASAN: "Jhulasan",
    JODHPUR: "Jodhpur",
    JODHPUR_AHMEDABAD: "Jodhpur (Ahmedabad)",
    JODIA: "Jodia",
    JODIYA_BANDAR: "Jodiya Bandar",
    JUNAGADH: "Junagadh",
    KACHCHH: "Kachchh",
    KACHHOLI: "Kachholi",
    KADI: "Kadi",
    KADOD: "Kadod",
    KALAVAD: "Kalavad",
    KALOL: "Kalol",
    KANDLA: "Kandla",
    KANDLA_PORT: "Kandla port",
    KANODAR: "Kanodar",
    KAPADVANJ: "Kapadvanj",
    KARAMSAD: "Karamsad",
    KARIANA: "Kariana",
    KARJAN: "Karjan",
    KATHOR: "Kathor",
    KATPUR: "Katpur",
    KAVANT: "Kavant",
    KAWANT: "Kawant",
    KAYAVAROHAN: "Kayavarohan",
    KERWADA: "Kerwada",
    KESHOD: "Keshod",
    KHAMBHALIA: "Khambhalia",
    KHAMBHAT: "Khambhat",
    KHAVDA: "Khavda",
    KHEDA: "Kheda",
    KHEDBRAHMA: "Khedbrahma",
    KHEDOI: "Khedoi",
    KHERALI: "Kherali",
    KHERALU: "Kheralu",
    KODINAR: "Kodinar",
    KOSAMBA: "Kosamba",
    KOTHARA: "Kothara",
    KOTHARIA: "Kotharia",
    KUKARMUNDA: "Kukarmunda",
    KUKMA: "Kukma",
    KUNDLA: "Kundla",
    KUTCH_DISTRICT: "Kutch district",
    KUTIYANA: "Kutiyana",
    LADOL: "Ladol",
    LAKHPAT: "Lakhpat",
    LAKHTAR: "Lakhtar",
    LALPUR: "Lalpur",
    LANGHNAJ: "Langhnaj",
    LATHI: "Lathi",
    LIMBDI: "Limbdi",
    LIMKHEDA: "Limkheda",
    LUNAVADA: "Lunavada",
    MADHAVPUR_GHED: "Madhavpur Ghed",
    MADHI: "Madhi",
    MAHEMDAVAD: "Mahemdavad",
    MAHESANA: "Mahesana",
    MAHISA: "Mahisa",
    MAHUDHA: "Mahudha",
    MAHUVA: "Mahuva",
    MAHUVA_SURAT: "Mahuva (Surat)",
    MALPUR: "Malpur",
    MANAVADAR: "Manavadar",
    MANDAL: "Mandal",
    MANDVI: "Mandvi",
    MANDVI_SURAT: "Mandvi (Surat)",
    MANGROL: "Mangrol",
    MANGROL_JUNAGADH: "Mangrol (Junagadh)",
    MANSA: "Mansa",
    MEGHRAJ: "Meghraj",
    MEHSANA: "Mehsana",
    MENDARDA: "Mendarda",
    MITHAPUR: "Mithapur",
    MODASA: "Modasa",
    MORBI: "Morbi",
    MORVA_HADAF: "Morva (Hadaf)",
    MORWA: "Morwa",
    MUNDRA: "Mundra",
    NADIAD: "Nadiad",
    NAGWA: "Nagwa",
    NALDHARA: "Naldhara",
    NALIYA: "Naliya",
    NARGOL: "Nargol",
    NARMADA: "Narmada",
    NARODA: "Naroda",
    NAVSARI: "Navsari",
    NIKORA: "Nikora",
    NIZAR: "Nizar",
    ODADAR: "Odadar",
    OKHA: "Okha",
    OLPAD: "Olpad",
    PADDHARI: "Paddhari",
    PADRA: "Padra",
    PALANPUR: "Palanpur",
    PALANSWA: "Palanswa",
    PALITANA: "Palitana",
    PALIYAD: "Paliyad",
    PALIYAD_BHAVNAGAR: "Paliyad (Bhavnagar)",
    PALSANA: "Palsana",
    PANCH_MAHALS: "Panch Mahals",
    PANCHMAHAL_DISTRICT: "Panchmahal district",
    PARDI: "Pardi",
    PARNERA: "Parnera",
    PATAN: "Patan",
    PAVI_JETPUR: "Pavi Jetpur",
    PETLAD: "Petlad",
    PIPAVAV: "Pipavav",
    PIPLOD: "Piplod",
    PORBANDAR: "Porbandar",
    PRABHAS_PATAN: "Prabhas Patan",
    PRANTIJ: "Prantij",
    RADHANPUR: "Radhanpur",
    RAJKOT: "Rajkot",
    RAJPIPLA: "Rajpipla",
    RAJULA: "Rajula",
    RANAVAV: "Ranavav",
    RANPUR: "Ranpur",
    RAPAR: "Rapar",
    REHA: "Reha",
    ROHA: "Roha",
    SABAR_KANTHA: "Sabar Kantha",
    SACHIN: "Sachin",
    SALAYA: "Salaya",
    SAMAKHIALI: "Samakhiali",
    SANAND: "Sanand",
    SANKHEDA: "Sankheda",
    SARBHON: "Sarbhon",
    SARDOI: "Sardoi",
    SARKHEJ: "Sarkhej",
    SATHAMBA: "Sathamba",
    SAVARKUNDLA: "Savarkundla",
    SAVLI: "Savli",
    SAYLA: "Sayla",
    SHAHPUR: "Shahpur",
    SHIVRAJPUR: "Shivrajpur",
    SIDDHPUR: "Siddhpur",
    SIHOR: "Sihor",
    SIKKA: "Sikka",
    SINOR: "Sinor",
    SOJITRA: "Sojitra",
    SONGADH: "Songadh",
    SUPEDI: "Supedi",
    SURAT: "Surat",
    SURENDRANAGAR: "Surendranagar",
    SUTRAPADA: "Sutrapada",
    TALAJA: "Talaja",
    TANKARA: "Tankara",
    TAPI: "Tapi",
    THAN: "Than",
    THANGADH: "Thangadh",
    THARAD: "Tharad",
    THASRA: "Thasra",
    THE_DANGS: "The Dangs",
    UMARPADA: "Umarpada",
    UMRALA: "Umrala",
    UMRETH: "Umreth",
    UN: "Un",
    UNA: "Una",
    UNJHA: "Unjha",
    UPLETA: "Upleta",
    UTRAN: "Utran",
    VADGAM: "Vadgam",
    VADNAGAR: "Vadnagar",
    VADODARA: "Vadodara",
    VAGHODIA: "Vaghodia",
    VAGHODIA_INA: "Vaghodia INA",
    VALLABH_VIDYANAGAR: "Vallabh Vidyanagar",
    VALLABHIPUR: "Vallabhipur",
    VALSAD: "Valsad",
    VANALA: "Vanala",
    VANSDA: "Vansda",
    VANTHLI: "Vanthli",
    VAPI: "Vapi",
    VARTEJ: "Vartej",
    VASA: "Vasa",
    VASAVAD: "Vasavad",
    VASO: "Vaso",
    VATAMAN: "Vataman",
    VEJALPUR: "Vejalpur",
    VERAVAL: "Veraval",
    VIJAPUR: "Vijapur",
    VINCHHIYA: "Vinchhiya",
    VINCHIA: "Vinchia",
    VIRAMGAM: "Viramgam",
    VIRPUR: "Virpur",
    VISAVADAR: "Visavadar",
    VISNAGAR: "Visnagar",
    VYARA: "Vyara",
    WADHAI: "Wadhai",
    WADHWAN: "Wadhwan",
    WAGHAI: "Waghai",
    WANKANER: "Wankaner",
  },
  HP: {
    ARKI: "Arki",
    BADDI: "Baddi",
    BANJAR: "Banjar",
    BILASPUR: "Bilaspur",
    CHAMBA: "Chamba",
    CHAUPAL: "Chaupal",
    CHOWARI: "Chowari",
    CHUARI_KHAS: "Chuari Khas",
    DAGSHAI: "Dagshai",
    DALHOUSIE: "Dalhousie",
    DAULATPUR: "Daulatpur",
    DERA_GOPIPUR: "Dera Gopipur",
    DHARAMSALA: "Dharamsala",
    GAGRET: "Gagret",
    GHUMARWIN: "Ghumarwin",
    HAMIRPUR: "Hamirpur",
    JAWALA_MUKHI: "Jawala Mukhi",
    JOGINDARNAGAR: "Jogindarnagar",
    JUBBAL: "Jubbal",
    JUTOGH: "Jutogh",
    KALKA: "Kalka",
    KANGAR: "Kangar",
    KANGRA: "Kangra",
    KASAULI: "Kasauli",
    KINNAUR: "Kinnaur",
    KOTKHAI: "Kotkhai",
    KOTLA: "Kotla",
    KULU: "Kulu",
    KYELANG: "Kyelang",
    LAHUL_AND_SPITI: "Lahul and Spiti",
    MANALI: "Manali",
    MANDI: "Mandi",
    NADAUN: "Nadaun",
    NAGAR: "Nagar",
    NAGROTA: "Nagrota",
    NAHAN: "Nahan",
    NALAGARH: "Nalagarh",
    PALAMPUR: "Palampur",
    PANDOH: "Pandoh",
    PAONTA_SAHIB: "Paonta Sahib",
    PARWANOO: "Parwanoo",
    RAJGARH: "Rajgarh",
    RAMPUR: "Rampur",
    ROHRU: "Rohru",
    SABATHU: "Sabathu",
    SANTOKHGARH: "Santokhgarh",
    SARAHAN: "Sarahan",
    SARKA_GHAT: "Sarka Ghat",
    SEONI: "Seoni",
    SHIMLA: "Shimla",
    SIRMAUR: "Sirmaur",
    SOLAN: "Solan",
    SUNDARNAGAR: "Sundarnagar",
    THEOG: "Theog",
    TIRA_SUJANPUR: "Tira Sujanpur",
    UNA: "Una",
    YOL: "Yol",
  },
  HR: {
    AMBALA: "Ambala",
    ASANDH: "Asandh",
    ATELI_MANDI: "Ateli Mandi",
    BAHADURGARH: "Bahadurgarh",
    BARA_UCHANA: "Bara Uchana",
    BARWALA: "Barwala",
    BAWAL: "Bawal",
    BERI_KHAS: "Beri Khas",
    BHIWANI: "Bhiwani",
    BILASPUR: "Bilaspur",
    BURIYA: "Buriya",
    CHARKHI_DADRI: "Charkhi Dadri",
    CHHACHHRAULI: "Chhachhrauli",
    DABWALI: "Dabwali",
    DHARUHERA: "Dharuhera",
    ELLENABAD: "Ellenabad",
    FARIDABAD: "Faridabad",
    FARIDABAD_DISTRICT: "Faridabad District",
    FARRUKHNAGAR: "Farrukhnagar",
    FATEHABAD: "Fatehabad",
    FATEHABAD_DISTRICT: "Fatehabad District",
    FIROZPUR_JHIRKA: "Firozpur Jhirka",
    GHARAUNDA: "Gharaunda",
    GOHANA: "Gohana",
    GORAKHPUR: "Gorakhpur",
    GURGAON: "Gurgaon",
    HANSI: "Hansi",
    HASANPUR: "Hasanpur",
    HISAR: "Hisar",
    HODAL: "Hodal",
    INDA_CHHOI: "Inda Chhoi",
    INDRI: "Indri",
    JAGADHRI: "Jagadhri",
    JAKHAL: "Jakhal",
    JHAJJAR: "Jhajjar",
    JIND: "Jind",
    KAITHAL: "Kaithal",
    KALANAUR: "Kalanaur",
    KALANWALI: "Kalanwali",
    KANINA_KHAS: "Kanina Khas",
    KARNAL: "Karnal",
    KHARKHAUDA: "Kharkhauda",
    KHERI_SAMPLA: "Kheri Sampla",
    KURUKSHETRA: "Kurukshetra",
    LADWA: "Ladwa",
    LOHARU: "Loharu",
    MAHAM: "Maham",
    MAHENDRAGARH: "Mahendragarh",
    MANDHOLI_KALAN: "Mandholi Kalan",
    MUSTAFABAD: "Mustafabad",
    NARAYANGARH: "Narayangarh",
    NARNAUL: "Narnaul",
    NARNAUND: "Narnaund",
    NARWANA: "Narwana",
    NILOKHERI: "Nilokheri",
    NUH: "Nuh",
    PALWAL: "Palwal",
    PANCHKULA: "Panchkula",
    PANIPAT: "Panipat",
    PATAUDI: "Pataudi",
    PEHOWA: "Pehowa",
    PINJAUR: "Pinjaur",
    PUNAHANA: "Punahana",
    PUNDRI: "Pundri",
    RADAUR: "Radaur",
    RANIA: "Rania",
    RATIA: "Ratia",
    REWARI: "Rewari",
    REWARI_DISTRICT: "Rewari District",
    ROHTAK: "Rohtak",
    SAFIDON: "Safidon",
    SAMALKHA: "Samalkha",
    SHADIPUR_JULANA: "Shadipur Julana",
    SHAHABAD: "Shahabad",
    SIRSA: "Sirsa",
    SOHNA: "Sohna",
    SONIPAT: "Sonipat",
    TAORU: "Taoru",
    THANESAR: "Thanesar",
    TOHANA: "Tohana",
    TOSHAM: "Tosham",
    UKLANA: "Uklana",
    YAMUNANAGAR: "Yamunanagar",
  },
  JH: {
    BAGRA: "Bagra",
    BARKA_KANA: "Barka Kana",
    BARKI_SARIA: "Barki Saria",
    BARWADIH: "Barwadih",
    BHOJUDIH: "Bhojudih",
    BOKARO: "Bokaro",
    BUNDU: "Bundu",
    CHAIBASA: "Chaibasa",
    CHAKRADHARPUR: "Chakradharpur",
    CHAKULIA: "Chakulia",
    CHANDIL: "Chandil",
    CHAS: "Chas",
    CHATRA: "Chatra",
    CHIRIA: "Chiria",
    DALTONGANJ: "Daltonganj",
    DEOGARH: "Deogarh",
    DHANBAD: "Dhanbad",
    DHANWAR: "Dhanwar",
    DUGDA: "Dugda",
    DUMKA: "Dumka",
    GARHWA: "Garhwa",
    GHATSILA: "Ghatsila",
    GIRIDIH: "Giridih",
    GOBINDPUR: "Gobindpur",
    GODDA: "Godda",
    GOMOH: "Gomoh",
    GOPINATHPUR: "Gopinathpur",
    GUA: "Gua",
    GUMIA: "Gumia",
    GUMLA: "Gumla",
    HAZARIBAG: "Hazaribag",
    HAZARIBAGH: "Hazaribagh",
    HESLA: "Hesla",
    HUSAINABAD: "Husainabad",
    JAGANNATHPUR: "Jagannathpur",
    JAMADOBA: "Jamadoba",
    JAMSHEDPUR: "Jamshedpur",
    JAMTARA: "Jamtara",
    JASIDIH: "Jasidih",
    JHARIA: "Jharia",
    JUGSALAI: "Jugsalai",
    JUMRI_TILAIYA: "Jumri Tilaiya",
    KALIKAPUR: "Kalikapur",
    KANDRA: "Kandra",
    KANKE: "Kanke",
    KATRAS: "Katras",
    KENDUADIH: "Kenduadih",
    KHARSAWAN: "Kharsawan",
    KHUNTI: "Khunti",
    KODARMA: "Kodarma",
    KUJU: "Kuju",
    LATEHAR: "Latehar",
    LOHARDAGA: "Lohardaga",
    MADHUPUR: "Madhupur",
    MALKERA: "Malkera",
    MANOHARPUR: "Manoharpur",
    MUGMA: "Mugma",
    MUSHABANI: "Mushabani",
    NETURHAT: "Neturhat",
    NIRSA: "Nirsa",
    NOAMUNDI: "Noamundi",
    PAKUR: "Pakur",
    PALAMU: "Palamu",
    PASHCHIM_SINGHBHUM: "Pashchim Singhbhum",
    PATAMDA: "patamda",
    PATHARDIH: "Pathardih",
    PURBA_SINGHBHUM: "Purba Singhbhum",
    RAMGARH: "Ramgarh",
    RANCHI: "Ranchi",
    RAY: "Ray",
    SAHIBGANJ: "Sahibganj",
    SARAIKELA: "Saraikela",
    SARUBERA: "Sarubera",
    SIJUA: "Sijua",
    SIMDEGA: "Simdega",
    SINI: "Sini",
    TOPCHANCHI: "Topchanchi",
  },
  JK: {
    AKHNUR: "Akhnur",
    ANANTNAG: "Anantnag",
    AWANTIPUR: "Awantipur",
    BADGAM: "Badgam",
    BANDIPORE: "Bandipore",
    BANDIPURA: "Bandipura",
    BANIHAL: "Banihal",
    BARAMULA: "Baramula",
    BATOTI: "Batoti",
    BHADARWAH: "Bhadarwah",
    BIJBEHARA: "Bijbehara",
    BISHNAH: "Bishnah",
    DODA: "Doda",
    GANDARBAL: "Gandarbal",
    GANDERBAL: "Ganderbal",
    GHO_BRAHMANAN_DE: "Gho Brahmanan de",
    HAJAN: "Hajan",
    HIRANAGAR: "Hiranagar",
    JAMMU: "Jammu",
    JAURIAN: "Jaurian",
    KATHUA: "Kathua",
    KATRA: "Katra",
    KHAUR: "Khaur",
    KISHTWAR: "Kishtwar",
    KUD: "Kud",
    KULGAM: "Kulgam",
    KUPWARA: "Kupwara",
    LADAKH: "Ladakh",
    MAGAM: "Magam",
    NAWANSHAHR: "Nawanshahr",
    NORIA: "Noria",
    PADAM: "Padam",
    PAHLGAM: "Pahlgam",
    PAROL: "Parol",
    PATTAN: "Pattan",
    PULWAMA: "Pulwama",
    PUNCH: "Punch",
    QAZIGUND: "Qazigund",
    RAJAORI: "Rajaori",
    RAJAURI: "Rajauri",
    RAMBAN: "Ramban",
    RAMGARH: "Ramgarh",
    RAMNAGAR: "Ramnagar",
    RIASI: "Riasi",
    SAMBA: "Samba",
    SHUPIYAN: "Shupiyan",
    SOPUR: "Sopur",
    SOYIBUG: "Soyibug",
    SRINAGAR: "Srinagar",
    SUMBAL: "Sumbal",
    THANG: "Thang",
    THANNA_MANDI: "Thanna Mandi",
    TRAL: "Tral",
    TSRAR_SHARIF: "Tsrar Sharif",
    UDHAMPUR: "Udhampur",
    URI: "Uri",
  },
  KA: {
    AFZALPUR: "Afzalpur",
    AJJAMPUR: "Ajjampur",
    ALAND: "Aland",
    ALNAVAR: "Alnavar",
    ALUR: "Alur",
    ANEKAL: "Anekal",
    ANKOLA: "Ankola",
    ANNIGERI: "Annigeri",
    ARKALGUD: "Arkalgud",
    ARSIKERE: "Arsikere",
    ATHNI: "Athni",
    AURAD: "Aurad",
    BADAMI: "Badami",
    BAGALKOT: "Bagalkot",
    BAGEPALLI: "Bagepalli",
    BAIL_HONGAL: "Bail-Hongal",
    BALLARI: "Ballari",
    BALLARY: "Ballary",
    BANAVAR: "Banavar",
    BANGALORE_RURAL: "Bangalore Rural",
    BANGALORE_URBAN: "Bangalore Urban",
    BANGARAPET: "Bangarapet",
    BANNUR: "Bannur",
    BANTVAL: "Bantval",
    BASAVAKALYAN: "Basavakalyan",
    BASAVANA_BAGEVADI: "Basavana Bagevadi",
    BELAGAVI: "Belagavi",
    BELLURU: "Belluru",
    BELTANGADI: "Beltangadi",
    BELUR: "Belur",
    BENGALURU: "Bengaluru",
    BHADRAVATI: "Bhadravati",
    BHALKI: "Bhalki",
    BHATKAL: "Bhatkal",
    BIDAR: "Bidar",
    BILGI: "Bilgi",
    BIRUR: "Birur",
    BYADGI: "Byadgi",
    BYNDOOR: "Byndoor",
    CANACONA: "Canacona",
    CHALLAKERE: "Challakere",
    CHAMRAJNAGAR: "Chamrajnagar",
    CHANNAGIRI: "Channagiri",
    CHANNAPATNA: "Channapatna",
    CHANNARAYAPATNA: "Channarayapatna",
    CHIK_BALLAPUR: "Chik Ballapur",
    CHIKKABALLAPUR: "Chikkaballapur",
    CHIKKAMAGALURU: "Chikkamagaluru",
    CHIKNAYAKANHALLI: "Chiknayakanhalli",
    CHIKODI: "Chikodi",
    CHINCHOLI: "Chincholi",
    CHINTAMANI: "Chintamani",
    CHITAPUR: "Chitapur",
    CHITRADURGA: "Chitradurga",
    CLOSEPET: "Closepet",
    COONDAPOOR: "Coondapoor",
    DAKSHINA_KANNADA: "Dakshina Kannada",
    DANDELI: "Dandeli",
    DAVANAGERE: "Davanagere",
    DEVANHALLI: "Devanhalli",
    DHARWAD: "Dharwad",
    DOD_BALLAPUR: "Dod Ballapur",
    FRENCH_ROCKS: "French Rocks",
    GADAG: "Gadag",
    GADAG_BETAGERI: "Gadag-Betageri",
    GAJENDRAGARH: "Gajendragarh",
    GANGAWATI: "Gangawati",
    GANGOLLI: "Gangolli",
    GOKAK: "Gokak",
    GOKARNA: "Gokarna",
    GORIBIDNUR: "Goribidnur",
    GORUR: "Gorur",
    GUBBI: "Gubbi",
    GUDIBANDA: "Gudibanda",
    GULEDAGUDDA: "Guledagudda",
    GUNDLUPĒT: "Gundlupēt",
    GURMATKAL: "Gurmatkal",
    HADAGALLI: "Hadagalli",
    HALIYAL: "Haliyal",
    HAMPI: "Hampi",
    HANGAL: "Hangal",
    HARIHAR: "Harihar",
    HARPANAHALLI: "Harpanahalli",
    HASSAN: "Hassan",
    HAVERI: "Haveri",
    HEGGADADEVANKOTE: "Heggadadevankote",
    HIREKERUR: "Hirekerur",
    HIRIYUR: "Hiriyur",
    HOLALKERE: "Holalkere",
    HOLE_NARSIPUR: "Hole Narsipur",
    HOMNABAD: "Homnabad",
    HONAVAR: "Honavar",
    HONNALI: "Honnali",
    HOSANAGARA: "Hosanagara",
    HOSANGADI: "Hosangadi",
    HOSDURGA: "Hosdurga",
    HOSKOTE: "Hoskote",
    HOSPET: "Hospet",
    HUBBALLI: "Hubballi",
    HUKERI: "Hukeri",
    HUNGUND: "Hungund",
    HUNSUR: "Hunsur",
    ILKAL: "Ilkal",
    INDI: "Indi",
    JAGALUR: "Jagalur",
    JAMKHANDI: "Jamkhandi",
    JEVARGI: "Jevargi",
    KADUR: "Kadur",
    KALABURGI: "Kalaburgi",
    KALGHATGI: "Kalghatgi",
    KAMPLI: "Kampli",
    KANKANHALLI: "Kankanhalli",
    KARKALA: "Karkala",
    KARWAR: "Karwar",
    KAVALUR: "Kavalur",
    KERUR: "Kerur",
    KHANAPUR: "Khanapur",
    KODAGU: "Kodagu",
    KODIGENAHALLI: "Kodigenahalli",
    KODLIPET: "Kodlipet",
    KOLAR: "Kolar",
    KOLLEGAL: "Kollegal",
    KONANUR: "Konanur",
    KONNUR: "Konnur",
    KOPPA: "Koppa",
    KOPPAL: "Koppal",
    KORATAGERE: "Koratagere",
    KOTTURU: "Kotturu",
    KRISHNARAJPET: "Krishnarajpet",
    KUDACHI: "Kudachi",
    KUDLIGI: "Kudligi",
    KUMSI: "Kumsi",
    KUMTA: "Kumta",
    KUNDGOL: "Kundgol",
    KUNIGAL: "Kunigal",
    KURGUNTA: "Kurgunta",
    KUSHALNAGAR: "Kushalnagar",
    KUSHTAGI: "Kushtagi",
    LAKSHMESHWAR: "Lakshmeshwar",
    LINGSUGUR: "Lingsugur",
    LONDA: "Londa",
    MADDAGIRI: "Maddagiri",
    MADDUR: "Maddur",
    MADIKERI: "Madikeri",
    MAGADI: "Magadi",
    MAHALINGPUR: "Mahalingpur",
    MALAVALLI: "Malavalli",
    MALPE: "Malpe",
    MALUR: "Malur",
    MANDYA: "Mandya",
    MANGALURU: "Mangaluru",
    MANIPAL: "Manipal",
    MANVI: "Manvi",
    MAYAKONDA: "Mayakonda",
    MELUKOTE: "Melukote",
    MUDBIDRI: "Mudbidri",
    MUDDEBIHAL: "Muddebihal",
    MUDGAL: "Mudgal",
    MUDGERE: "Mudgere",
    MUDHOL: "Mudhol",
    MULBAGAL: "Mulbagal",
    MULGUND: "Mulgund",
    MULKI: "Mulki",
    MUNDARGI: "Mundargi",
    MUNDGOD: "Mundgod",
    MUNIRABAD: "Munirabad",
    MURUDESHWARA: "Murudeshwara",
    MYSURU: "Mysuru",
    NAGAMANGALA: "Nagamangala",
    NANJANGUD: "Nanjangud",
    NARASIMHARAJAPURA: "Narasimharajapura",
    NAREGAL: "Naregal",
    NARGUND: "Nargund",
    NAVALGUND: "Navalgund",
    NELAMANGALA: "Nelamangala",
    NYAMTI: "Nyamti",
    PANGALA: "Pangala",
    PAVUGADA: "Pavugada",
    PIRIYAPATNA: "Piriyapatna",
    PONNAMPET: "Ponnampet",
    PUTTUR: "Puttur",
    RABKAVI: "Rabkavi",
    RAICHUR: "Raichur",
    RAMANAGARA: "Ramanagara",
    RANIBENNUR: "Ranibennur",
    RAYBAG: "Raybag",
    ROBERTSONPET: "Robertsonpet",
    RON: "Ron",
    SADALGI: "Sadalgi",
    SAGAR: "Sagar",
    SAKLESHPUR: "Sakleshpur",
    SANDUR: "Sandur",
    SANIVARSANTE: "Sanivarsante",
    SANKESHWAR: "Sankeshwar",
    SARGUR: "Sargur",
    SAUNDATTI: "Saundatti",
    SAVANUR: "Savanur",
    SERAM: "Seram",
    SHAHABAD: "Shahabad",
    SHAHPUR: "Shahpur",
    SHIGGAON: "Shiggaon",
    SHIKARPUR: "Shikarpur",
    SHIMOGA: "Shimoga",
    SHIRHATTI: "Shirhatti",
    SHORAPUR: "Shorapur",
    SHRIRANGAPATTANA: "Shrirangapattana",
    SIDDAPUR: "Siddapur",
    SIDLAGHATTA: "Sidlaghatta",
    SINDGI: "Sindgi",
    SINDHNUR: "Sindhnur",
    SIRA: "Sira",
    SIRSI: "Sirsi",
    SIRUGUPPA: "Siruguppa",
    SOMESHWAR: "Someshwar",
    SOMVARPET: "Somvarpet",
    SORAB: "Sorab",
    SRAVANA_BELGOLA: "Sravana Belgola",
    SRINGERI: "Sringeri",
    SRINIVASPUR: "Srinivaspur",
    SULYA: "Sulya",
    SUNTIKOPPA: "Suntikoppa",
    TALIKOTA: "Talikota",
    TARIKERE: "Tarikere",
    TEKKALAKOTE: "Tekkalakote",
    TERDAL: "Terdal",
    TIPTUR: "Tiptur",
    TIRTHAHALLI: "Tirthahalli",
    TIRUMAKUDAL_NARSIPUR: "Tirumakudal Narsipur",
    TUMAKURU: "Tumakuru",
    TURUVEKERE: "Turuvekere",
    UDUPI: "Udupi",
    ULLAL: "Ullal",
    UTTAR_KANNADA: "Uttar Kannada",
    VADIGENHALLI: "Vadigenhalli",
    VIJAYAPURA: "Vijayapura",
    VIRARAJENDRAPET: "Virarajendrapet",
    WADI: "Wadi",
    YADGIR: "Yadgir",
    YELAHANKA: "Yelahanka",
    YELANDUR: "Yelandur",
    YELBARGA: "Yelbarga",
    YELLAPUR: "Yellapur",
  },
  KL: {
    ADUR: "Adur",
    ALAPPUZHA: "Alappuzha",
    ALUVA: "Aluva",
    ALWAYE: "Alwaye",
    ANGAMALI: "Angamali",
    AROOR: "Aroor",
    ARUKUTTI: "Arukutti",
    ATTINGAL: "Attingal",
    AVANOOR: "Avanoor",
    AZHIKKAL: "Azhikkal",
    BADAGARA: "Badagara",
    BEYPORE: "Beypore",
    CHANGANACHERI: "Changanacheri",
    CHELAKARA: "Chelakara",
    CHENGANNUR: "Chengannur",
    CHERPULASSERY: "Cherpulassery",
    CHERTHALA: "Cherthala",
    CHETWAYI: "Chetwayi",
    CHITTUR: "Chittur",
    COCHIN: "Cochin",
    DHARMADAM: "Dharmadam",
    EDAKKULAM: "Edakkulam",
    ELUR: "Elur",
    ERATTUPETTA: "Erattupetta",
    ERNAKULAM: "Ernakulam",
    FEROKH: "Ferokh",
    GURUVAYUR: "Guruvayur",
    IDUKKI: "Idukki",
    IRINGAL: "Iringal",
    IRINJALAKUDA: "Irinjalakuda",
    KADAKKAVOOR: "Kadakkavoor",
    KALAMASSERY: "Kalamassery",
    KALAVOOR: "Kalavoor",
    KALPATTA: "Kalpatta",
    KANNANGAD: "Kannangad",
    KANNAVAM: "Kannavam",
    KANNUR: "Kannur",
    KASARAGOD: "Kasaragod",
    KASARAGOD_DISTRICT: "Kasaragod District",
    KATTANAM: "Kattanam",
    KAYANKULAM: "Kayankulam",
    KIZHAKE_CHALAKUDI: "Kizhake Chalakudi",
    KODUNGALLUR: "Kodungallur",
    KOLLAM: "Kollam",
    KOTAMANGALAM: "Kotamangalam",
    KOTTAYAM: "Kottayam",
    KOVALAM: "Kovalam",
    KOZHIKODE: "Kozhikode",
    KUMBALAM: "Kumbalam",
    KUNNAMANGALAM: "Kunnamangalam",
    KUNNAMKULAM: "Kunnamkulam",
    KUNNUMMA: "Kunnumma",
    KUTIATODU: "Kutiatodu",
    KUTTAMPUZHA: "Kuttampuzha",
    LALAM: "Lalam",
    MAHE: "Mahe",
    MALAPPURAM: "Malappuram",
    MANJERI: "Manjeri",
    MANJESHVAR: "Manjeshvar",
    MANNARAKKAT: "Mannarakkat",
    MARAYUR: "Marayur",
    MATTANUR: "Mattanur",
    MAVELIKARA: "Mavelikara",
    MAVOOR: "Mavoor",
    MULUPPILAGADU: "Muluppilagadu",
    MUNNAR: "Munnar",
    MUVATTUPULA: "Muvattupula",
    MUVATTUPUZHA: "Muvattupuzha",
    NADAPURAM: "Nadapuram",
    NADUVANNUR: "Naduvannur",
    NEDUMANGAD: "Nedumangad",
    NEYYATTINKARA: "Neyyattinkara",
    NILESHWAR: "Nileshwar",
    OTTAPPALAM: "Ottappalam",
    PALACKATTUMALA: "Palackattumala",
    PALAKKAD_DISTRICT: "Palakkad district",
    PALGHAT: "Palghat",
    PANAMARAM: "Panamaram",
    PAPPINISSHERI: "Pappinissheri",
    PARAVUR_TEKKUMBHAGAM: "Paravur Tekkumbhagam",
    PARIYAPURAM: "Pariyapuram",
    PATHANAMTHITTA: "Pathanamthitta",
    PATTANAMTITTA: "Pattanamtitta",
    PAYYANNUR: "Payyannur",
    PERUMBAVOOR: "Perumbavoor",
    PERUMPAVUR: "Perumpavur",
    PERYA: "Perya",
    PIRAVAM: "Piravam",
    PONMANA: "Ponmana",
    PONNANI: "Ponnani",
    PUNALUR: "Punalur",
    RAMAMANGALAM: "Ramamangalam",
    SHERTALLAI: "Shertallai",
    SHŌRANUR: "Shōranur",
    TALIPPARAMBA: "Talipparamba",
    TELLICHERRY: "Tellicherry",
    THANNIYAM: "Thanniyam",
    THIRUVANANTHAPURAM: "Thiruvananthapuram",
    THRISSUR: "Thrissur",
    THRISSUR_DISTRICT: "Thrissur District",
    TIRUR: "Tirur",
    TIRUVALLA: "Tiruvalla",
    VAIKAM: "Vaikam",
    VARKALA: "Varkala",
    VAYALAR: "Vayalar",
    VETTUR: "Vettur",
    WAYANAD: "Wayanad",
  },
  LA: {
    KARGIL: "Kargil",
    LEH: "Leh",
  },
  LD: {
    KAVARATTI: "Kavaratti",
    LAKSHADWEEP: "Lakshadweep",
  },
  MH: {
    ACHALPUR: "Achalpur",
    ADAWAD: "Adawad",
    AGAR_PANCHAITAN: "Agar Panchaitan",
    AHERI: "Aheri",
    AHIRI: "Ahiri",
    AHMADNAGAR: "Ahmadnagar",
    AHMADPUR: "Ahmadpur",
    AHMEDNAGAR: "Ahmednagar",
    AIROLI: "Airoli",
    AJRA: "Ajra",
    AKALKOT: "Akalkot",
    AKKALKOT: "Akkalkot",
    AKLUJ: "Akluj",
    AKOLA: "Akola",
    AKOLNER: "Akolner",
    AKOT: "Akot",
    AKRANI: "Akrani",
    ALANDI: "Alandi",
    ALE: "Ale",
    ALIBAG: "Alibag",
    ALKUTI: "Alkuti",
    ALLAPALLI: "Allapalli",
    AMALNER: "Amalner",
    AMARNATH: "Amarnath",
    AMBAD: "Ambad",
    AMBAJOGAI: "Ambajogai",
    AMBARNATH: "Ambarnath",
    AMBEGAON: "Ambegaon",
    AMGAON: "Amgaon",
    AMRAVATI: "Amravati",
    AMRAVATI_DIVISION: "Amravati Division",
    ANDHERI: "Andheri",
    ANDURA: "Andura",
    ANJANGAON: "Anjangaon",
    ANJARLE: "Anjarle",
    ANSHING: "Anshing",
    ARAG: "Arag",
    ARANGAON: "Arangaon",
    ARDHAPUR: "Ardhapur",
    ARGAON: "Argaon",
    ARTIST_VILLAGE: "Artist Village",
    ARVI: "Arvi",
    ASHTA: "Ashta",
    ASHTI: "Ashti",
    ASODA: "Asoda",
    ASSAYE: "Assaye",
    ASTAGAON: "Astagaon",
    AUNDH_SATARA: "Aundh, Satara",
    AURANGABAD: "Aurangabad",
    AUSA: "Ausa",
    BADLAPUR: "Badlapur",
    BADNAPUR: "Badnapur",
    BADNERA: "Badnera",
    BAGEWADI: "Bagewadi",
    BALAPUR: "Balapur",
    BALAPUR_AKOLA_DISTRICT: "Balapur, Akola district",
    BALLALPUR: "Ballalpur",
    BALLARD_ESTATE: "Ballard Estate",
    BALLARPUR: "Ballarpur",
    BANDA_MAHARASHTRA: "Banda, Maharashtra",
    BANDRA: "Bandra",
    BANER: "Baner",
    BANKOT: "Bankot",
    BARAMATI: "Baramati",
    BARSI: "Barsi",
    BASMAT: "Basmat",
    BASMATH: "Basmath",
    BAVDHAN: "Bavdhan",
    BAWANBIR: "Bawanbir",
    BEED: "Beed",
    BHADGAON_MAHARASHTRA: "Bhadgaon, Maharashtra",
    BHANDARA: "Bhandara",
    BHANDARDARA: "Bhandardara",
    BHANDUP: "Bhandup",
    BHAYANDAR: "Bhayandar",
    BHIGVAN: "Bhigvan",
    BHIWANDI: "Bhiwandi",
    BHIWAPUR: "Bhiwapur",
    BHOKAR: "Bhokar",
    BHOKARDAN: "Bhokardan",
    BHOOM: "Bhoom",
    BHOR: "Bhor",
    BHUDGAON: "Bhudgaon",
    BHUGAON: "Bhugaon",
    BHUM: "Bhum",
    BHUSAVAL: "Bhusaval",
    BHUSAWAL: "Bhusawal",
    BID: "Bid",
    BIJUR: "Bijur",
    BILASHI: "Bilashi",
    BILOLI: "Biloli",
    BOISAR: "Boisar",
    BORGAON_MANJU: "Borgaon Manju",
    BORIVALI: "Borivali",
    BORIVLI: "Borivli",
    BRAHMAPURI: "Brahmapuri",
    BREACH_CANDY: "Breach Candy",
    BULDANA: "Buldana",
    BULDHANA: "Buldhana",
    BYCULLA: "Byculla",
    CHAKAN: "Chakan",
    CHAKUR: "Chakur",
    CHALISGAON: "Chalisgaon",
    CHANDA: "Chanda",
    CHANDGAD: "Chandgad",
    CHANDOR: "Chandor",
    CHANDRAPUR: "Chandrapur",
    CHANDUR: "Chandur",
    CHANDUR_BAZAR: "Chandur Bazar",
    CHAUSALA: "Chausala",
    CHEMBUR: "Chembur",
    CHICHOLI: "Chicholi",
    CHICHONDI_PATIL: "Chichondi Patil",
    CHIKHLI_BULDHANA: "Chikhli (Buldhana)",
    CHIKHLI_JALNA: "Chikhli (Jalna)",
    CHIMUR: "Chimur",
    CHINCHANI: "Chinchani",
    CHINCHPOKLI: "Chinchpokli",
    CHIPLUN: "Chiplun",
    CHOPDA: "Chopda",
    COLABA: "Colaba",
    DABHOL: "Dabhol",
    DADDI: "Daddi",
    DAHANU: "Dahanu",
    DAHIVEL: "Dahivel",
    DAPOLI: "Dapoli",
    DARWHA: "Darwha",
    DARYAPUR: "Daryapur",
    DATTAPUR: "Dattapur",
    DAULATABAD: "Daulatabad",
    DAUND: "Daund",
    DECCAN_GYMKHANA: "Deccan Gymkhana",
    DEGLUR: "Deglur",
    DEHU: "Dehu",
    DEOLALI: "Deolali",
    DEOLAPAR: "Deolapar",
    DEOLI: "Deoli",
    DEONI: "Deoni",
    DEULGAON_RAJA: "Deulgaon Raja",
    DEVRUKH: "Devrukh",
    DHARANGAON: "Dharangaon",
    DHARAVI: "Dharavi",
    DHARMABAD: "Dharmabad",
    DHARUR: "Dharur",
    DHAWALPURI: "Dhawalpuri",
    DHULE: "Dhule",
    DHULIA: "Dhulia",
    DIGHORI: "Dighori",
    DIGLUR: "Diglur",
    DIGRAS: "Digras",
    DINDORI_MAHARASHTRA: "Dindori, Maharashtra",
    DIVEAGAR: "Diveagar",
    DOMBIVLI: "Dombivli",
    DONDAICHA: "Dondaicha",
    DONGRI: "Dongri",
    DUDHANI: "Dudhani",
    DURGAPUR: "Durgapur",
    DURGAPUR_CHANDRAPUR: "Durgapur, Chandrapur",
    ERANDOL: "Erandol",
    FAIZPUR: "Faizpur",
    FORT: "Fort",
    GADCHIROLI: "Gadchiroli",
    GADHINGLAJ: "Gadhinglaj",
    GANGAKHER: "Gangakher",
    GANGAPUR: "Gangapur",
    GANPATIPULE: "Ganpatipule",
    GEVRAI: "Gevrai",
    GHARGAON: "Ghargaon",
    GHATANJI: "Ghatanji",
    GHATKOPAR: "Ghatkopar",
    GHOTI_BUDRUKH: "Ghoti Budrukh",
    GHUGUS: "Ghugus",
    GIRGAON: "Girgaon",
    GONDIA: "Gondia",
    GONDIYA: "Gondiya",
    GORAI: "Gorai",
    GOREGAON: "Goregaon",
    GUHAGAR: "Guhagar",
    HADAPSAR_PUNE: "Hadapsar, Pune",
    HADGAON: "Hadgaon",
    HALKARNI: "Halkarni",
    HARANGUL: "Harangul",
    HARNAI: "Harnai",
    HELWAK: "Helwak",
    HINGANGHAT: "Hinganghat",
    HINGOLI: "Hingoli",
    HIRAPUR: "Hirapur",
    HIRAPUR_HAMESHA: "Hirapur Hamesha",
    HOTGI: "Hotgi",
    ICHALKARANJI: "Ichalkaranji",
    IGATPURI: "Igatpuri",
    INDAPUR: "Indapur",
    JAISINGPUR: "Jaisingpur",
    JAITAPUR: "Jaitapur",
    JAKHANGAON: "Jakhangaon",
    JALGAON: "Jalgaon",
    JALGAON_JAMOD: "Jalgaon Jamod",
    JALKOT: "Jalkot",
    JALNA: "Jalna",
    JAMKHED: "Jamkhed",
    JAMOD: "Jamod",
    JANEPHAL: "Janephal",
    JAOLI: "Jaoli",
    JAT_SANGLI: "Jat, Sangli",
    JATEGAON: "Jategaon",
    JAWHAR: "Jawhar",
    JAYSINGPUR: "Jaysingpur",
    JEJURI: "Jejuri",
    JINTUR: "Jintur",
    JOGESHWARI: "Jogeshwari",
    JUHU: "Juhu",
    JUNNAR: "Junnar",
    KACHURWAHI: "Kachurwahi",
    KADEGAON: "Kadegaon",
    KADUS: "Kadus",
    KAGAL: "Kagal",
    KAIJ: "Kaij",
    KALAMB: "Kalamb",
    KALAMB_OSMANABAD: "Kalamb, Osmanabad",
    KALAMBOLI: "Kalamboli",
    KALAMNURI: "Kalamnuri",
    KALAS: "Kalas",
    KALI_DK: "Kali(DK)",
    KALMESHWAR: "Kalmeshwar",
    KALUNDRI: "Kalundri",
    KALYAN: "Kalyan",
    KALYANI_NAGAR: "Kalyani Nagar",
    KAMARGAON: "Kamargaon",
    KAMATGI: "Kamatgi",
    KAMPTEE: "Kamptee",
    KAMTHI: "Kamthi",
    KANDRI: "Kandri",
    KANKAULI: "Kankauli",
    KANKAVLI: "Kankavli",
    KANNAD: "Kannad",
    KARAD: "Karad",
    KARAJAGI: "Karajagi",
    KARANJA: "Karanja",
    KARANJA_LAD: "Karanja Lad",
    KARJAT: "Karjat",
    KARKAMB: "Karkamb",
    KARMALA: "Karmala",
    KASARA: "Kasara",
    KASODA: "Kasoda",
    KATI: "Kati",
    KATOL: "Katol",
    KATRAL: "Katral",
    KHADKI: "Khadki",
    KHALAPUR: "Khalapur",
    KHALLAR: "Khallar",
    KHAMGAON: "Khamgaon",
    KHANAPUR: "Khanapur",
    KHANDALA: "Khandala",
    KHANGAON: "Khangaon",
    KHAPA: "Khapa",
    KHARAKVASLA: "Kharakvasla",
    KHARDA: "Kharda",
    KHARGHAR: "Kharghar",
    KHARSUNDI: "Kharsundi",
    KHED: "Khed",
    KHEDE: "Khede",
    KHETIA: "Khetia",
    KHONI: "Khoni",
    KHOPOLI: "Khopoli",
    KHULDABAD: "Khuldabad",
    KINWAT: "Kinwat",
    KODOLI: "Kodoli",
    KOLHAPUR: "Kolhapur",
    KONDALWADI: "Kondalwadi",
    KONDHALI: "Kondhali",
    KOPAR_KHAIRANE: "Kopar Khairane",
    KOPARGAON: "Kopargaon",
    KOPELA: "Kopela",
    KORADI: "Koradi",
    KOREGAON: "Koregaon",
    KOYNANAGAR: "Koynanagar",
    KUDAL: "Kudal",
    KUHI: "Kuhi",
    KURANDVAD: "Kurandvad",
    KURANKHED: "Kurankhed",
    KURDUVADI: "Kurduvadi",
    KUSUMBA: "Kusumba",
    LAKHANDUR: "Lakhandur",
    LANJA: "Lanja",
    LASALGAON: "Lasalgaon",
    LATUR: "Latur",
    LAVASA: "Lavasa",
    LOHOGAON: "Lohogaon",
    LONAR: "Lonar",
    LONAVLA: "Lonavla",
    MAHABALESHWAR: "Mahabaleshwar",
    MAHAD: "Mahad",
    MAHAPE: "Mahape",
    MAHIM: "Mahim",
    MAINDARGI: "Maindargi",
    MAJALGAON: "Majalgaon",
    MAKHJAN: "Makhjan",
    MALABAR_HILL: "Malabar Hill",
    MALAD: "Malad",
    MALEGAON: "Malegaon",
    MALKAPUR: "Malkapur",
    MALVAN: "Malvan",
    MANCHAR: "Manchar",
    MANDANGAD: "Mandangad",
    MANDHAL: "Mandhal",
    MANDWA: "Mandwa",
    MANGAON: "Mangaon",
    MANGRUL_PIR: "Mangrul Pir",
    MANJLEGAON: "Manjlegaon",
    MANKESHWAR: "Mankeshwar",
    MANKHURD: "Mankhurd",
    MANMAD: "Manmad",
    MANOR: "Manor",
    MANSAR: "Mansar",
    MANWAT: "Manwat",
    MANWATH: "Manwath",
    MAREGAON: "Maregaon",
    MASTIHOLI: "Mastiholi",
    MASUR_INDIA: "Masur, India",
    MATHERAN: "Matheran",
    MATUNGA: "Matunga",
    MAZAGAON: "Mazagaon",
    MEHEKAR: "Mehekar",
    MEHERGAON: "Mehergaon",
    MEHKAR: "Mehkar",
    MHASLA: "Mhasla",
    MHASVAD: "Mhasvad",
    MIRAJ: "Miraj",
    MOHADI: "Mohadi",
    MOHOL: "Mohol",
    MOHPA: "Mohpa",
    MOKHADA_TALUKA: "Mokhada taluka",
    MORA_MAHARASHTRA: "Mora, Maharashtra",
    MORAM: "Moram",
    MORSI: "Morsi",
    MOWAD: "Mowad",
    MUDKHED: "Mudkhed",
    MUKHER: "Mukher",
    MUL: "Mul",
    MULHER: "Mulher",
    MULUND: "Mulund",
    MUMBAI: "Mumbai",
    MUMBAI_SUBURBAN: "Mumbai Suburban",
    MURBAD: "Murbad",
    MURGUD: "Murgud",
    MURTAJAPUR: "Murtajapur",
    MURUD_RAIGAD: "Murud (Raigad)",
    MURUD_RATNAGIRI: "Murud (Ratnagiri)",
    MURUM: "Murum",
    NADGAON: "Nadgaon",
    NAGAPUR: "Nagapur",
    NAGOTHANA: "Nagothana",
    NAGPUR: "Nagpur",
    NAGPUR_DIVISION: "Nagpur Division",
    NALA_SOPARA: "Nala Sopara",
    NALDURG: "Naldurg",
    NALEGAON: "Nalegaon",
    NAMPUR: "Nampur",
    NANDED: "Nanded",
    NANDGAON: "Nandgaon",
    NANDNEE: "Nandnee",
    NANDURA: "Nandura",
    NANDURA_BUZURG: "Nandura Buzurg",
    NANDURBAR: "Nandurbar",
    NARAYANGAON: "Narayangaon",
    NARDANA: "Nardana",
    NARIMAN_POINT: "Nariman Point",
    NARKHED: "Narkhed",
    NASHIK: "Nashik",
    NASHIK_DIVISION: "Nashik Division",
    NAVAPUR: "Navapur",
    NAVI_MUMBAI: "Navi Mumbai",
    NERAL: "Neral",
    NERUR: "Nerur",
    NEVASA: "Nevasa",
    NIGHOJ: "Nighoj",
    NILANGA: "Nilanga",
    NIPANI: "Nipani",
    NIPHAD: "Niphad",
    NIRA_NARSINGPUR: "Nira Narsingpur",
    OSMANABAD: "Osmanabad",
    OZAR: "Ozar",
    PABAL: "Pabal",
    PACHORA: "Pachora",
    PAHUR_MAHARASHTRA: "Pahur, Maharashtra",
    PAITHAN: "Paithan",
    PALGHAR: "Palghar",
    PALI_RAIGAD: "Pali, Raigad",
    PALSO: "Palso",
    PANCHGANI: "Panchgani",
    PANDHARPUR: "Pandharpur",
    PANDHURLI: "Pandhurli",
    PANHALA: "Panhala",
    PANVEL: "Panvel",
    PARBHANI: "Parbhani",
    PAREL: "Parel",
    PARLI_VAIJNATH: "Parli Vaijnath",
    PARNER: "Parner",
    PAROLA: "Parola",
    PARSEONI: "Parseoni",
    PARTUR: "Partur",
    PATAN: "Patan",
    PATHARDI: "Pathardi",
    PATHRI: "Pathri",
    PATUR: "Patur",
    PATURDA: "Paturda",
    PAUD: "Paud",
    PAUNI: "Pauni",
    PAWNI: "Pawni",
    PEDGAON: "Pedgaon",
    PEINT: "Peint",
    PEN: "Pen",
    PHALTAN: "Phaltan",
    PHULAMBRI: "Phulambri",
    PILIV: "Piliv",
    PIMPALGAON_BASWANT: "Pimpalgaon Baswant",
    PIMPALGAON_RAJA: "Pimpalgaon Raja",
    PIMPRI: "Pimpri",
    PIMPRI_CHINCHWAD: "Pimpri-Chinchwad",
    PIPRI: "Pipri",
    POWAI: "Powai",
    PRABHADEVI: "Prabhadevi",
    PRAKASHA: "Prakasha",
    PULGAON: "Pulgaon",
    PUNE: "Pune",
    PUNE_DIVISION: "Pune Division",
    PUNTAMBA: "Puntamba",
    PURAL: "Pural",
    PURNA: "Purna",
    PUSAD: "Pusad",
    RADHANAGARI: "Radhanagari",
    RAHATA: "Rahata",
    RAHIMATPUR: "Rahimatpur",
    RAHURI: "Rahuri",
    RAIGARH: "Raigarh",
    RAIRESHWAR: "Raireshwar",
    RAJAPUR: "Rajapur",
    RAJGURUNAGAR: "Rajgurunagar",
    RAJUR: "Rajur",
    RAJURA: "Rajura",
    RALEGAON: "Ralegaon",
    RAMEWADI: "Ramewadi",
    RAMTEK: "Ramtek",
    RATNAGIRI: "Ratnagiri",
    RAVER: "Raver",
    RENAPUR: "Renapur",
    RENAVI: "Renavi",
    REVADANDA: "Revadanda",
    REVDANDA: "Revdanda",
    RISOD: "Risod",
    ROHA: "Roha",
    SAILU: "Sailu",
    SAKOL: "Sakol",
    SAKOLI: "Sakoli",
    SAKRI: "Sakri",
    SAMUDRAPUR: "Samudrapur",
    SANGAMESHWAR: "Sangameshwar",
    SANGAMNER: "Sangamner",
    SANGLI: "Sangli",
    SANGOLA: "Sangola",
    SANGOLE: "Sangole",
    SANGRAMPUR_MAHARASHTRA: "Sangrampur, Maharashtra",
    SAOLI: "Saoli",
    SAONER: "Saoner",
    SARANGKHEDA: "Sarangkheda",
    SASVAD: "Sasvad",
    SASWAD: "Saswad",
    SATANA: "Satana",
    SATARA: "Satara",
    SATARA_DIVISION: "Satara Division",
    SATPATI: "Satpati",
    SAVANTVADI: "Savantvadi",
    SAVDA: "Savda",
    SAVLAJ: "Savlaj",
    SAWANTVADI: "Sawantvadi",
    SELU: "Selu",
    SEVAGRAM: "Sevagram",
    SEWRI: "Sewri",
    SHAHADA: "Shahada",
    SHAHAPUR: "Shahapur",
    SHEDBAL: "Shedbal",
    SHEGAON: "Shegaon",
    SHEVGAON: "Shevgaon",
    SHIKRAPUR: "Shikrapur",
    SHIRAGUPPI: "Shiraguppi",
    SHIRALA: "Shirala",
    SHIRDI: "Shirdi",
    SHIRGAON: "Shirgaon",
    SHIROL: "Shirol",
    SHIRPUR: "Shirpur",
    SHIRUD: "Shirud",
    SHIRWAL: "Shirwal",
    SHIVAJI_NAGAR: "Shivaji Nagar",
    SHRIGONDA: "Shrigonda",
    SILLOD: "Sillod",
    SINDEWAHI: "Sindewahi",
    SINDHUDURG: "Sindhudurg",
    SINDI: "Sindi",
    SINDKHEDA: "Sindkheda",
    SINNAR: "Sinnar",
    SION_MUMBAI: "Sion, Mumbai",
    SIRONCHA: "Sironcha",
    SIRUR: "Sirur",
    SIVALA_EAST_GODAVARI_DISTRICT: "Sivala, East Godavari district",
    SOLAPUR: "Solapur",
    SONALA: "Sonala",
    SONEGAON: "Sonegaon",
    SONGIR: "Songir",
    SONVAD: "Sonvad",
    SOYGAON: "Soygaon",
    SRIVARDHAN: "Srivardhan",
    SURGANA: "Surgana",
    TAKLIBHAN: "Taklibhan",
    TALBID: "Talbid",
    TALEGAON_DABHADE: "Talegaon Dabhade",
    TALEGAON_DHAMDHERE: "Talegaon Dhamdhere",
    TALODA: "Taloda",
    TALODE: "Talode",
    TARAPUR: "Tarapur",
    TARDEO: "Tardeo",
    TASGAON: "Tasgaon",
    TELHARA: "Telhara",
    THALNER: "Thalner",
    THANE: "Thane",
    TRIMBAK: "Trimbak",
    TROMBAY: "Trombay",
    TULJAPUR: "Tuljapur",
    TUMSAR: "Tumsar",
    UDGIR: "Udgir",
    ULHASNAGAR: "Ulhasnagar",
    UMARGA: "Umarga",
    UMARKHED: "Umarkhed",
    UMRED: "Umred",
    URAN: "Uran",
    URULI_KANCHAN: "Uruli Kanchan",
    VADA: "Vada",
    VADGAON: "Vadgaon",
    VADNER: "Vadner",
    VAIJAPUR: "Vaijapur",
    VAIRAG: "Vairag",
    VALSANG: "Valsang",
    VANGAON: "Vangaon",
    VARANGAON: "Varangaon",
    VASHI: "Vashi",
    VASIND: "Vasind",
    VATUL: "Vatul",
    VELAS_MAHARASHTRA: "Velas, Maharashtra",
    VELNESHWAR: "Velneshwar",
    VENGURLA: "Vengurla",
    VIJAYDURG: "Vijaydurg",
    VIKHROLI: "Vikhroli",
    VILE_PARLE: "Vile Parle",
    VINCHUR: "Vinchur",
    VIRAR: "Virar",
    VITA_MAHARASHTRA: "Vita, Maharashtra",
    VITE: "Vite",
    WADALA: "Wadala",
    WADGAON: "Wadgaon",
    WADNER: "Wadner",
    WADWANI: "Wadwani",
    WAGHOLI: "Wagholi",
    WAI: "Wai",
    WAKAD: "Wakad",
    WALGAON: "Walgaon",
    WALKI: "Walki",
    WANI: "Wani",
    WARDHA: "Wardha",
    WARORA: "Warora",
    WARUD: "Warud",
    WASHIM: "Washim",
    WORLI: "Worli",
    YAVAL: "Yaval",
    YAVATMAL: "Yavatmal",
    YEOLA: "Yeola",
  },
  ML: {
    CHERRAPUNJI: "Cherrapunji",
    EAST_GARO_HILLS: "East Garo Hills",
    EAST_JAINTIA_HILLS: "East Jaintia Hills",
    EAST_KHASI_HILLS: "East Khasi Hills",
    MAIRANG: "Mairang",
    MANKACHAR: "Mankachar",
    NONGPOH: "Nongpoh",
    NONGSTOIN: "Nongstoin",
    NORTH_GARO_HILLS: "North Garo Hills",
    RI_BHOI: "Ri-Bhoi",
    SHILLONG: "Shillong",
    SOUTH_GARO_HILLS: "South Garo Hills",
    SOUTH_WEST_GARO_HILLS: "South West Garo Hills",
    SOUTH_WEST_KHASI_HILLS: "South West Khasi Hills",
    TURA: "Tura",
    WEST_GARO_HILLS: "West Garo Hills",
    WEST_JAINTIA_HILLS: "West Jaintia Hills",
    WEST_KHASI_HILLS: "West Khasi Hills",
  },
  MN: {
    BISHNUPUR: "Bishnupur",
    CHURACHANDPUR: "Churachandpur",
    IMPHAL: "Imphal",
    KAKCHING: "Kakching",
    MAYANG_IMPHAL: "Mayang Imphal",
    MOIRANG: "Moirang",
    PHEK: "Phek",
    SENAPATI: "Senapati",
    TAMENGLONG: "Tamenglong",
    THOUBAL: "Thoubal",
    UKHRUL: "Ukhrul",
    WANGJING: "Wangjing",
    YAIRIPOK: "Yairipok",
  },
  MP: {
    AGAR: "Agar",
    AJAIGARH: "Ajaigarh",
    AKODIA: "Akodia",
    ALAMPUR: "Alampur",
    ALIRAJPUR: "Alirajpur",
    ALOT: "Alot",
    AMANGANJ: "Amanganj",
    AMARKANTAK: "Amarkantak",
    AMARPATAN: "Amarpatan",
    AMARWARA: "Amarwara",
    AMBAH: "Ambah",
    AMLA: "Amla",
    ANJAD: "Anjad",
    ANTRI: "Antri",
    ANUPPUR: "Anuppur",
    ARON: "Aron",
    ASHOKNAGAR: "Ashoknagar",
    ASHTA: "Ashta",
    BABAI: "Babai",
    BADARWAS: "Badarwas",
    BADNAWAR: "Badnawar",
    BAG: "Bag",
    BAGLI: "Bagli",
    BAIHAR: "Baihar",
    BAIKUNTHPUR: "Baikunthpur",
    BAKSHWAHO: "Bakshwaho",
    BALAGHAT: "Balaghat",
    BALDEOGARH: "Baldeogarh",
    BAMNA: "Bamna",
    BAMOR_KALAN: "Bamor Kalan",
    BAMORA: "Bamora",
    BANDA: "Banda",
    BARELA: "Barela",
    BARGHAT: "Barghat",
    BARGI: "Bargi",
    BARHI: "Barhi",
    BARWANI: "Barwani",
    BASODA: "Basoda",
    BEGAMGANJ: "Begamganj",
    BEOHARI: "Beohari",
    BERASIA: "Berasia",
    BETMA: "Betma",
    BETUL: "Betul",
    BETUL_BAZAR: "Betul Bazar",
    BHABHRA: "Bhabhra",
    BHAINSDEHI: "Bhainsdehi",
    BHANDER: "Bhander",
    BHANPURA: "Bhanpura",
    BHAWANIGANJ: "Bhawaniganj",
    BHIKANGAON: "Bhikangaon",
    BHIND: "Bhind",
    BHITARWAR: "Bhitarwar",
    BHOPAL: "Bhopal",
    BIAORA: "Biaora",
    BIJAWAR: "Bijawar",
    BIJRAUNI: "Bijrauni",
    BODRI: "Bodri",
    BURHANPUR: "Burhanpur",
    BURHAR: "Burhar",
    CHANDERI: "Chanderi",
    CHANDIA: "Chandia",
    CHANDLA: "Chandla",
    CHHATARPUR: "Chhatarpur",
    CHHINDWARA: "Chhindwara",
    CHICHLI: "Chichli",
    CHORHAT: "Chorhat",
    DABOH: "Daboh",
    DABRA: "Dabra",
    DAMOH: "Damoh",
    DATIA: "Datia",
    DEORI_KHAS: "Deori Khas",
    DEPALPUR: "Depalpur",
    DEWAS: "Dewas",
    DHAMNOD: "Dhamnod",
    DHANA: "Dhana",
    DHAR: "Dhar",
    DHARAMPURI: "Dharampuri",
    DINDORI: "Dindori",
    ETAWA: "Etawa",
    GADARWARA: "Gadarwara",
    GARHA_BRAHMAN: "Garha Brahman",
    GARHAKOTA: "Garhakota",
    GAUTAMPURA: "Gautampura",
    GHANSOR: "Ghansor",
    GOGAPUR: "Gogapur",
    GOHADI: "Gohadi",
    GOVINDGARH: "Govindgarh",
    GUNA: "Guna",
    GURH: "Gurh",
    GWALIOR: "Gwalior",
    HARDA: "Harda",
    HARDA_KHAS: "Harda Khas",
    HARPALPUR: "Harpalpur",
    HARRAI: "Harrai",
    HARSUD: "Harsud",
    HATOD: "Hatod",
    HATTA: "Hatta",
    HINDORIA: "Hindoria",
    HOSHANGABAD: "Hoshangabad",
    IAWAR: "Iawar",
    ICHHAWAR: "Ichhawar",
    IKLEHRA: "Iklehra",
    INDORE: "Indore",
    ISAGARH: "Isagarh",
    ITARSI: "Itarsi",
    JABALPUR: "Jabalpur",
    JAISINGHNAGAR: "Jaisinghnagar",
    JAITHARI: "Jaithari",
    JAMAI: "Jamai",
    JAORA: "Jaora",
    JATARA: "Jatara",
    JAWAD: "Jawad",
    JHABUA: "Jhabua",
    JIRAN: "Jiran",
    JOBAT: "Jobat",
    KAILARAS: "Kailaras",
    KAIMORI: "Kaimori",
    KANNOD: "Kannod",
    KARELI: "Kareli",
    KARERA: "Karera",
    KARRAPUR: "Karrapur",
    KASRAWAD: "Kasrawad",
    KATANGI: "Katangi",
    KATNI: "Katni",
    KHACHROD: "Khachrod",
    KHAILAR: "Khailar",
    KHAJURAHO_GROUP_OF_MONUMENTS: "Khajuraho Group of Monuments",
    KHAMARIA: "Khamaria",
    KHANDWA: "Khandwa",
    KHANDWA_DISTRICT: "Khandwa district",
    KHARGAPUR: "Khargapur",
    KHARGONE: "Khargone",
    KHATEGAON: "Khategaon",
    KHILCHIPUR: "Khilchipur",
    KHIRKIYAN: "Khirkiyan",
    KHUJNER: "Khujner",
    KHURAI: "Khurai",
    KOLARAS: "Kolaras",
    KORWAI: "Korwai",
    KOTAR: "Kotar",
    KOTHI: "Kothi",
    KOTMA: "Kotma",
    KOTWA: "Kotwa",
    KUKSHI: "Kukshi",
    KUMBHRAJ: "Kumbhraj",
    LAHAR: "Lahar",
    LAKHNADON: "Lakhnadon",
    LETERI: "Leteri",
    LODHIKHEDA: "Lodhikheda",
    MACHALPUR: "Machalpur",
    MADHOGARH: "Madhogarh",
    MAHESHWAR: "Maheshwar",
    MAHGAWAN: "Mahgawan",
    MAIHAR: "Maihar",
    MAJHOLI: "Majholi",
    MAKSI: "Maksi",
    MALHARGARH: "Malhargarh",
    MANASA: "Manasa",
    MANAWAR: "Manawar",
    MANDIDEEP: "Mandideep",
    MANDLA: "Mandla",
    MANDLESHWAR: "Mandleshwar",
    MANDSAUR: "Mandsaur",
    MANGAWAN: "Mangawan",
    MANPUR: "Manpur",
    MAU: "Mau",
    MAUGANJ: "Mauganj",
    MIHONA: "Mihona",
    MOHGAON: "Mohgaon",
    MORAR: "Morar",
    MORENA: "Morena",
    MULTAI: "Multai",
    MUNDI: "Mundi",
    MUNGAOLI: "Mungaoli",
    MURWARA: "Murwara",
    NAGDA: "Nagda",
    NAGOD: "Nagod",
    NAIGARHI: "Naigarhi",
    NAINPUR: "Nainpur",
    NAMLI: "Namli",
    NARAINI: "Naraini",
    NARAYANGARH: "Narayangarh",
    NARSIMHAPUR: "Narsimhapur",
    NARSINGHGARH: "Narsinghgarh",
    NARWAR: "Narwar",
    NASRULLAHGANJ: "Nasrullahganj",
    NEEMUCH: "Neemuch",
    NEPANAGAR: "Nepanagar",
    ORCHHA: "Orchha",
    PACHMARHI: "Pachmarhi",
    PALERA: "Palera",
    PALI: "Pali",
    PANAGAR: "Panagar",
    PANARA: "Panara",
    PANDHANA: "Pandhana",
    PANDHURNA: "Pandhurna",
    PANNA: "Panna",
    PANSEMAL: "Pansemal",
    PARASIA: "Parasia",
    PATAN: "Patan",
    PATHARIA: "Patharia",
    PAWAI: "Pawai",
    PETLAWAD: "Petlawad",
    PIPLODA: "Piploda",
    PITHAMPUR: "Pithampur",
    PORSA: "Porsa",
    PUNASA: "Punasa",
    RAGHOGARH: "Raghogarh",
    RAHATGARH: "Rahatgarh",
    RAISEN: "Raisen",
    RAJGARH: "Rajgarh",
    RAJNAGAR: "Rajnagar",
    RAJPUR: "Rajpur",
    RAMPURA: "Rampura",
    RANAPUR: "Ranapur",
    RATANGARH: "Ratangarh",
    RATLAM: "Ratlam",
    REHLI: "Rehli",
    REHTI: "Rehti",
    REWA: "Rewa",
    SABALGARH: "Sabalgarh",
    SAGAR: "Sagar",
    SAILANA: "Sailana",
    SANAWAD: "Sanawad",
    SANCHI: "Sanchi",
    SANWER: "Sanwer",
    SARANGPUR: "Sarangpur",
    SATNA: "Satna",
    SATWAS: "Satwas",
    SAUGOR: "Saugor",
    SAUSAR: "Sausar",
    SEHORE: "Sehore",
    SENDHWA: "Sendhwa",
    SEONDHA: "Seondha",
    SEONI: "Seoni",
    SEONI_MALWA: "Seoni Malwa",
    SHAHDOL: "Shahdol",
    SHAHGARH: "Shahgarh",
    SHAHPUR: "Shahpur",
    SHAHPURA: "Shahpura",
    SHAJAPUR: "Shajapur",
    SHAMGARH: "Shamgarh",
    SHEOPUR: "Sheopur",
    SHIVPURI: "Shivpuri",
    SHUJALPUR: "Shujalpur",
    SIDHI: "Sidhi",
    SIHORA: "Sihora",
    SIMARIA: "Simaria",
    SINGOLI: "Singoli",
    SINGRAULI: "Singrauli",
    SIRMAUR: "Sirmaur",
    SIRONJ: "Sironj",
    SITAMAU: "Sitamau",
    SOHAGI: "Sohagi",
    SOHAGPUR: "Sohagpur",
    SULTANPUR: "Sultanpur",
    SUSNER: "Susner",
    TAL: "Tal",
    TALEN: "Talen",
    TARANA: "Tarana",
    TEKANPUR: "Tekanpur",
    TENDUKHEDA: "Tendukheda",
    TEONTHAR: "Teonthar",
    THANDLA: "Thandla",
    TIKAMGARH: "Tikamgarh",
    TIRODI: "Tirodi",
    UDAIPURA: "Udaipura",
    UJJAIN: "Ujjain",
    UKWA: "Ukwa",
    UMARIA: "Umaria",
    UMARIA_DISTRICT: "Umaria District",
    UMRI: "Umri",
    UNHEL: "Unhel",
    VIDISHA: "Vidisha",
    WARASEONI: "Waraseoni",
  },
  MZ:{
    AIZAWL: "Aizawl",
    CHAMPHAI: "Champhai",
    DARLAWN: "Darlawn",
    KHAWHAI: "Khawhai",
    KOLASIB: "Kolasib",
    KOLASIB_DISTRICT: "Kolasib district",
    LAWNGTLAI: "Lawngtlai",
    LUNGLEI: "Lunglei",
    MAMIT: "Mamit",
    NORTH_VANLAIPHAI: "North Vanlaiphai",
    SAIHA: "Saiha",
    SAIRANG: "Sairang",
    SAITLAW: "Saitlaw",
    SERCHHIP: "Serchhip",
    THENZAWL: "Thenzawl",
  },
  NL: {
    DIMAPUR: "Dimapur",
    KOHIMA: "Kohima",
    MOKOKCHUNG: "Mokokchung",
    MON: "Mon",
    PEREN: "Peren",
    PHEK: "Phek",
    TUENSANG: "Tuensang",
    TUENSANG_DISTRICT: "Tuensang District",
    WOKHA: "Wokha",
    ZUNHEBOTO: "Zunheboto",
  },
  OR: {
    ANGUL: "Angul",
    ANGUL_DISTRICT: "Angul District",
    ASIKA: "Asika",
    ATHAGARH: "Athagarh",
    BADA_BARABIL: "Bada Barabil",
    BALANGIR: "Balangir",
    BALASORE: "Balasore",
    BALESHWAR: "Baleshwar",
    BALIMILA: "Balimila",
    BALUGAON: "Balugaon",
    BANAPUR: "Banapur",
    BANKI: "Banki",
    BANPOSH: "Banposh",
    BARAGARH: "Baragarh",
    BARBIL: "Barbil",
    BARGARH: "Bargarh",
    BARPALI: "Barpali",
    BASUDEBPUR: "Basudebpur",
    BAUD: "Baud",
    BAUDH: "Baudh",
    BELAGUNTHA: "Belaguntha",
    BHADRAK: "Bhadrak",
    BHADRAKH: "Bhadrakh",
    BHANJANAGAR: "Bhanjanagar",
    BHAWANIPATNA: "Bhawanipatna",
    BHUBAN: "Bhuban",
    BHUBANESHWAR: "Bhubaneshwar",
    BINKA: "Binka",
    BIRMITRAPUR: "Birmitrapur",
    BOLANIKHODAN: "Bolanikhodan",
    BRAHMAPUR: "Brahmapur",
    BRAJARAJNAGAR: "Brajarajnagar",
    BUGUDA: "Buguda",
    BURLA: "Burla",
    CHAMPUA: "Champua",
    CHANDBALI: "Chandbali",
    CHATRAPUR: "Chatrapur",
    CHIKITIGARH: "Chikitigarh",
    CHITTARKONDA: "Chittarkonda",
    CUTTACK: "Cuttack",
    DAITARI: "Daitari",
    DEOGARH: "Deogarh",
    DHENKANAL: "Dhenkanal",
    DIGAPAHANDI: "Digapahandi",
    GAJAPATI: "Gajapati",
    GANJAM: "Ganjam",
    GOPALPUR: "Gopalpur",
    GUDARI: "Gudari",
    GUNUPUR: "Gunupur",
    HINJILIKATU: "Hinjilikatu",
    HIRAKUD: "Hirakud",
    JAGATSINGHAPUR: "Jagatsinghapur",
    JAGATSINGHPUR: "Jagatsinghpur",
    JAJPUR: "Jajpur",
    JALESHWAR: "Jaleshwar",
    JATANI: "Jatani",
    JEYPORE: "Jeypore",
    JHARSUGUDA: "Jharsuguda",
    JHARSUGUDA_DISTRICT: "Jharsuguda District",
    KAINTRAGARH: "Kaintragarh",
    KALAHANDI: "Kalahandi",
    KAMAKHYANAGAR: "Kamakhyanagar",
    KANDHAMAL: "Kandhamal",
    KANTABANJI: "Kantabanji",
    KANTILO: "Kantilo",
    KENDRAPARA: "Kendrapara",
    KENDRAPARHA: "Kendraparha",
    KENDUJHAR: "Kendujhar",
    KESINGA: "Kesinga",
    KHALLIKOT: "Khallikot",
    KHARHIAL: "Kharhial",
    KHORDHA: "Khordha",
    KHURDA: "Khurda",
    KIRI_BURU: "Kiri Buru",
    KODALA: "Kodala",
    KONARKA: "Konarka",
    KORAPUT: "Koraput",
    KUCHAIBURI: "Kuchaiburi",
    KUCHINDA: "Kuchinda",
    MALAKANAGIRI: "Malakanagiri",
    MALKANGIRI: "Malkangiri",
    MAYURBHANJ: "Mayurbhanj",
    NABARANGPUR: "Nabarangpur",
    NAYAGARH: "Nayagarh",
    NAYAGARH_DISTRICT: "Nayagarh District",
    NILGIRI: "Nilgiri",
    NIMAPARHA: "Nimaparha",
    NOWRANGAPUR: "Nowrangapur",
    NUAPADA: "Nuapada",
    PADAMPUR: "Padampur",
    PARADIP_GARH: "Paradip Garh",
    PATAMUNDAI: "Patamundai",
    PATNAGARH: "Patnagarh",
    PHULBANI: "Phulbani",
    PIPILI: "Pipili",
    POLASARA: "Polasara",
    PURI: "Puri",
    PURUSHOTTAMPUR: "Purushottampur",
    RAMBHA: "Rambha",
    RAURKELA: "Raurkela",
    RAYAGADA: "Rayagada",
    REMUNA: "Remuna",
    RENGALI: "Rengali",
    SAMBALPUR: "Sambalpur",
    SONEPUR: "Sonepur",
    SORADA: "Sorada",
    SORO: "Soro",
    SUBARNAPUR: "Subarnapur",
    SUNDARGARH: "Sundargarh",
    TALCHER: "Talcher",
    TARABHA: "Tarabha",
    TITLAGARH: "Titlagarh",
    UDAYAGIRI: "Udayagiri",
  },
  PB: {
    ABOHAR: "Abohar",
    ADAMPUR: "Adampur",
    AJITGARH: "Ajitgarh",
    AJNALA: "Ajnala",
    AKALGARH: "Akalgarh",
    ALAWALPUR: "Alawalpur",
    AMLOH: "Amloh",
    AMRITSAR: "Amritsar",
    ANANDPUR_SAHIB: "Anandpur Sahib",
    BADHNI_KALAN: "Badhni Kalan",
    BAGHA_PURANA: "Bagha Purana",
    BAKLOH: "Bakloh",
    BALACHOR: "Balachor",
    BANGA: "Banga",
    BANUR: "Banur",
    BARNALA: "Barnala",
    BATALA: "Batala",
    BEGOWAL: "Begowal",
    BHADAUR: "Bhadaur",
    BHATINDA: "Bhatinda",
    BHAWANIGARH: "Bhawanigarh",
    BHIKHI: "Bhikhi",
    BHOGPUR: "Bhogpur",
    BHOLATH: "Bholath",
    BUDHLADA: "Budhlada",
    CHIMA: "Chima",
    DASUYA: "Dasuya",
    DERA_BABA_NANAK: "Dera Baba Nanak",
    DERA_BASSI: "Dera Bassi",
    DHANAULA: "Dhanaula",
    DHARIWAL: "Dhariwal",
    DHILWAN: "Dhilwan",
    DHUDI: "Dhudi",
    DHURI: "Dhuri",
    DINA_NAGAR: "Dina Nagar",
    DIRBA: "Dirba",
    DORAHA: "Doraha",
    FARIDKOT: "Faridkot",
    FATEHGARH_CHURIAN: "Fatehgarh Churian",
    FATEHGARH_SAHIB: "Fatehgarh Sahib",
    FAZILKA: "Fazilka",
    FIROZPUR: "Firozpur",
    FIROZPUR_DISTRICT: "Firozpur District",
    GARDHIWALA: "Gardhiwala",
    GARHSHANKAR: "Garhshankar",
    GHANAUR: "Ghanaur",
    GIDDARBAHA: "Giddarbaha",
    GURDASPUR: "Gurdaspur",
    GURU_HAR_SAHAI: "Guru Har Sahai",
    HAJIPUR: "Hajipur",
    HARIANA: "Hariana",
    HOSHIARPUR: "Hoshiarpur",
    ISHANPUR: "Ishanpur",
    JAGRAON: "Jagraon",
    JAITO: "Jaito",
    JALALABAD: "Jalalabad",
    JALANDHAR: "Jalandhar",
    JANDIALA: "Jandiala",
    JANDIALA_GURU: "Jandiala Guru",
    KALANAUR: "Kalanaur",
    KAPURTHALA: "Kapurthala",
    KARTARPUR: "Kartarpur",
    KHAMANON: "Khamanon",
    KHANNA: "Khanna",
    KHARAR: "Kharar",
    KHEMKARAN: "Khemkaran",
    KOT_ISA_KHAN: "Kot Isa Khan",
    KOTKAPURA: "Kotkapura",
    LAUNGOWAL: "Laungowal",
    LUDHIANA: "Ludhiana",
    MACHHIWARA: "Machhiwara",
    MAJITHA: "Majitha",
    MAKHU: "Makhu",
    MALAUT: "Malaut",
    MALERKOTLA: "Malerkotla",
    MANSA: "Mansa",
    MAUR_MANDI: "Maur Mandi",
    MOGA: "Moga",
    MOHALI: "Mohali",
    MORINDA: "Morinda",
    MUKERIAN: "Mukerian",
    NABHA: "Nabha",
    NAKODAR: "Nakodar",
    NANGAL: "Nangal",
    NAWANSHAHR: "Nawanshahr",
    NURMAHAL: "Nurmahal",
    NURPUR_KALAN: "Nurpur Kalan",
    PATHANKOT: "Pathankot",
    PATIALA: "Patiala",
    PATTI: "Patti",
    PHAGWARA: "Phagwara",
    PHILLAUR: "Phillaur",
    QADIAN: "Qadian",
    RAHON: "Rahon",
    RAIKOT: "Raikot",
    RAJASANSI: "Rajasansi",
    RAJPURA: "Rajpura",
    RAM_DAS: "Ram Das",
    RAMPURA: "Rampura",
    RUPNAGAR: "Rupnagar",
    SAMRALA: "Samrala",
    SANAUR: "Sanaur",
    SANGRUR: "Sangrur",
    SARDULGARH: "Sardulgarh",
    SHAHID_BHAGAT_SINGH_NAGAR: "Shahid Bhagat Singh Nagar",
    SHAHKOT: "Shahkot",
    SHAM_CHURASI: "Sham Churasi",
    SIRHIND_FATEGARH: "Sirhind-Fategarh",
    SRI_MUKTSAR_SAHIB: "Sri Muktsar Sahib",
    SULTANPUR_LODHI: "Sultanpur Lodhi",
    SUNAM: "Sunam",
    TALWANDI_BHAI: "Talwandi Bhai",
    TALWARA: "Talwara",
    TARN_TARAN_SAHIB: "Tarn Taran Sahib",
    ZIRA: "Zira",
  },
  PY:{
    KARAIKAL: "Karaikal",
    MAHE: "Mahe",
    PUDUCHERRY: "Puducherry",
    YANAM: "Yanam",
  },
  RJ:{
    ABHANERI: "Abhaneri",
    ABU: "Abu",
    ABU_ROAD: "Abu Road",
    AJMER: "Ajmer",
    AKLERA: "Aklera",
    ALWAR: "Alwar",
    AMET: "Amet",
    ANTA: "Anta",
    ANUPGARH: "Anupgarh",
    ASIND: "Asind",
    BAGAR: "Bagar",
    BAKANI: "Bakani",
    BALI: "Bali",
    BALOTRA: "Balotra",
    BANDIKUI: "Bandikui",
    BANSWARA: "Banswara",
    BARAN: "Baran",
    BARI: "Bari",
    BARI_SADRI: "Bari Sadri",
    BARMER: "Barmer",
    BASI: "Basi",
    BASNI: "Basni",
    BASWA: "Baswa",
    BAYANA: "Bayana",
    BEAWAR: "Beawar",
    BEGUN: "Begun",
    BEHROR: "Behror",
    BHADASAR: "Bhadasar",
    BHADRA: "Bhadra",
    BHARATPUR: "Bharatpur",
    BHASAWAR: "Bhasawar",
    BHILWARA: "Bhilwara",
    BHINDAR: "Bhindar",
    BHINMAL: "Bhinmal",
    BHIWADI: "Bhiwadi",
    BHUMA: "Bhuma",
    BIKANER: "Bikaner",
    BILARA: "Bilara",
    BISSAU: "Bissau",
    BORKHERA: "Borkhera",
    BUNDI: "Bundi",
    CHAKSU: "Chaksu",
    CHECHAT: "Chechat",
    CHHABRA: "Chhabra",
    CHHAPAR: "Chhapar",
    CHHOTI_SADRI: "Chhoti Sadri",
    CHIDAWA: "Chidawa",
    CHITTAURGARH: "Chittaurgarh",
    CHURU: "Churu",
    DARIBA: "Dariba",
    DAUSA: "Dausa",
    DEOLI: "Deoli",
    DESHNOKE: "Deshnoke",
    DEVGARH: "Devgarh",
    DHAULPUR: "Dhaulpur",
    DIDWANA: "Didwana",
    DIG: "Dig",
    DUNGARPUR: "Dungarpur",
    FATEHPUR: "Fatehpur",
    GALIAKOT: "Galiakot",
    GANGANAGAR: "Ganganagar",
    GANGAPUR: "Gangapur",
    GOVINDGARH: "Govindgarh",
    GULABPURA: "Gulabpura",
    HANUMANGARH: "Hanumangarh",
    HINDAUN: "Hindaun",
    JAHAZPUR: "Jahazpur",
    JAIPUR: "Jaipur",
    JAISALMER: "Jaisalmer",
    JAITARAN: "Jaitaran",
    JALOR: "Jalor",
    JALORE: "Jalore",
    JHALAWAR: "Jhalawar",
    JHALRAPATAN: "Jhalrapatan",
    JHUNJHUNUN: "Jhunjhunun",
    JOBNER: "Jobner",
    JODHPUR: "Jodhpur",
    KAMAN: "Kaman",
    KANOR: "Kanor",
    KAPREN: "Kapren",
    KARANPUR: "Karanpur",
    KARAULI: "Karauli",
    KEKRI: "Kekri",
    KESHORAI_PATAN: "Keshorai Patan",
    KHANDELA: "Khandela",
    KHANPUR: "Khanpur",
    KHETRI: "Khetri",
    KISHANGARH: "Kishangarh",
    KOTA: "Kota",
    KOTPUTLI: "Kotputli",
    KUCHAMAN: "Kuchaman",
    KUCHERA: "Kuchera",
    KUMHER: "Kumher",
    KUSHALGARH: "Kushalgarh",
    LACHHMANGARH_SIKAR: "Lachhmangarh Sikar",
    LADNUN: "Ladnun",
    LAKHERI: "Lakheri",
    LALSOT: "Lalsot",
    LOSAL: "Losal",
    MAHWAH: "Mahwah",
    MAKRANA: "Makrana",
    MALPURA: "Malpura",
    MANDAL: "Mandal",
    MANDALGARH: "Mandalgarh",
    MANDAWAR: "Mandawar",
    MANGROL: "Mangrol",
    MANOHAR_THANA: "Manohar Thana",
    MANOHARPUR: "Manoharpur",
    MEETHARI_MARWAR: "Meethari Marwar",
    MERTA: "Merta",
    MUNDWA: "Mundwa",
    NADBAI: "Nadbai",
    NAGAR: "Nagar",
    NAGAUR: "Nagaur",
    NAINWA: "Nainwa",
    NAPASAR: "Napasar",
    NARAINA: "Naraina",
    NASIRABAD: "Nasirabad",
    NATHDWARA: "Nathdwara",
    NAWA: "Nawa",
    NAWALGARH: "Nawalgarh",
    NEEM_KA_THANA: "Neem ka Thana",
    NIMAJ: "Nimaj",
    NIMBAHERA: "Nimbahera",
    NIWAI: "Niwai",
    NOHAR: "Nohar",
    NOKHA: "Nokha",
    PADAMPUR: "Padampur",
    PALI: "Pali",
    PARTAPUR: "Partapur",
    PARVATSAR: "Parvatsar",
    PHALODI: "Phalodi",
    PHULERA: "Phulera",
    PILANI: "Pilani",
    PILIBANGAN: "Pilibangan",
    PINDWARA: "Pindwara",
    PIPAR: "Pipar",
    PIRAWA: "Pirawa",
    POKARAN: "Pokaran",
    PRATAPGARH: "Pratapgarh",
    PUSHKAR: "Pushkar",
    RAIPUR: "Raipur",
    RAISINGHNAGAR: "Raisinghnagar",
    RAJAKHERA: "Rajakhera",
    RAJALDESAR: "Rajaldesar",
    RAJGARH: "Rajgarh",
    RAJSAMAND: "Rajsamand",
    RAMGANJ_MANDI: "Ramganj Mandi",
    RAMGARH: "Ramgarh",
    RANI: "Rani",
    RATANGARH: "Ratangarh",
    RAWATBHATA: "Rawatbhata",
    RAWATSAR: "Rawatsar",
    RINGAS: "Ringas",
    SADRI: "Sadri",
    SALUMBAR: "Salumbar",
    SAMBHAR: "Sambhar",
    SAMDARI: "Samdari",
    SANCHOR: "Sanchor",
    SANGARIA: "Sangaria",
    SANGOD: "Sangod",
    SARDARSHAHR: "Sardarshahr",
    SARWAR: "Sarwar",
    SAWAI_MADHOPUR: "Sawai Madhopur",
    SHAHPURA: "Shahpura",
    SHEOGANJ: "Sheoganj",
    SIKAR: "Sikar",
    SIROHI: "Sirohi",
    SIWANA: "Siwana",
    SOJAT: "Sojat",
    SRI_DUNGARGARH: "Sri Dungargarh",
    SRI_MADHOPUR: "Sri Madhopur",
    SUJANGARH: "Sujangarh",
    SUKET: "Suket",
    SUNEL: "Sunel",
    SURAJGARH: "Surajgarh",
    SURATGARH: "Suratgarh",
    TAKHATGARH: "Takhatgarh",
    TARANAGAR: "Taranagar",
    TIJARA: "Tijara",
    TODABHIM: "Todabhim",
    TODARAISINGH: "Todaraisingh",
    TONK: "Tonk",
    UDAIPUR: "Udaipur",
    UDPURA: "Udpura",
    UNIARA: "Uniara",
    WER: "Wer",
  },
  SK:{
    EAST_DISTRICT: "East District",
    GANGTOK: "Gangtok",
    GYALSHING: "Gyalshing",
    JORETHANG: "Jorethang",
    MANGAN: "Mangan",
    NAMCHI: "Namchi",
    NAYA_BAZAR: "Naya Bazar",
    NORTH_DISTRICT: "North District",
    RANGPO: "Rangpo",
    SINGTAM: "Singtam",
    SOUTH_DISTRICT: "South District",
    WEST_DISTRICT: "West District",
  },
  TG: {
    ADILABAD: "Adilabad",
    ALAMPUR: "Alampur",
    ANDOL: "Andol",
    ASIFABAD: "Asifabad",
    BALAPUR: "Balapur",
    BANSWADA: "Banswada",
    BELLAMPALLI: "Bellampalli",
    BHADRACHALAM: "Bhadrachalam",
    BHADRADRI_KOTHAGUDEM: "Bhadradri Kothagudem",
    BHAISA: "Bhaisa",
    BHONGIR: "Bhongir",
    BODHAN: "Bodhan",
    CHANDUR: "Chandur",
    CHATAKONDA: "Chatakonda",
    DASNAPUR: "Dasnapur",
    DEVARKONDA: "Devarkonda",
    DORNAKAL: "Dornakal",
    FARRUKHNAGAR: "Farrukhnagar",
    GADDI_ANNARAM: "Gaddi Annaram",
    GADWAL: "Gadwal",
    GHATKESAR: "Ghatkesar",
    GOPALUR: "Gopalur",
    GUDUR: "Gudur",
    HYDERABAD: "Hyderabad",
    JAGTIAL: "Jagtial",
    JANGAON: "Jangaon",
    JANGOAN: "Jangoan",
    JAYASHANKAR_BHUPALAPALLY: "Jayashankar Bhupalapally",
    JOGULAMBA_GADWAL: "Jogulamba Gadwal",
    KAGAZNAGAR: "Kagaznagar",
    KAMAREDDI: "Kamareddi",
    KAMAREDDY: "Kamareddy",
    KARIMNAGAR: "Karimnagar",
    KHAMMAM: "Khammam",
    KODAR: "Kodar",
    KORATLA: "Koratla",
    KOTHAPET: "Kothapet",
    KOTTAGUDEM: "Kottagudem",
    KOTTAPALLI: "Kottapalli",
    KUKATPALLI: "Kukatpalli",
    KYATHAMPALLE: "Kyathampalle",
    LAKSHETTIPET: "Lakshettipet",
    LAL_BAHADUR_NAGAR: "Lal Bahadur Nagar",
    MAHABUBABAD: "Mahabubabad",
    MAHBUBNAGAR: "Mahbubnagar",
    MALKAJGIRI: "Malkajgiri",
    MANCHERAL: "Mancheral",
    MANDAMARRI: "Mandamarri",
    MANTHANI: "Manthani",
    MANUGURU: "Manuguru",
    MEDAK: "Medak",
    MEDCHAL: "Medchal",
    MEDCHAL_MALKAJGIRI: "Medchal Malkajgiri",
    MIRIALGUDA: "Mirialguda",
    NAGAR_KARNUL: "Nagar Karnul",
    NALGONDA: "Nalgonda",
    NARAYANPET: "Narayanpet",
    NARSINGI: "Narsingi",
    NASPUR: "Naspur",
    NIRMAL: "Nirmal",
    NIZAMABAD: "Nizamabad",
    PALONCHA: "Paloncha",
    PALWANCHA: "Palwancha",
    PATANCHERU: "Patancheru",
    PEDDAPALLI: "Peddapalli",
    QUTHBULLAPUR: "Quthbullapur",
    RAJANNA_SIRCILLA: "Rajanna Sircilla",
    RAMAGUNDAM: "Ramagundam",
    RAMGUNDAM: "Ramgundam",
    RANGAREDDI: "Rangareddi",
    SADASEOPET: "Sadaseopet",
    SANGAREDDI: "Sangareddi",
    SATHUPALLI: "Sathupalli",
    SECUNDERABAD: "Secunderabad",
    SERILINGAMPALLE: "Serilingampalle",
    SIDDIPET: "Siddipet",
    SINGAPUR: "Singapur",
    SIRPUR: "Sirpur",
    SIRSILLA: "Sirsilla",
    SRIRAMNAGAR: "Sriramnagar",
    SURIAPET: "Suriapet",
    TANDUR: "Tandur",
    UPPAL_KALAN: "Uppal Kalan",
    VEMALWADA: "Vemalwada",
    VIKARABAD: "Vikarabad",
    WANPARTI: "Wanparti",
    WARANGAL: "Warangal",
    YELLANDU: "Yellandu",
    ZAHIRABAD: "Zahirabad",
  },
  TN: {
    ABIRAMAM: "Abiramam",
    ADIRAMPATTINAM: "Adirampattinam",
    ADUTHURAI: "Aduthurai",
    ALAGAPURAM: "Alagapuram",
    ALANDUR: "Alandur",
    ALANGANALLUR: "Alanganallur",
    ALANGAYAM: "Alangayam",
    ALANGUDI: "Alangudi",
    ALANGULAM: "Alangulam",
    ALAPPAKKAM: "Alappakkam",
    ALWA_TIRUNAGARI: "Alwa Tirunagari",
    AMBASAMUDRAM: "Ambasamudram",
    AMBATTUR: "Ambattur",
    AMBUR: "Ambur",
    AMMAPETTAI: "Ammapettai",
    ANAMALAIS: "Anamalais",
    ANDIPPATTI: "Andippatti",
    ANNAMALAINAGAR: "Annamalainagar",
    ANNAVASAL: "Annavasal",
    ANNUR: "Annur",
    ANTHIYUR: "Anthiyur",
    ARAKKONAM: "Arakkonam",
    ARANTANGI: "Arantangi",
    ARCOT: "Arcot",
    ARIMALAM: "Arimalam",
    ARIYALUR: "Ariyalur",
    ARNI: "Arni",
    ARUMBAVUR: "Arumbavur",
    ARUMUGANERI: "Arumuganeri",
    ARUPPUKKOTTAI: "Aruppukkottai",
    ARUVANKAD: "Aruvankad",
    ATTAYYAMPATTI: "Attayyampatti",
    ATTUR: "Attur",
    AUROVILLE: "Auroville",
    AVADI: "Avadi",
    AVINASHI: "Avinashi",
    AYAKUDI: "Ayakudi",
    AYYAMPETTAI: "Ayyampettai",
    BELUR: "Belur",
    BHAVANI: "Bhavani",
    BODINAYAKKANUR: "Bodinayakkanur",
    CHENGAM: "Chengam",
    CHENNAI: "Chennai",
    CHENNIMALAI: "Chennimalai",
    CHETPUT: "Chetput",
    CHETTIPALAIYAM: "Chettipalaiyam",
    CHEYYAR: "Cheyyar",
    CHEYYUR: "Cheyyur",
    CHIDAMBARAM: "Chidambaram",
    CHINGLEPUT: "Chingleput",
    CHINNA_SALEM: "Chinna Salem",
    CHINNAMANUR: "Chinnamanur",
    CHINNASEKKADU: "Chinnasekkadu",
    CHOLAPURAM: "Cholapuram",
    COIMBATORE: "Coimbatore",
    COLACHEL: "Colachel",
    CUDDALORE: "Cuddalore",
    CUMBUM: "Cumbum",
    DENKANIKOTA: "Denkanikota",
    DESUR: "Desur",
    DEVADANAPPATTI: "Devadanappatti",
    DEVAKOTTAI: "Devakottai",
    DHALI: "Dhali",
    DHARAPURAM: "Dharapuram",
    DHARMAPURI: "Dharmapuri",
    DINDIGUL: "Dindigul",
    DUSI: "Dusi",
    ELAYIRAMPANNAI: "Elayirampannai",
    ELUMALAI: "Elumalai",
    ERAL: "Eral",
    ERANIEL: "Eraniel",
    ERODE: "Erode",
    ERUMAIPPATTI: "Erumaippatti",
    ETTAIYAPURAM: "Ettaiyapuram",
    GANDHI_NAGAR: "Gandhi Nagar",
    GANGAIKONDAN: "Gangaikondan",
    GANGAVALLI: "Gangavalli",
    GINGEE: "Gingee",
    GOBICHETTIPALAYAM: "Gobichettipalayam",
    GUDALUR: "Gudalur",
    GUDIYATHAM: "Gudiyatham",
    GUDUVANCHERI: "Guduvancheri",
    GUMMIDIPUNDI: "Gummidipundi",
    HARUR: "Harur",
    HOSUR: "Hosur",
    IDAPPADI: "Idappadi",
    ILAMPILLAI: "Ilampillai",
    ILUPPUR: "Iluppur",
    INJAMBAKKAM: "Injambakkam",
    IRUGUR: "Irugur",
    JALAKANDAPURAM: "Jalakandapuram",
    JALARPET: "Jalarpet",
    JAYAMKONDACHOLAPURAM: "Jayamkondacholapuram",
    KADAMBUR: "Kadambur",
    KADAYANALLUR: "Kadayanallur",
    KALAKKADU: "Kalakkadu",
    KALAVAI: "Kalavai",
    KALLAKKURICHCHI: "Kallakkurichchi",
    KALLIDAIKURICHI: "Kallidaikurichi",
    KALLUPATTI: "Kallupatti",
    KALUGUMALAI: "Kalugumalai",
    KAMUTHI: "Kamuthi",
    KANADUKATTAN: "Kanadukattan",
    KANCHEEPURAM: "Kancheepuram",
    KANCHIPURAM: "Kanchipuram",
    KANGAYAM: "Kangayam",
    KANNIYAKUMARI: "Kanniyakumari",
    KARAIKKUDI: "Karaikkudi",
    KARAMADAI: "Karamadai",
    KARAMBAKKUDI: "Karambakkudi",
    KARIAPATTI: "Kariapatti",
    KARUMBAKKAM: "Karumbakkam",
    KARUR: "Karur",
    KATPADI: "Katpadi",
    KATTIVAKKAM: "Kattivakkam",
    KATTUPPUTTUR: "Kattupputtur",
    KAVERIPATNAM: "Kaveripatnam",
    KAYALPATTINAM: "Kayalpattinam",
    KAYATTAR: "Kayattar",
    KEELAKARAI: "Keelakarai",
    KELAMANGALAM: "Kelamangalam",
    KIL_BHUVANAGIRI: "Kil Bhuvanagiri",
    KILVELUR: "Kilvelur",
    KIRANUR: "Kiranur",
    KODAIKANAL: "Kodaikanal",
    KODUMUDI: "Kodumudi",
    KOMBAI: "Kombai",
    KONGANAPURAM: "Konganapuram",
    KOOTHANALLUR: "Koothanallur",
    KORADACHCHERI: "Koradachcheri",
    KORAMPALLAM: "Korampallam",
    KOTAGIRI: "Kotagiri",
    KOTTAIYUR: "Kottaiyur",
    KOVILPATTI: "Kovilpatti",
    KRISHNAGIRI: "Krishnagiri",
    KULATTUR: "Kulattur",
    KULITTALAI: "Kulittalai",
    KUMARALINGAM: "Kumaralingam",
    KUMBAKONAM: "Kumbakonam",
    KUNNATTUR: "Kunnattur",
    KURINJIPPADI: "Kurinjippadi",
    KUTTALAM: "Kuttalam",
    KUZHITHURAI: "Kuzhithurai",
    LALGUDI: "Lalgudi",
    MADAMBAKKAM: "Madambakkam",
    MADIPAKKAM: "Madipakkam",
    MADUKKARAI: "Madukkarai",
    MADUKKUR: "Madukkur",
    MADURAI: "Madurai",
    MADURANTAKAM: "Madurantakam",
    MALLAPURAM: "Mallapuram",
    MALLASAMUDRAM: "Mallasamudram",
    MALLUR: "Mallur",
    MANALI: "Manali",
    MANALURPETTAI: "Manalurpettai",
    MANAMADURAI: "Manamadurai",
    MANAPPAKKAM: "Manappakkam",
    MANAPPARAI: "Manapparai",
    MANAVALAKURICHI: "Manavalakurichi",
    MANDAPAM: "Mandapam",
    MANGALAM: "Mangalam",
    MANNARGUDI: "Mannargudi",
    MARAKKANAM: "Marakkanam",
    MARANDAHALLI: "Marandahalli",
    MASINIGUDI: "Masinigudi",
    MATTUR: "Mattur",
    MAYILADUTHURAI: "Mayiladuthurai",
    MELUR: "Melur",
    METTUPPALAIYAM: "Mettuppalaiyam",
    METTUR: "Mettur",
    MINJUR: "Minjur",
    MOHANUR: "Mohanur",
    MUDUKULATTUR: "Mudukulattur",
    MULANUR: "Mulanur",
    MUSIRI: "Musiri",
    MUTTUPET: "Muttupet",
    NADUVATTAM: "Naduvattam",
    NAGAPATTINAM: "Nagapattinam",
    NAGERCOIL: "Nagercoil",
    NAMAGIRIPETTAI: "Namagiripettai",
    NAMAKKAL: "Namakkal",
    NAMBIYUR: "Nambiyur",
    NAMBUTALAI: "Nambutalai",
    NANDAMBAKKAM: "Nandambakkam",
    NANGAVALLI: "Nangavalli",
    NANGILICKONDAN: "Nangilickondan",
    NANGUNERI: "Nanguneri",
    NANNILAM: "Nannilam",
    NARAVARIKUPPAM: "Naravarikuppam",
    NATTAM: "Nattam",
    NATTARASANKOTTAI: "Nattarasankottai",
    NEEDAMANGALAM: "Needamangalam",
    NEELANKARAI: "Neelankarai",
    NEGAPATAM: "Negapatam",
    NELLIKKUPPAM: "Nellikkuppam",
    NILAKOTTAI: "Nilakottai",
    NILGIRIS: "Nilgiris",
    ODUGATTUR: "Odugattur",
    OMALUR: "Omalur",
    OOTY: "Ooty",
    PADMANABHAPURAM: "Padmanabhapuram",
    PALAKKODU: "Palakkodu",
    PALAMEDU: "Palamedu",
    PALANI: "Palani",
    PALAVAKKAM: "Palavakkam",
    PALLADAM: "Palladam",
    PALLAPPATTI: "Pallappatti",
    PALLATTUR: "Pallattur",
    PALLAVARAM: "Pallavaram",
    PALLIKONDAI: "Pallikondai",
    PALLIPATTU: "Pallipattu",
    PALLIPPATTI: "Pallippatti",
    PANRUTI: "Panruti",
    PAPANASAM: "Papanasam",
    PAPIREDDIPPATTI: "Papireddippatti",
    PAPPARAPPATTI: "Papparappatti",
    PARAMAGUDI: "Paramagudi",
    PATTUKKOTTAI: "Pattukkottai",
    PENNADAM: "Pennadam",
    PENNAGARAM: "Pennagaram",
    PENNATHUR: "Pennathur",
    PERAIYUR: "Peraiyur",
    PERAMBALUR: "Perambalur",
    PERANAMALLUR: "Peranamallur",
    PERANAMPATTU: "Peranampattu",
    PERAVURANI: "Peravurani",
    PERIYAKULAM: "Periyakulam",
    PERIYANAYAKKANPALAIYAM: "Periyanayakkanpalaiyam",
    PERIYANEGAMAM: "Periyanegamam",
    PERIYAPATTI: "Periyapatti",
    PERIYAPATTINAM: "Periyapattinam",
    PERUNDURAI: "Perundurai",
    PERUNGUDI: "Perungudi",
    PERUR: "Perur",
    POLLACHI: "Pollachi",
    POLUR: "Polur",
    PONNAMARAVATI: "Ponnamaravati",
    PONNERI: "Ponneri",
    POONAMALLE: "Poonamalle",
    PORUR: "Porur",
    PUDUKKOTTAI: "Pudukkottai",
    PUDUPPATTI: "Puduppatti",
    PUDUR: "Pudur",
    PUDUVAYAL: "Puduvayal",
    PULIYANGUDI: "Puliyangudi",
    PULIYUR: "Puliyur",
    PULLAMBADI: "Pullambadi",
    PUNJAI_PULIYAMPATTI: "Punjai Puliyampatti",
    RAJAPALAIYAM: "Rajapalaiyam",
    RAMANATHAPURAM: "Ramanathapuram",
    RAMESWARAM: "Rameswaram",
    RASIPURAM: "Rasipuram",
    SAINT_THOMAS_MOUNT: "Saint Thomas Mount",
    SALEM: "Salem",
    SATHANKULAM: "Sathankulam",
    SATHYAMANGALAM: "Sathyamangalam",
    SATTUR: "Sattur",
    SAYALKUDI: "Sayalkudi",
    SEVEN_PAGODAS: "Seven Pagodas",
    SHOLINGHUR: "Sholinghur",
    SINGANALLUR: "Singanallur",
    SINGAPPERUMALKOVIL: "Singapperumalkovil",
    SIRKAZHI: "Sirkazhi",
    SIRUMUGAI: "Sirumugai",
    SIVAGANGA: "Sivaganga",
    SIVAGIRI: "Sivagiri",
    SIVAKASI: "Sivakasi",
    SRIMUSHNAM: "Srimushnam",
    SRIPERUMBUDUR: "Sriperumbudur",
    SRIVAIKUNTAM: "Srivaikuntam",
    SRIVILLIPUTHUR: "Srivilliputhur",
    SUCHINDRAM: "Suchindram",
    SULUR: "Sulur",
    SURANDAI: "Surandai",
    SWAMIMALAI: "Swamimalai",
    TAMBARAM: "Tambaram",
    TANJORE: "Tanjore",
    TARAMANGALAM: "Taramangalam",
    TATTAYYANGARPETTAI: "Tattayyangarpettai",
    THANJAVUR: "Thanjavur",
    THARANGAMBADI: "Tharangambadi",
    THENI: "Theni",
    THENKASI: "Thenkasi",
    THIRUKATTUPALLI: "Thirukattupalli",
    THIRUTHANI: "Thiruthani",
    THIRUVAIYARU: "Thiruvaiyaru",
    THIRUVALLUR: "Thiruvallur",
    THIRUVARUR: "Thiruvarur",
    THIRUVIDAIMARUTHUR: "Thiruvidaimaruthur",
    THOOTHUKUDI: "Thoothukudi",
    TINDIVANAM: "Tindivanam",
    TINNANUR: "Tinnanur",
    TIRUCHCHENDUR: "Tiruchchendur",
    TIRUCHENGODE: "Tiruchengode",
    TIRUCHIRAPPALLI: "Tiruchirappalli",
    TIRUKKOYILUR: "Tirukkoyilur",
    TIRUMULLAIVASAL: "Tirumullaivasal",
    TIRUNELVELI: "Tirunelveli",
    TIRUNELVELI_KATTABO: "Tirunelveli Kattabo",
    TIRUPPALAIKUDI: "Tiruppalaikudi",
    TIRUPPARANGUNRAM: "Tirupparangunram",
    TIRUPPUR: "Tiruppur",
    TIRUPPUVANAM: "Tiruppuvanam",
    TIRUTTANGAL: "Tiruttangal",
    TIRUVANNAMALAI: "Tiruvannamalai",
    TIRUVOTTIYUR: "Tiruvottiyur",
    TISAIYANVILAI: "Tisaiyanvilai",
    TONDI: "Tondi",
    TURAIYUR: "Turaiyur",
    UDANGUDI: "Udangudi",
    UDUMALAIPPETTAI: "Udumalaippettai",
    UPPILIYAPURAM: "Uppiliyapuram",
    USILAMPATTI: "Usilampatti",
    UTTAMAPALAIYAM: "Uttamapalaiyam",
    UTTIRAMERUR: "Uttiramerur",
    UTTUKKULI: "Uttukkuli",
    VSK_VALASAI_DINDIGUL_DIST: "V.S.K.Valasai (Dindigul-Dist.)",
    VADAKKU_VALLIYUR: "Vadakku Valliyur",
    VADAKKU_VIRAVANALLUR: "Vadakku Viravanallur",
    VADAMADURAI: "Vadamadurai",
    VADIPPATTI: "Vadippatti",
    VALANGAIMAN: "Valangaiman",
    VALAVANUR: "Valavanur",
    VALLAM: "Vallam",
    VALPARAI: "Valparai",
    VANDALUR: "Vandalur",
    VANDAVASI: "Vandavasi",
    VANIYAMBADI: "Vaniyambadi",
    VASUDEVANALLUR: "Vasudevanallur",
    VATTALKUNDU: "Vattalkundu",
    VEDARANIYAM: "Vedaraniyam",
    VEDASANDUR: "Vedasandur",
    VELANKANNI: "Velankanni",
    VELLANUR: "Vellanur",
    VELLORE: "Vellore",
    VELUR: "Velur",
    VENGAVASAL: "Vengavasal",
    VETTAIKKARANPUDUR: "Vettaikkaranpudur",
    VETTAVALAM: "Vettavalam",
    VIJAYAPURI: "Vijayapuri",
    VIKRAVANDI: "Vikravandi",
    VILATTIKULAM: "Vilattikulam",
    VILLUPURAM: "Villupuram",
    VIRAGANUR: "Viraganur",
    VIRUDHUNAGAR: "Virudhunagar",
    VRIDDHACHALAM: "Vriddhachalam",
    WALAJAPET: "Walajapet",
    WALLAJAHBAD: "Wallajahbad",
    WELLINGTON: "Wellington",
  },
  TR: {
    AGARTALA: "Agartala",
    AMARPUR: "Amarpur",
    AMBASA: "Ambasa",
    BARJALA: "Barjala",
    BELONIA: "Belonia",
    DHALAI: "Dhalai",
    DHARMANAGAR: "Dharmanagar",
    GOMATI: "Gomati",
    KAILASHAHAR: "Kailashahar",
    KAMALPUR: "Kamalpur",
    KHOWAI: "Khowai",
    NORTH_TRIPURA: "North Tripura",
    RANIR_BAZAR: "Ranir Bazar",
    SABRUM: "Sabrum",
    SONAMURA: "Sonamura",
    SOUTH_TRIPURA: "South Tripura",
    UDAIPUR: "Udaipur",
    UNAKOTI: "Unakoti",
    WEST_TRIPURA: "West Tripura",
  },
  UP: {
    ACHHNERA: "Achhnera",
    AFZALGARH: "Afzalgarh",
    AGRA: "Agra",
    AHRAURA: "Ahraura",
    AIDALPUR: "Aidalpur",
    AIRWA: "Airwa",
    AKBARPUR: "Akbarpur",
    AKOLA: "Akola",
    ALIGANJ: "Aliganj",
    ALIGARH: "Aligarh",
    ALLAHABAD: "Allahabad",
    ALLAHGANJ: "Allahganj",
    AMANPUR: "Amanpur",
    AMAULI: "Amauli",
    AMBAHTA: "Ambahta",
    AMBEDKAR_NAGAR: "Ambedkar Nagar",
    AMETHI: "Amethi",
    AMROHA: "Amroha",
    ANANDNAGAR: "Anandnagar",
    ANTU: "Antu",
    ANUPSHAHR: "Anupshahr",
    AONLA: "Aonla",
    ARAUL: "Araul",
    ASALATGANJ: "Asalatganj",
    ATARRA: "Atarra",
    ATRAULI: "Atrauli",
    ATRAULIA: "Atraulia",
    AURAIYA: "Auraiya",
    AURAS: "Auras",
    AYODHYA: "Ayodhya",
    AZAMGARH: "Azamgarh",
    AZIZPUR: "Azizpur",
    BABERU: "Baberu",
    BABINA: "Babina",
    BABRALA: "Babrala",
    BABUGARH: "Babugarh",
    BACHHRAON: "Bachhraon",
    BACHHRAWAN: "Bachhrawan",
    BAGHPAT: "Baghpat",
    BAGHRA: "Baghra",
    BAH: "Bah",
    BAHERI: "Baheri",
    BAHJOI: "Bahjoi",
    BAHRAICH: "Bahraich",
    BAHRAIGH: "Bahraigh",
    BAHSUMA: "Bahsuma",
    BAHUA: "Bahua",
    BAJNA: "Bajna",
    BAKEWAR: "Bakewar",
    BAKSAR: "Baksar",
    BALAMAU: "Balamau",
    BALDEO: "Baldeo",
    BALDEV: "Baldev",
    BALLIA: "Ballia",
    BALRAMPUR: "Balrampur",
    BANAT: "Banat",
    BANBASA: "Banbasa",
    BANDA: "Banda",
    BANGARMAU: "Bangarmau",
    BANSDIH: "Bansdih",
    BANSGAON: "Bansgaon",
    BANSI: "Bansi",
    BANTHRA: "Banthra",
    BARA_BANKI: "Bara Banki",
    BARAGAON: "Baragaon",
    BARAUT: "Baraut",
    BAREILLY: "Bareilly",
    BARHALGANJ: "Barhalganj",
    BARKHERA: "Barkhera",
    BARKHERA_KALAN: "Barkhera Kalan",
    BAROKHAR: "Barokhar",
    BARSANA: "Barsana",
    BARWAR_LAKHIMPUR_KHERI: "Barwar (Lakhimpur Kheri)",
    BASTI: "Basti",
    BEHAT: "Behat",
    BELA: "Bela",
    BELTHARA: "Belthara",
    BENIGANJ: "Beniganj",
    BESWAN: "Beswan",
    BEWAR: "Bewar",
    BHADARSA: "Bhadarsa",
    BHADOHI: "Bhadohi",
    BHAGWANTNAGAR: "Bhagwantnagar",
    BHARATPURA: "Bharatpura",
    BHARGAIN: "Bhargain",
    BHARTHANA: "Bharthana",
    BHARWARI: "Bharwari",
    BHAUPUR: "Bhaupur",
    BHIMTAL: "Bhimtal",
    BHINGA: "Bhinga",
    BHOGNIPUR: "Bhognipur",
    BHONGAON: "Bhongaon",
    BIDHNU: "Bidhnu",
    BIDHUNA: "Bidhuna",
    BIGHAPUR: "Bighapur",
    BIGHAPUR_KHURD: "Bighapur Khurd",
    BIJNOR: "Bijnor",
    BIKAPUR: "Bikapur",
    BILARI: "Bilari",
    BILARIAGANJ: "Bilariaganj",
    BILASPUR: "Bilaspur",
    BILGRAM: "Bilgram",
    BILHAUR: "Bilhaur",
    BILSANDA: "Bilsanda",
    BILSI: "Bilsi",
    BILTHRA: "Bilthra",
    BINAULI: "Binauli",
    BINAUR: "Binaur",
    BINDKI: "Bindki",
    BIRDPUR: "Birdpur",
    BIRPUR: "Birpur",
    BISALPUR: "Bisalpur",
    BISANDA_BUZURG: "Bisanda Buzurg",
    BISAULI: "Bisauli",
    BISENDA_BUZURG: "Bisenda Buzurg",
    BISHUNPUR_URF_MAHARAJGANJ: "Bishunpur Urf Maharajganj",
    BISWAN: "Biswan",
    BITHUR: "Bithur",
    BUDAUN: "Budaun",
    BUDHANA: "Budhana",
    BULANDSHAHR: "Bulandshahr",
    CAPTAINGANJ: "Captainganj",
    CHAIL: "Chail",
    CHAKIA: "Chakia",
    CHANDAULI: "Chandauli",
    CHANDAULI_DISTRICT: "Chandauli District",
    CHANDAUSI: "Chandausi",
    CHANDPUR: "Chandpur",
    CHANDUASI: "Chanduasi",
    CHARKHARI: "Charkhari",
    CHARTHAWAL: "Charthawal",
    CHHAPRAULI: "Chhaprauli",
    CHHARRA: "Chharra",
    CHHATA: "Chhata",
    CHHIBRAMAU: "Chhibramau",
    CHHITAUNI: "Chhitauni",
    CHHUTMALPUR: "Chhutmalpur",
    CHILLUPAR: "Chillupar",
    CHIRGAON: "Chirgaon",
    CHITRAKOOT: "Chitrakoot",
    CHITRAKOOT_DHAM: "Chitrakoot Dham",
    CHOPAN: "Chopan",
    CHUNAR: "Chunar",
    CHURK: "Churk",
    COLONELGANJ: "Colonelganj",
    DADRI: "Dadri",
    DALMAU: "Dalmau",
    DANKAUR: "Dankaur",
    DARAGANJ: "Daraganj",
    DARANAGAR: "Daranagar",
    DASNA: "Dasna",
    DATAGANJ: "Dataganj",
    DAURALA: "Daurala",
    DAYAL_BAGH: "Dayal Bagh",
    DEOBAND: "Deoband",
    DEOGARH: "Deogarh",
    DEORANIAN: "Deoranian",
    DEORIA: "Deoria",
    DERAPUR: "Derapur",
    DEWA: "Dewa",
    DHAMPUR: "Dhampur",
    DHANAURA: "Dhanaura",
    DHANGHATA: "Dhanghata",
    DHARAU: "Dharau",
    DHAURAHRA: "Dhaurahra",
    DIBAI: "Dibai",
    DIVRASAI: "Divrasai",
    DOHRIGHAT: "Dohrighat",
    DOMARIAGANJ: "Domariaganj",
    DOSTPUR: "Dostpur",
    DUDHI: "Dudhi",
    ETAH: "Etah",
    ETAWAH: "Etawah",
    ETMADPUR: "Etmadpur",
    FAIZABAD: "Faizabad",
    FARAH: "Farah",
    FARIDNAGAR: "Faridnagar",
    FARIDPUR: "Faridpur",
    FARRUKHABAD: "Farrukhabad",
    FATEHABAD: "Fatehabad",
    FATEHGANJ_WEST: "Fatehganj West",
    FATEHGARH: "Fatehgarh",
    FATEHPUR: "Fatehpur",
    FATEHPUR_BARABANKI: "Fatehpur (Barabanki)",
    FATEHPUR_CHAURASI: "Fatehpur Chaurasi",
    FATEHPUR_SIKRI: "Fatehpur Sikri",
    FIROZABAD: "Firozabad",
    FYZABAD: "Fyzabad",
    GAHLON: "Gahlon",
    GAHMAR: "Gahmar",
    GAINI: "Gaini",
    GAJRAULA: "Gajraula",
    GANGOH: "Gangoh",
    GANJ_DUNDAWARA: "Ganj Dundawara",
    GANJ_DUNDWARA: "Ganj Dundwara",
    GANJ_MURADABAD: "Ganj Muradabad",
    GARAUTHA: "Garautha",
    GARHI_PUKHTA: "Garhi Pukhta",
    GARHMUKTESAR: "Garhmuktesar",
    GARHWA: "Garhwa",
    GAURIGANJ: "Gauriganj",
    GAUTAM_BUDDHA_NAGAR: "Gautam Buddha Nagar",
    GAWAN: "Gawan",
    GHATAMPUR: "Ghatampur",
    GHAZIABAD: "Ghaziabad",
    GHAZIPUR: "Ghazipur",
    GHIROR: "Ghiror",
    GHORAWAL: "Ghorawal",
    GHOSI: "Ghosi",
    GOHAND: "Gohand",
    GOKUL: "Gokul",
    GOLA_BAZAR: "Gola Bazar",
    GOLA_GOKARANNATH: "Gola Gokarannath",
    GONDA: "Gonda",
    GONDA_ALIGARH: "Gonda (Aligarh)",
    GONDA_CITY: "Gonda City",
    GOPAMAU: "Gopamau",
    GORAKHPUR: "Gorakhpur",
    GOSAINGANJ: "Gosainganj",
    GOSHAINGANJ: "Goshainganj",
    GOVARDHAN: "Govardhan",
    GREATER_NOIDA: "Greater Noida",
    GULAOTHI: "Gulaothi",
    GUNNAUR: "Gunnaur",
    GURSAHAIGANJ: "Gursahaiganj",
    GURSARAI: "Gursarai",
    GYANPUR: "Gyanpur",
    HALDAUR: "Haldaur",
    HAMIRPUR: "Hamirpur",
    HANDIA: "Handia",
    HAPUR: "Hapur",
    HARAIPUR: "Haraipur",
    HARAIYA: "Haraiya",
    HARCHANDPUR: "Harchandpur",
    HARDOI: "Hardoi",
    HARDUAGANJ: "Harduaganj",
    HASANPUR: "Hasanpur",
    HASTINAPUR: "Hastinapur",
    HATA: "Hata",
    HATA_INDIA: "Hata (India)",
    HATHRAS: "Hathras",
    HULAS: "Hulas",
    IBRAHIMPUR: "Ibrahimpur",
    IGLAS: "Iglas",
    IKAUNA: "Ikauna",
    INDERGARH: "Indergarh",
    INDRAGARH: "Indragarh",
    ISLAMNAGAR: "Islamnagar",
    ISLAMNAGAR_BADAUN: "Islamnagar (Badaun)",
    ITAUNJA: "Itaunja",
    ITIMADPUR: "Itimadpur",
    JAGDISHPUR: "Jagdishpur",
    JAGNAIR: "Jagnair",
    JAHANABAD: "Jahanabad",
    JAHANABAD_PILIBHIT: "Jahanabad (Pilibhit)",
    JAHANGIRABAD: "Jahangirabad",
    JAHANGIRPUR: "Jahangirpur",
    JAINPUR: "Jainpur",
    JAIS: "Jais",
    JALALABAD: "Jalalabad",
    JALALI: "Jalali",
    JALALPUR: "Jalalpur",
    JALAUN: "Jalaun",
    JALESAR: "Jalesar",
    JANGHAI: "Janghai",
    JANSATH: "Jansath",
    JARWA: "Jarwa",
    JARWAL: "Jarwal",
    JASRANA: "Jasrana",
    JASWANTNAGAR: "Jaswantnagar",
    JAUNPUR: "Jaunpur",
    JEWAR: "Jewar",
    JHAJHAR: "Jhajhar",
    JHALU: "Jhalu",
    JHANSI: "Jhansi",
    JHINJHAK: "Jhinjhak",
    JHINJHANA: "Jhinjhana",
    JHUSI: "Jhusi",
    JIYANPUR: "Jiyanpur",
    JYOTIBA_PHULE_NAGAR: "Jyotiba Phule Nagar",
    KABRAI: "Kabrai",
    KACHHWA: "Kachhwa",
    KADAURA: "Kadaura",
    KADIPUR: "Kadipur",
    KAGAROL: "Kagarol",
    KAIMGANJ: "Kaimganj",
    KAIRANA: "Kairana",
    KAKORI: "Kakori",
    KAKRALA: "Kakrala",
    KALINAGAR: "Kalinagar",
    KALPI: "Kalpi",
    KALYANPUR: "Kalyanpur",
    KAMALGANJ: "Kamalganj",
    KAMPIL: "Kampil",
    KANDHLA: "Kandhla",
    KANNAUJ: "Kannauj",
    KANPUR: "Kanpur",
    KANPUR_DEHAT: "Kanpur Dehat",
    KANT: "Kant",
    KANTH: "Kanth",
    KAPTANGANJ: "Kaptanganj",
    KARA: "Kara",
    KARARI: "Karari",
    KARBIGWAN: "Karbigwan",
    KARCHANA: "Karchana",
    KARHAL: "Karhal",
    KASGANJ: "Kasganj",
    KATRA: "Katra",
    KAUSANI: "Kausani",
    KAUSHAMBI_DISTRICT: "Kaushambi District",
    KEMRI: "Kemri",
    KHADA: "Khada",
    KHAGA: "Khaga",
    KHAILAR: "Khailar",
    KHAIR: "Khair",
    KHAIRABAD: "Khairabad",
    KHALILABAD: "Khalilabad",
    KHANPUR: "Khanpur",
    KHARELA: "Kharela",
    KHARGUPUR: "Khargupur",
    KHARKHAUDA: "Kharkhauda",
    KHATAULI: "Khatauli",
    KHEKRA: "Khekra",
    KHERI: "Kheri",
    KHUDAGANJ: "Khudaganj",
    KHURJA: "Khurja",
    KHUTAR: "Khutar",
    KIRAKAT: "Kirakat",
    KIRAOLI: "Kiraoli",
    KIRATPUR: "Kiratpur",
    KISHANPUR: "Kishanpur",
    KISHANPUR_BARAL: "Kishanpur baral",
    KISHNI: "Kishni",
    KITHOR: "Kithor",
    KONCH: "Konch",
    KOPAGANJ: "Kopaganj",
    KOSI: "Kosi",
    KOTA: "Kota",
    KOTRA: "Kotra",
    KUCHESAR: "Kuchesar",
    KUDARKOT: "Kudarkot",
    KULPAHAR: "Kulpahar",
    KUNDA: "Kunda",
    KUNDARKHI: "Kundarkhi",
    KUNDARKI: "Kundarki",
    KURARA: "Kurara",
    KUREBHAR_SAIDKHANPUR: "Kurebhar,saidkhanpur",
    KUSHINAGAR: "Kushinagar",
    KUSMARA: "Kusmara",
    KUTHAUND: "Kuthaund",
    LAHARPUR: "Laharpur",
    LAKHIMPUR: "Lakhimpur",
    LAKHNA: "Lakhna",
    LALGANJ: "Lalganj",
    LALITPUR: "Lalitpur",
    LAMBHUA: "Lambhua",
    LAR: "Lar",
    LAWAR: "Lawar",
    LAWAR_KHAS: "Lawar Khas",
    LONI: "Loni",
    LUCKNOW: "Lucknow",
    LUCKNOW_DISTRICT: "Lucknow District",
    MACHHALI_SHAHAR: "Machhali Shahar",
    MACHHLISHAHR: "Machhlishahr",
    MADHOGANJ: "Madhoganj",
    MADHOGARH: "Madhogarh",
    MAGHAR: "Maghar",
    MAHABAN: "Mahaban",
    MAHARAJGANJ: "Maharajganj",
    MAHMUDABAD: "Mahmudabad",
    MAHOBA: "Mahoba",
    MAHOLI: "Maholi",
    MAHRAJGANJ: "Mahrajganj",
    MAHRAJGANJ_RAEBARELI: "Mahrajganj (Raebareli)",
    MAHRONI: "Mahroni",
    MAHUL: "Mahul",
    MAILANI: "Mailani",
    MAINPURI: "Mainpuri",
    MAJHUPUR: "Majhupur",
    MAKANPUR: "Makanpur",
    MALASA: "Malasa",
    MALIHABAD: "Malihabad",
    MANDAWAR: "Mandawar",
    MANIAR: "Maniar",
    MANIKPUR: "Manikpur",
    MANJHANPUR: "Manjhanpur",
    MANKAPUR: "Mankapur",
    MARAHRA: "Marahra",
    MARIAHU: "Mariahu",
    MATAUNDH: "Mataundh",
    MATHURA: "Mathura",
    MAU: "Mau",
    MAU_AIMA: "Mau Aima",
    MAU_AIMMA: "Mau Aimma",
    MAUDAHA: "Maudaha",
    MAURANWAN: "Mauranwan",
    MAURAWAN: "Maurawan",
    MAWANA: "Mawana",
    MAWAR: "Mawar",
    MEERUT: "Meerut",
    MEHDAWAL: "Mehdawal",
    MEHNAGAR: "Mehnagar",
    MEHNDAWAL: "Mehndawal",
    MILAK: "Milak",
    MILKIPUR: "Milkipur",
    MIRANPUR: "Miranpur",
    MIRANPUR_KATRA: "Miranpur Katra",
    MIRGANJ: "Mirganj",
    MIRZAPUR: "Mirzapur",
    MISRIKH: "Misrikh",
    MOHAN: "Mohan",
    MOHANPUR: "Mohanpur",
    MORADABAD: "Moradabad",
    MOTH: "Moth",
    MUBARAKPUR: "Mubarakpur",
    MUGHAL_SARAI: "Mughal Sarai",
    MUHAMMADABAD: "Muhammadabad",
    MUKTESHWAR: "Mukteshwar",
    MUNGRA_BADSHAHPUR: "Mungra Badshahpur",
    MUNSYARI: "Munsyari",
    MURADABAD: "Muradabad",
    MURADNAGAR: "Muradnagar",
    MURSAN: "Mursan",
    MUSAFIR_KHANA: "Musafir-Khana",
    MUSAFIRKHANA: "Musafirkhana",
    MUZAFFARNAGAR: "Muzaffarnagar",
    NADIGAON: "Nadigaon",
    NAGINA: "Nagina",
    NAGLA: "Nagla",
    NAGRAM: "Nagram",
    NAJIBABAD: "Najibabad",
    NAKUR: "Nakur",
    NANAUTA: "Nanauta",
    NANDGAON: "Nandgaon",
    NANPARA: "Nanpara",
    NARAULI: "Narauli",
    NARAURA: "Naraura",
    NARORA: "Narora",
    NAUGAMA: "Naugama",
    NAURANGPUR: "Naurangpur",
    NAUTANWA: "Nautanwa",
    NAWABGANJ: "Nawabganj",
    NAWABGANJ_BARABANKI: "Nawabganj (Barabanki)",
    NAWABGANJ_BAREILLY: "Nawabganj (Bareilly)",
    NEWARA: "Newara",
    NICHLAUL: "Nichlaul",
    NIGOH: "Nigoh",
    NIHTAUR: "Nihtaur",
    NIWARI: "Niwari",
    NIZAMABAD: "Nizamabad",
    NOIDA: "Noida",
    NURPUR: "Nurpur",
    OBRA: "Obra",
    ORAI: "Orai",
    ORAN: "Oran",
    PACHPERWA: "Pachperwa",
    PADRAUNA: "Padrauna",
    PAHASU: "Pahasu",
    PAIGAON: "Paigaon",
    PALI: "Pali",
    PALIA_KALAN: "Palia Kalan",
    PARAS_RAMPUR: "Paras Rampur",
    PARICHHA: "Parichha",
    PARICHHATGARH: "Parichhatgarh",
    PARSHADEPUR: "Parshadepur",
    PATHAKPURA: "Pathakpura",
    PATIALI: "Patiali",
    PATTI: "Patti",
    PAWAYAN: "Pawayan",
    PAYAGPUR: "Payagpur",
    PHALAUDA: "Phalauda",
    PHAPHAMAU: "Phaphamau",
    PHAPHUND: "Phaphund",
    PHARIHA: "Phariha",
    PHEONA: "Pheona",
    PHULPUR: "Phulpur",
    PICHHAURA: "Pichhaura",
    PIHANI: "Pihani",
    PILIBHIT: "Pilibhit",
    PILKHUA: "Pilkhua",
    PILKHUWA: "Pilkhuwa",
    PINAHAT: "Pinahat",
    PIPRAICH: "Pipraich",
    PIPRI: "Pipri",
    PRATAPGARH: "Pratapgarh",
    PRAYAGRAJ_ALLAHABAD: "Prayagraj (Allahabad)",
    PUKHRAYAN: "Pukhrayan",
    PURANPUR: "Puranpur",
    PURMAFI: "Purmafi",
    PURWA: "Purwa",
    QADIRGANJ: "Qadirganj",
    RABUPURA: "Rabupura",
    RADHA_KUND: "Radha Kund",
    RADHAKUND: "Radhakund",
    RAEBARELI: "Raebareli",
    RAJAPUR: "Rajapur",
    RAMKOLA: "Ramkola",
    RAMNAGAR: "Ramnagar",
    RAMPUR: "Rampur",
    RAMPURA: "Rampura",
    RANIPUR: "Ranipur",
    RANIPUR_BARSI: "Ranipur Barsi",
    RASRA: "Rasra",
    RASULABAD: "Rasulabad",
    RATH: "Rath",
    RAYA: "Raya",
    REHAR: "Rehar",
    RENUKOOT: "Renukoot",
    RENUKUT: "Renukut",
    REOTI: "Reoti",
    REOTIPUR: "Reotipur",
    RICHHA: "Richha",
    ROBERTSGANJ: "Robertsganj",
    RUDARPUR: "Rudarpur",
    RUDAULI: "Rudauli",
    RURA: "Rura",
    SABALPUR: "Sabalpur",
    SACHENDI: "Sachendi",
    SADABAD: "Sadabad",
    SADAT: "Sadat",
    SAFIPUR: "Safipur",
    SAHARANPUR: "Saharanpur",
    SAHASPUR: "Sahaspur",
    SAHASWAN: "Sahaswan",
    SAHAWAR: "Sahawar",
    SAHIBABAD: "Sahibabad",
    SAHPAU: "Sahpau",
    SAIDPUR: "Saidpur",
    SAKHANU: "Sakhanu",
    SAKIT: "Sakit",
    SALEMPUR: "Salempur",
    SALON: "Salon",
    SAMBHAL: "Sambhal",
    SAMTHAR: "Samthar",
    SANDI: "Sandi",
    SANDILA: "Sandila",
    SANT_KABIR_NAGAR: "Sant Kabir Nagar",
    SANT_RAVI_DAS_NAGAR: "Sant Ravi Das Nagar",
    SARAI_AKIL: "Sarai Akil",
    SARAI_EKDIL: "Sarai Ekdil",
    SARAI_MIR: "Sarai Mir",
    SARAULI: "Sarauli",
    SARDHANA: "Sardhana",
    SARILA: "Sarila",
    SARURPUR: "Sarurpur",
    SASNI: "Sasni",
    SATRIKH: "Satrikh",
    SAURIKH: "Saurikh",
    SECTOR: "Sector",
    SEOHARA: "Seohara",
    SHAHABAD: "Shahabad",
    SHAHGANJ: "Shahganj",
    SHAHI: "Shahi",
    SHAHJAHANPUR: "Shahjahanpur",
    SHAHPUR: "Shahpur",
    SHAMLI: "Shamli",
    SHAMSABAD: "Shamsabad",
    SHANKARGARH: "Shankargarh",
    SHERGARH: "Shergarh",
    SHERKOT: "Sherkot",
    SHIBNAGAR: "Shibnagar",
    SHIKARPUR: "Shikarpur",
    SHIKARPUR_BULANDSHAHR: "Shikarpur (Bulandshahr)",
    SHIKOHABAD: "Shikohabad",
    SHISHGARH: "Shishgarh",
    SHIVRAJPUR: "Shivrajpur",
    SHRAWASTI: "Shrawasti",
    SIDDHARTHNAGAR: "Siddharthnagar",
    SIDDHAUR: "Siddhaur",
    SIDHAULI: "Sidhauli",
    SIDHPURA: "Sidhpura",
    SIKANDARABAD: "Sikandarabad",
    SIKANDARPUR: "Sikandarpur",
    SIKANDRA: "Sikandra",
    SIKANDRA_RAO: "Sikandra Rao",
    SIKANDRABAD: "Sikandrabad",
    SIRATHU: "Sirathu",
    SIRSA: "Sirsa",
    SIRSAGANJ: "Sirsaganj",
    SIRSI: "Sirsi",
    SISAULI: "Sisauli",
    SISWA_BAZAR: "Siswa Bazar",
    SITAPUR: "Sitapur",
    SONBHADRA: "Sonbhadra",
    SORON: "Soron",
    SUAR: "Suar",
    SULTANPUR: "Sultanpur",
    SURIANWAN: "Surianwan",
    TAJPUR: "Tajpur",
    TALBAHAT: "Talbahat",
    TALGRAM: "Talgram",
    TANDA: "Tanda",
    TERHA: "Terha",
    THAKURDWARA: "Thakurdwara",
    THANA_BHAWAN: "Thana Bhawan",
    TIGRI: "Tigri",
    TIKAITNAGAR: "Tikaitnagar",
    TIKRI: "Tikri",
    TILHAR: "Tilhar",
    TILSAHRI: "Tilsahri",
    TINDWARI: "Tindwari",
    TITRON: "Titron",
    TORI_FATEHPUR: "Tori Fatehpur",
    TULSIPUR: "Tulsipur",
    TUNDLA: "Tundla",
    UGU: "Ugu",
    UJHANI: "Ujhani",
    UMRI: "Umri",
    UN: "Un",
    UNNAO: "Unnao",
    USAWAN: "Usawan",
    USEHAT: "Usehat",
    USKA: "Uska",
    UTRAULA: "Utraula",
    VARANASI: "Varanasi",
    VINDHYACHAL: "Vindhyachal",
    VRINDAVAN: "Vrindavan",
    WALTERGANJ: "Walterganj",
    WAZIRGANJ: "Wazirganj",
    YUSUFPUR: "Yusufpur",
    ZAFARABAD: "Zafarabad",
    ZAIDPUR: "Zaidpur",
    ZAMANIA: "Zamania",
  },
  UT: {
    ALMORA: "Almora",
    BAGESHWAR: "Bageshwar",
    BARKOT: "Barkot",
    BAZPUR: "Bazpur",
    BHIM_TAL: "Bhim Tal",
    BHOWALI: "Bhowali",
    BIRBHADDAR: "Birbhaddar",
    CHAKRATA: "Chakrata",
    CHAMOLI: "Chamoli",
    CHAMPAWAT: "Champawat",
    CLEMENT_TOWN: "Clement Town",
    DEHRADUN: "Dehradun",
    DEVAPRAYAG: "Devaprayag",
    DHARCHULA: "Dharchula",
    DOIWALA: "Doiwala",
    DUGADDA: "Dugadda",
    DWARAHAT: "Dwarahat",
    GARHWAL: "Garhwal",
    HALDWANI: "Haldwani",
    HARBATPUR: "Harbatpur",
    HARIDWAR: "Haridwar",
    JASPUR: "Jaspur",
    JOSHIMATH: "Joshimath",
    KALADHUNGI: "Kaladhungi",
    KALAGARH_PROJECT_COLONY: "Kalagarh Project Colony",
    KASHIPUR: "Kashipur",
    KHATIMA: "Khatima",
    KICHHA: "Kichha",
    KOTDWARA: "Kotdwara",
    LAKSAR: "Laksar",
    LANSDOWNE: "Lansdowne",
    LOHAGHAT: "Lohaghat",
    MANGLAUR: "Manglaur",
    MUSSOORIE: "Mussoorie",
    NAINI_TAL: "Naini Tal",
    NARENDRANAGAR: "Narendranagar",
    PAURI: "Pauri",
    PIPALKOTI: "Pipalkoti",
    PITHORAGARH: "Pithoragarh",
    RAIPUR: "Raipur",
    RAIWALA_BARA: "Raiwala Bara",
    RAMNAGAR: "Ramnagar",
    RANIKHET: "Ranikhet",
    RISHIKESH: "Rishikesh",
    ROORKEE: "Roorkee",
    RUDRAPRAYAG: "Rudraprayag",
    SITARGANJ: "Sitarganj",
    SRINAGAR: "Srinagar",
    SULTANPUR: "Sultanpur",
    TANAKPUR: "Tanakpur",
    TEHRI: "Tehri",
    TEHRI_GARHWAL: "Tehri-Garhwal",
    UDHAM_SINGH_NAGAR: "Udham Singh Nagar",
    UTTARKASHI: "Uttarkashi",
    VIKASNAGAR: "Vikasnagar",
  },
  WB: {
    AHMEDPUR: "Ahmedpur",
    AISTALA: "Aistala",
    AKNAPUR: "Aknapur",
    ALIPURDUAR: "Alipurduar",
    AMLAGORA: "Amlagora",
    AMTA: "Amta",
    AMTALA: "Amtala",
    ANDAL: "Andal",
    ARAMBAGH_COMMUNITY_DEVELOPMENT_BLOCK: "Arambagh community development block",
    ASANSOL: "Asansol",
    ASHOKNAGAR_KALYANGARH: "Ashoknagar Kalyangarh",
    BADKULLA: "Badkulla",
    BADURIA: "Baduria",
    BAGDOGRA: "Bagdogra",
    BAGNAN: "Bagnan",
    BAGULA: "Bagula",
    BAHULA: "Bahula",
    BAIDYABATI: "Baidyabati",
    BAKRESWAR: "Bakreswar",
    BALARAMPUR: "Balarampur",
    BALI_CHAK: "Bali Chak",
    BALLY: "Bally",
    BALURGHAT: "Balurghat",
    BAMANGOLA_COMMUNITY_DEVELOPMENT_BLOCK: "Bamangola community development block",
    BANESWAR: "Baneswar",
    BANGAON: "Bangaon",
    BANKRA: "Bankra",
    BANKURA: "Bankura",
    BANSBERIA: "Bansberia",
    BANSIHARI_COMMUNITY_DEVELOPMENT_BLOCK: "Bansihari community development block",
    BARABAZAR: "Barabazar",
    BARANAGAR: "Baranagar",
    BARASAT: "Barasat",
    BARDHAMAN: "Bardhaman",
    BARJORA: "Barjora",
    BARRACKPORE: "Barrackpore",
    BARUIPUR: "Baruipur",
    BASANTI: "Basanti",
    BASIRHAT: "Basirhat",
    BAWALI: "Bawali",
    BEGAMPUR: "Begampur",
    BELDA: "Belda",
    BELDANGA: "Beldanga",
    BELIATORE: "Beliatore",
    BERHAMPORE: "Berhampore",
    BHADRESWAR: "Bhadreswar",
    BHANDARDAHA: "Bhandardaha",
    BHATPARA: "Bhatpara",
    BIRBHUM_DISTRICT: "Birbhum district",
    BIRPARA: "Birpara",
    BISHNUPUR: "Bishnupur",
    BOLPUR: "Bolpur",
    BUDGE_BUDGE: "Budge Budge",
    CANNING: "Canning",
    CHAKAPARA: "Chakapara",
    CHAKDAHA: "Chakdaha",
    CHAMPADANGA: "Champadanga",
    CHAMPAHATI: "Champahati",
    CHAMPDANI: "Champdani",
    CHANDANNAGAR: "Chandannagar",
    CHANDRAKONA: "Chandrakona",
    CHITTARANJAN: "Chittaranjan",
    CHURULIA: "Churulia",
    CONTAI: "Contai",
    COOCH_BEHAR: "Cooch Behar",
    COSSIMBAZAR: "Cossimbazar",
    DAKSHIN_DINAJPUR_DISTRICT: "Dakshin Dinajpur district",
    DALKOLA: "Dalkola",
    DAM_DAM: "Dam Dam",
    DARJEELING: "Darjeeling",
    DAULATPUR: "Daulatpur",
    DEBAGRAM: "Debagram",
    DEBIPUR: "Debipur",
    DHANIAKHALI_COMMUNITY_DEVELOPMENT_BLOCK: "Dhaniakhali community development block",
    DHULAGARI: "Dhulagari",
    DHULIAN: "Dhulian",
    DHUPGURI: "Dhupguri",
    DIAMOND_HARBOUR: "Diamond Harbour",
    DIGHA: "Digha",
    DINHATA: "Dinhata",
    DOMJUR: "Domjur",
    DUBRAJPUR: "Dubrajpur",
    DURGAPUR: "Durgapur",
    EGRA: "Egra",
    FALAKATA: "Falakata",
    FARAKKA: "Farakka",
    FORT_GLOSTER: "Fort Gloster",
    GAIGHATA_COMMUNITY_DEVELOPMENT_BLOCK: "Gaighata community development block",
    GAIRKATA: "Gairkata",
    GANGADHARPUR: "Gangadharpur",
    GANGARAMPUR: "Gangarampur",
    GARUI: "Garui",
    GARULIA: "Garulia",
    GHATAL: "Ghatal",
    GIRIA: "Giria",
    GOBARDANGA: "Gobardanga",
    GOBINDAPUR: "Gobindapur",
    GOPALPUR: "Gopalpur",
    GOPINATHPUR: "Gopinathpur",
    GORUBATHAN: "Gorubathan",
    GOSABA: "Gosaba",
    GOSANIMARI: "Gosanimari",
    GURDAHA: "Gurdaha",
    GUSKHARA: "Guskhara",
    HABRA: "Habra",
    HALDIA: "Haldia",
    HALDIBARI: "Haldibari",
    HALISAHAR: "Halisahar",
    HARINDANGA: "Harindanga",
    HARINGHATA: "Haringhata",
    HARIPUR: "Haripur",
    HASIMARA: "Hasimara",
    HINDUSTHAN_CABLES_TOWN: "Hindusthan Cables Town",
    HOOGHLY_DISTRICT: "Hooghly district",
    HOWRAH: "Howrah",
    ICHAPUR: "Ichapur",
    INDPUR_COMMUNITY_DEVELOPMENT_BLOCK: "Indpur community development block",
    INGRAJ_BAZAR: "Ingraj Bazar",
    ISLAMPUR: "Islampur",
    JAFARPUR: "Jafarpur",
    JAIGAON: "Jaigaon",
    JALPAIGURI: "Jalpaiguri",
    JAMURIA: "Jamuria",
    JANGIPUR: "Jangipur",
    JAYNAGAR_MAJILPUR: "Jaynagar Majilpur",
    JEJUR: "Jejur",
    JHALIDA: "Jhalida",
    JHARGRAM: "Jhargram",
    JHILIMILI: "Jhilimili",
    KAKDWIP: "Kakdwip",
    KALAIKUNDA: "Kalaikunda",
    KALIAGANJ: "Kaliaganj",
    KALIMPONG: "Kalimpong",
    KALNA: "Kalna",
    KALYANI: "Kalyani",
    KAMARHATI: "Kamarhati",
    KAMARPUKUR: "Kamarpukur",
    KANCHRAPARA: "Kanchrapara",
    KANDI: "Kandi",
    KARIMPUR: "Karimpur",
    KATWA: "Katwa",
    KENDA: "Kenda",
    KESHABPUR: "Keshabpur",
    KHARAGPUR: "Kharagpur",
    KHARAR: "Kharar",
    KHARBA: "Kharba",
    KHARDAHA: "Khardaha",
    KHATRA: "Khatra",
    KIRNAHAR: "Kirnahar",
    KOLKATA: "Kolkata",
    KONNAGAR: "Konnagar",
    KRISHNANAGAR: "Krishnanagar",
    KRISHNAPUR: "Krishnapur",
    KSHIRPAI: "Kshirpai",
    KULPI: "Kulpi",
    KULTALI: "Kultali",
    KULTI: "Kulti",
    KURSEONG: "Kurseong",
    LALGARH: "Lalgarh",
    LALGOLA: "Lalgola",
    LOYABAD: "Loyabad",
    MADANPUR: "Madanpur",
    MADHYAMGRAM: "Madhyamgram",
    MAHIARI: "Mahiari",
    MAHISHADAL_COMMUNITY_DEVELOPMENT_BLOCK: "Mahishadal community development block",
    MAINAGURI: "Mainaguri",
    MANIKPARA: "Manikpara",
    MASILA: "Masila",
    MATHABHANGA: "Mathabhanga",
    MATIALI_COMMUNITY_DEVELOPMENT_BLOCK: "Matiali community development block",
    MATIGARA_COMMUNITY_DEVELOPMENT_BLOCK: "Matigara community development block",
    MEDINIPUR: "Medinipur",
    MEJIA_COMMUNITY_DEVELOPMENT_BLOCK: "Mejia community development block",
    MEMARI: "Memari",
    MIRIK: "Mirik",
    MOHANPUR_COMMUNITY_DEVELOPMENT_BLOCK: "Mohanpur community development block",
    MONOHARPUR: "Monoharpur",
    MURAGACHA: "Muragacha",
    MURI: "Muri",
    MURSHIDABAD: "Murshidabad",
    NABADWIP: "Nabadwip",
    NABAGRAM: "Nabagram",
    NADIA_DISTRICT: "Nadia district",
    NAGARUKHRA: "Nagarukhra",
    NAGRAKATA: "Nagrakata",
    NAIHATI: "Naihati",
    NAKSALBARI: "Naksalbari",
    NALHATI: "Nalhati",
    NALPUR: "Nalpur",
    NAMKHANA_COMMUNITY_DEVELOPMENT_BLOCK: "Namkhana community development block",
    NANDIGRAM: "Nandigram",
    NANGI: "Nangi",
    NAYAGRAM_COMMUNITY_DEVELOPMENT_BLOCK: "Nayagram community development block",
    NORTH_24_PARGANAS_DISTRICT: "North 24 Parganas district",
    ODLABARI: "Odlabari",
    PAIKPARA: "Paikpara",
    PANAGARH: "Panagarh",
    PANCHLA: "Panchla",
    PANCHMURA: "Panchmura",
    PANDUA: "Pandua",
    PANIHATI: "Panihati",
    PANSKURA: "Panskura",
    PARBATIPUR: "Parbatipur",
    PASCHIM_MEDINIPUR_DISTRICT: "Paschim Medinipur district",
    PATIRAM: "Patiram",
    PATRASAER: "Patrasaer",
    PATULI: "Patuli",
    PUJALI: "Pujali",
    PUNCHA_COMMUNITY_DEVELOPMENT_BLOCK: "Puncha community development block",
    PURBA_MEDINIPUR_DISTRICT: "Purba Medinipur district",
    PURULIA: "Purulia",
    RAGHUDEBBATI: "Raghudebbati",
    RAGHUNATHPUR: "Raghunathpur",
    RAIGANJ: "Raiganj",
    RAJMAHAL: "Rajmahal",
    RAJNAGAR_COMMUNITY_DEVELOPMENT_BLOCK: "Rajnagar community development block",
    RAMCHANDRAPUR: "Ramchandrapur",
    RAMJIBANPUR: "Ramjibanpur",
    RAMNAGAR: "Ramnagar",
    RAMPUR_HAT: "Rampur Hat",
    RANAGHAT: "Ranaghat",
    RANIGANJ: "Raniganj",
    RAYPUR: "Raypur",
    RISHRA: "Rishra",
    SAHAPUR: "Sahapur",
    SAINTHIA: "Sainthia",
    SALANPUR_COMMUNIT_DEVELOPMENT_BLOCK: "Salanpur community development block",
    SANKARPUR: "Sankarpur",
    SANKRAIL: "Sankrail",
    SANTIPUR: "Santipur",
    SANTOSHPUR: "Santoshpur",
    SANTURI_COMMUNITY_DEVELOPMENT_BLOCK: "Santuri community development block",
    SARENGA: "Sarenga",
    SERAMPORE: "Serampore",
    SERPUR: "Serpur",
    SHYAMNAGAR_WEST_BENGAL: "Shyamnagar, West Bengal",
    SILIGURI: "Siliguri",
    SINGUR: "Singur",
    SODPUR: "Sodpur",
    SOLAP: "Solap",
    SONADA: "Sonada",
    SONAMUKHI: "Sonamukhi",
    SONARPUR_COMMUNITY_DEVELOPMENT_BLOCK: "Sonarpur community development block",
    SOUTH_24_PARGANAS_DISTRICT: "South 24 Parganas district",
    SRIKHANDA: "Srikhanda",
    SRIRAMPUR: "Srirampur",
    SURI: "Suri",
    SWARUPNAGAR_COMMUNITY_DEVELOPMENT_BLOCK: "Swarupnagar community development block",
    TAKDAH: "Takdah",
    TAKI: "Taki",
    TAMLUK: "Tamluk",
    TARAKESWAR: "Tarakeswar",
    TITAGARH: "Titagarh",
    TUFANGANJ: "Tufanganj",
    TULIN: "Tulin",
    UCHALAN: "Uchalan",
    ULA: "Ula",
    ULUBERIA: "Uluberia",
    UTTAR_DINAJPUR_DISTRICT: "Uttar Dinajpur district",
    UTTARPARA_KOTRUNG: "Uttarpara Kotrung",
  }
}

module.exports = {
    COUNTRY,
    COUNTRY_ID_MAP,
    STATE,
    CITIES,
  };
  