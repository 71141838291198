import {
  CLEAR_SEARCH_RESULTS,
  CLEAR_SET_SELECTED_PROFILE,
  GET_PROFILE_INTERESTS,
  SET_PROFILE,
  SET_PROFILE_CONTACTS,
  SET_USER_NAME_LOOKUP,
  SET_SEARCH_RESULTS,
  SET_SELECTED_PROFILE,
  SET_SELECTED_PROFILE_CONTACTS
} from "../actions/actionType";

const INITIAL_STATE = {
  user: null,
};

const profileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        profile: action.profile,
      }
    case SET_PROFILE_CONTACTS:
      return {
        ...state,
        profileContacts: action.profileContacts,
      }
    case SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.profiles,
      }
    case CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: null,
      }
    case SET_SELECTED_PROFILE:
      return {
        ...state,
        selectedProfile: action.profile,
      }
    case SET_SELECTED_PROFILE_CONTACTS:
      return {
        ...state,
        setSelectedProfileContacts: action.profileContacts,
      }
    case CLEAR_SET_SELECTED_PROFILE:
      return {
        ...state,
        selectedProfile: null,
      }
    case GET_PROFILE_INTERESTS:
      return {
        ...state,
        profileInterests: action.interests,
      }
    default:
      return state;
  }
}



export default profileReducer;
