import styled from "styled-components";

import {
  CASTE,
  COMPLEXION,
  EDUCATION,
  EMPLOYED_IN,
  FAMILY_TYPE,
  FAMILY_STATUS,
  GENDER,
  MOTHER_TOUNGE,
  PHYSICAL_STATUS,
  PROFILE_CREATED,
  RELIGION,
  STARS,
  ANNUAL_INCOME,
  MARITAL_STATUS
} from "../../helpers/dbFields";

import { COUNTRY, STATE } from "../../helpers/countries";
import { titleCase } from "../../helpers/common";

const Content = styled.div`
  width: 100%;
`;

const Box = styled.div`
  background-color: #FFF;
  width: 30%;
  border: solid 1px #F8E9A1;
  float: left;
  display: inline;
  margin: 10px;
  min-height: 350px;
  
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px;
  img {
    width: 50%;
  }
`;

const BoxHeader = styled.div`
  background-color: #f76c6c;
  padding: 5px 10px;
  color: #FFF;
`;

const Line = styled.span`
  display: block;
  padding: 4px 10px;
`;

const LineBreak = styled.div`
  width: 90%;
  height: 1px;
  margin: 10px;
  border-bottom: 1px solid #f76c6c;
  opacity: 0.4;
`;

const PremiumLink = styled.a`
  font-size: 14px!important;
`;


const ProfileDetails = ({props}) => {
  const profile = props.profile;
  const profileContacts = props.profileContacts;
  
  const profileType = profile.premium ? 'Premium' : 'Normal';
  const profileCreated = profile.profile_created ? PROFILE_CREATED[profile.profile_created] : '';

  const amIPremium = props.me ? props.me.premium === true || props.me.premium === "true" ? true : false : true;

  const profilePhoto = profile.profile_pic ? profile.profile_pic : '';

  //Personal Information
  const displayName = profile.display_name ? profile.display_name : '';
  const fullName = profile.full_name ? profile.full_name : '';
  const gender = profile.gender ? GENDER[profile.gender]: '';
  const dob = profile.dob ? profile.dob.toDate().toDateString() : '';
  const complexion = profile.complexion ? COMPLEXION[profile.complexion] : '';
  const height = profile.height ? profile.height : '';
  const motherTounge = profile.mother_tounge ? MOTHER_TOUNGE[profile.mother_tounge] : '';
  const maritalStatus = profile.marital_status ? MARITAL_STATUS[profile.marital_status] : '';
  const physicalStatus = profile.physical_status ? PHYSICAL_STATUS[profile.physical_status] : '';
  const aboutMyself = profile.about_myself ? profile.about_myself : '';

  //Family and Religious Beliefs
  const familyType = profile.family_type ? FAMILY_TYPE[profile.family_type] : '';
  const familyStatus = profile.family_status ? FAMILY_STATUS[profile.family_status] : '';
  const aboutFamily = profile.about_family ? profile.about_family : '';
  const religion = profile.religion ? RELIGION[profile.religion] : '';
  const caste = profile.caste && profile.religion ? CASTE[profile.religion][profile.caste]: '';
  const star = profile.star ? STARS[profile.star] ? STARS[profile.star] : profile.star : '';
  const dosham = profile.dosham ? profile.dosham : '';
  
  //Education and Work
  const education = profile.education ? EDUCATION[profile.education] : '';
  const specialisation = profile.specialisation ? profile.specialisation : '';
  const employedIn = profile.employed ? EMPLOYED_IN[profile.employed] : '';
  const annualIncome = profile.annual_income ? ANNUAL_INCOME[profile.annual_income] ? ANNUAL_INCOME[profile.annual_income] : profile.annual_income : '';
  const countryOfWork = profile.country_of_work ? COUNTRY[profile.country_of_work] : '';
  const statesWorkList = STATE[profile.country_of_work];
  const stateOfWork = profile.state_of_work_name ? profile.state_of_work_name : profile.state_of_work ? statesWorkList[profile.state_of_work] : '';
  const cityOfWork = profile.city_of_work ? titleCase(profile.city_of_work.replace(/_/g , " ")) : "";
  const jobName = profile.job_name ? profile.job_name : '';
  const nameOfEmployer = profile.name_of_employer ? profile.name_of_employer : '';

  //Address and Contact details
  const address = profile.address ? profile.address : '';
  const mobile = amIPremium ? profileContacts ? profileContacts.mobile : '' : "Only premium member can view mobile number";
  const email = amIPremium ? profileContacts? profileContacts.email : '' : "Only premium member can view email";

  const country = profile.country ? COUNTRY[profile.country] : '';
  const statesList = STATE[profile.country];
  const state = profile.state_name ? profile.state_name : profile.state ? statesList[profile.state] : '';
  const city = profile.city ? titleCase(profile.city.replace(/_/g , " ")) : "";
  const zipCode = profile.zip_code ? profile.zip_code : '';  

  //Preferences
  const preferredAgeFrom = profile.preferred_age_from ? profile.preferred_age_from : '';
  const preferredAgeTo = profile.preferred_age_to ? profile.preferred_age_to : '';


  let preferredEducation = '';
  let preferredEmployment = '';
  if(Array.isArray(profile.preferred_education)) {
    preferredEducation = profile.preferred_education.map((emp)=> {
      if(emp == "ANY" || emp == "Any") return "Any";
      return EDUCATION[emp];
    }).join(", ");
  } else {
    preferredEducation = profile.preferred_education ? EDUCATION[profile.preferred_education] ? EDUCATION[profile.preferred_education] : profile.preferred_education : '';
  }

  if(Array.isArray(profile.preferred_employment)) {
    preferredEmployment = profile.preferred_employment.map((emp)=> {
      if(emp == "ANY" || emp == "Any") return "Any";
      return EMPLOYED_IN[emp];
    }).join(", ");
  } else {
    preferredEmployment = profile.preferred_employment ? EMPLOYED_IN[profile.preferred_employment] ? EMPLOYED_IN[profile.preferred_employment] : profile.preferred_employment : '';
  }
  
  const preferredHeightFrom = profile.preferred_height_from ? profile.preferred_height_from : '';
  const preferredHeightTo = profile.preferred_height_to ? profile.preferred_height_to : '';
  const starsPreferred = profile.stars_preferred ? profile.stars_preferred : '';
  const aboutPreferredPartner = profile.about_preferred_partner ? profile.about_preferred_partner : '';

  return (
    <Content>
      {profilePhoto && 
      <Box>
        <BoxHeader>Photo</BoxHeader>
        <ImageContainer>
          <img src={profilePhoto}></img>
        </ImageContainer>
      </Box>
      }
      <Box>
        <BoxHeader>Personal Information</BoxHeader>
        {displayName && <Line>Display Name: {displayName}</Line>}
        <Line>Full Name: {fullName}</Line>
        <Line>Marital Status: {maritalStatus}</Line>
        <Line>Gender: {gender}</Line>
        <Line>Mother Tounge: {motherTounge}</Line>
        <Line>Date of Birth: {dob}</Line>
        <Line>Height: {height}</Line>
        <Line>Complexion: {complexion}</Line>
        <Line>Physical Status: {physicalStatus}</Line>
        <Line>About my self: {aboutMyself}</Line>
      </Box>

      <Box>
        <BoxHeader>Family and Religious Beliefs</BoxHeader>
        <Line>Family status: {familyStatus}</Line>
        <Line>Family type: {familyType}</Line>
        <Line>About family: {aboutFamily}</Line>
        <LineBreak></LineBreak>
        <Line>Religion: {religion}</Line>
        <Line>Caste: {caste}</Line>
        <Line>Star: {star}</Line>
        <Line>Dosham: {dosham}</Line>
      </Box>

      <Box>
        <BoxHeader>Education and Work</BoxHeader>
        <Line>Education: {education}</Line>
        <Line>Specialisation: {specialisation}</Line>
        <LineBreak></LineBreak>
        <Line>Job: {jobName}</Line>
        <Line>Employed: {employedIn}</Line>
        <Line>Employer: {nameOfEmployer}</Line>
        <Line>Annual Income: {annualIncome}</Line>
        <Line>Country of work: {countryOfWork}</Line>
        <Line>State of work: {stateOfWork}</Line>
        <Line>City of work: {cityOfWork}</Line>
      </Box>

      <Box>
        <BoxHeader>Address and Contact information</BoxHeader>
        <Line>Address: {address}</Line>
        <Line>City: {city}</Line>
        <Line>State: {state}</Line>
        <Line>Country: {country}</Line>
        <Line>Pincode: {zipCode}</Line>
        <Line>Mobile Number: {mobile}</Line>
        <Line>Email: {email}</Line>
        <Line>{!amIPremium ? <PremiumLink href="/premium">Click here to upgrade to premium</PremiumLink> : ""}</Line>
      </Box>
      
      <Box>
        <BoxHeader>Personal Information</BoxHeader>
        <Line>Preferred age: {preferredAgeFrom} to {preferredAgeTo}</Line>
        <Line>Preferred height {preferredHeightFrom} to {preferredHeightTo}</Line>
        <Line>Preferred education: {preferredEducation}</Line>
        <Line>Preferred employment: {preferredEmployment}</Line>
        <Line>Star preferred: {starsPreferred}</Line>
        <Line>About preferred partner: {aboutPreferredPartner}</Line>
      </Box>
    </Content>
  )
};

export default ProfileDetails;
