import styled from "styled-components";
import { connect } from "react-redux";

import Dropdown from "../../Shared/Dropdown";
import TextBox from "../../Shared/TextBox";
import MultiCheckBox from "../../Shared/MultiCheckBox";

import { AGE, EDUCATION, EMPLOYED_IN, HEIGHT } from "../../../helpers/dbFields";

const Step6 = ({ setPayload }) => {
  return (
    <Content id="step">
      <label htmlFor="preferred_age_from">Preferred Age Range, From ***</label>
      <Dropdown 
        placeholder='Preferred Age Range, From'
        setPayload={ setPayload }
        name = "preferred_age_from"
        options= { AGE }
        errorMessage="Please select preferred age from"
        errorType="required"
      />
      <label htmlFor="preferred_age_to">Preferred Age Range, To ***</label>
      <Dropdown 
        placeholder='To'
        setPayload={ setPayload }
        name = "preferred_age_to"
        options= { AGE }
        errorMessage="Please select preferred age to"
        errorType="required"
      />
      <label htmlFor="preferred_height_from">Preferred Height Range, From ***</label>
      <Dropdown 
        placeholder='Preferred Height Range, From'
        setPayload={ setPayload }
        name = "preferred_height_from"
        options= { HEIGHT }
        errorMessage="Please select preferred height from"
        errorType="required"
      />
      <label htmlFor="preferred_height_to">Preferred Height Range, To ***</label>
      <Dropdown 
        placeholder='To'
        setPayload={ setPayload }
        name = "preferred_height_to"
        options= { HEIGHT }
        errorMessage="Please select preferred height to"
        errorType="required"
      />
      <label htmlFor="preferred_education">Preferred Education ***</label>
      <MultiCheckBox 
        placeholder='Preferred Education'
        setPayload={ setPayload }
        name = "preferred_education"
        options= { EDUCATION }
        errorMessage="Please select education"
        errorType="required"
      />
      <label htmlFor="preferred_employment">Preferred Employment ***</label>
      <MultiCheckBox 
        placeholder='Preferred Employment'
        setPayload={ setPayload }
        name = "preferred_employment"
        options= { EMPLOYED_IN }
        errorMessage="Please select employment"
        errorType="required"
      />
      <TextBox placeholder='Stars Not Preferred' setPayload={setPayload} name="stars_preferred" />
      <TextBox placeholder='Few lines about my partner' setPayload={setPayload} name="about_preferred_partner" />
    </Content>
  );
};

const Content = styled.div`
  width: 100%;
`;

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    profile: state.profileState.profile,
  };
};

const mapDipatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDipatchToProps)(Step6);
