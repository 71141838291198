import styled from "styled-components";
import { connect } from "react-redux";
import React, { useState } from 'react';

import { signInAPI, signOutAPI } from "../../store/actions";

import googleLogin from "../../images/login/btn_google_signin_light_normal_web.png";

const Google = (props) => {

  return (
    <Container>
        <img src={ googleLogin } onClick={ props.signInAPI } />
    </Container>
  );
};

const Container = styled.div`
  font-family: "Open Sans", sans-serif;
  color: #444444;
  justify-content: center;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #000;

  img {
    cursor: pointer;
  }
`;

const mapDipatchToProps = (dispatch) => ({
    signInAPI: () => dispatch(signInAPI())
});

export default connect(null, mapDipatchToProps)(Google);
