import styled from "styled-components";
import pic1 from "../images/stories/1.jpeg";
import pic2 from "../images/stories/2.jpeg";
import pic3 from "../images/stories/3.jpeg";

const OurStories = (props) => {
  return (
    <Containter>
      Vinayaka matrimony is a trend setter in finding a perfect partner for you. Being Kerala's number one matrimonial site you have with you the right information about the right person. The site provides all essential features for sharing and accessing genuine information.
      <h1>Success Stories</h1>
      <Section>
        <StoryPic src={pic1} />
        <StoryPic src={pic2} />
        <StoryPic src={pic3} />
      </Section>
    </Containter>
  );
};  

const Containter = styled.div`
  text-align: justify;
  width: 65%;
  padding-left: 60px;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0;
  }
`;

const Section = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;

  @media (max-width: 768px) {
    display: block;
  }
`;

const StoryPic = styled.img`
  padding: 4px 4px;
  background: #fff;
  box-shadow: 0 0 29px 0 rgb(18 66 101 / 8%);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  margin: 0 10px;
`;


export default OurStories;
