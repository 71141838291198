export const GET_PROFILE_INTERESTS = 'GET_PROFILE_INTERESTS';
export const SET_USER = 'SET_USER';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PROFILE_CONTACTS = 'SET_PROFILE_CONTACTS';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_PROFILES = 'SET_PROFILES';
export const SET_ADMIN_MENU = 'SET_ADMIN_MENU';
export const SET_SELECTED_PROFILE = 'SET_SELECTED_PROFILE';
export const SET_SELECTED_PROFILE_CONTACTS = 'SET_SELECTED_PROFILE_CONTACTS';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const CLEAR_SET_SELECTED_PROFILE = 'CLEAR_SET_SELECTED_PROFILE';
export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const SET_STATES = 'SET_STATES';
export const SET_CITIES = 'SET_CITIES';
