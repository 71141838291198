import React, { useState, useCallback, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";

import { generateDownload } from "../Shared/cropImage";
import PhotoList from '../Shared/PhotoList';
import styled from "styled-components";

export default function PhotoUpload(props) {
	const inputRef = React.useRef();

	const triggerFileSelectPopup = () => inputRef.current.click();

	const [image, setImage] = React.useState(null);
	const [croppedArea, setCroppedArea] = React.useState(null);
	const [crop, setCrop] = React.useState({ x: 0, y: 0 });
	const [zoom, setZoom] = React.useState(1);

	const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
		setCroppedArea(croppedAreaPixels);
	};

	const onSelectFile = (event) => {
		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]);
			reader.addEventListener("load", () => {
				setImage(reader.result);
			});
		}
	};

	const onDownload = () => {
		generateDownload(image, croppedArea, setImage, props);
		setImage(null);
	};
	const onCancel = () => {
		setImage(null);
	};

	return (
		<>
			<div className='container-buttons'>
				<input
					type='file'
					accept='image/*'
					ref={inputRef}
					onChange={onSelectFile}
					style={{ display: "none" }}
				/>
				{!image && <Button
					variant='contained'
					color='primary'
					onClick={triggerFileSelectPopup}
					style={{ margin: "10px 0 0 30px" }}
				>
					Choose
				</Button>}
			</div>
			<div className="container-photo-upload">
				<div className='container-cropper'>
					{image ? (
						<>
							<div className='cropper'>
								<Cropper
									image={image}
									crop={crop}
									zoom={zoom}
									aspect={2 / 3}
									onCropChange={setCrop}
									onZoomChange={setZoom}
									onCropComplete={onCropComplete}
								/>
							</div>

							<div className='slider'>
								<Slider
									min={1}
									max={3}
									step={0.1}
									value={zoom}
									onChange={(e, zoom) => setZoom(zoom)}
								/>
							</div>
						</>
					) : <PhotoList props={props} />}
				</div>

				<div className='container-buttons'>
					{image &&
						<>
							<Button variant='contained' color='primary' onClick={onDownload} style={{ marginRight: "10px" }}>
								Save
							</Button>
							<Button variant='contained' color='secondary' onClick={onCancel}>
								Cancel
							</Button>
						</>}
				</div>

			</div>

		</>
	);
}
