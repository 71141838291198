import styled from "styled-components";

const ContactUs = (props) => {
  return (
    <Containter id="contact">
      <div>
        <h2>Contact</h2>
      </div>
      <IFRAME src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3904.4779539649485!2d75.36029301525566!3d11.871770391583992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba43d4a5a5aeb57%3A0xa6d86351cc7d00f9!2sVINAYAKA%20FOUNDATION!5e0!3m2!1sen!2sin!4v1608140462530!5m2!1sen!2sin" frameborder="0" allowfullscreen=""></IFRAME>
      <Section>
        <div>
          <div>
            <div>
              <i></i>
              <h4>Location:</h4>
              <p>VINAYAKA FOUNDATION, MASCOT SQUARE, Nr.S.N.PARK, KANNUR 670001, Kerala, India</p>
            </div>

            <div>
              <i></i>
              <h4>Email:</h4>
              <p>foundationvinayaka@gmail.com</p>
            </div>

            <div>
              <i></i>
              <h4>Call:</h4>
              <p>+91 7907300686</p>
            </div>
          </div>
        </div>
      </Section>
      <div>
        <h3>Vinayaka Matrimony</h3>
        <p>
          VINAYAKA FOUNDATION <br/>
          MASCOT SQUARE, Nr.S.N.PARK <br/>
          KANNUR 670001 <br/>
          Kerala, India<br/><br/>
          <strong>Phone:</strong> +91 79073 00686<br/>
          <strong>Email:</strong> foundationvinayaka@gmail.com<br/>
        </p>
      </div>
    </Containter>
  );
};

const Containter = styled.div`
  text-align: justify;
`;

const Section = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;

  @media (max-width: 768px) {
    display: block;
  }
  
  div {
    padding-right: 20px;

    form {
      div {
        padding: 6px 0px;
        text-decoration: none;
        text-align: left;
        display: flex;
        flex-direction: row;

        input, textarea {
          width: 100%;
          height: calc(1em + .75rem + 1px);
          padding: .375rem .75rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #495057;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          border-radius: .25rem;
          transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }

        button {
          background: #f76c6c;
          border-color: #f76c6c;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          vertical-align: middle;
          border: 1px solid transparent;
          padding: .375rem .75rem;
          font-size: 1rem;
          line-height: 1.5;
          border-radius: .25rem;
          transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          margin: auto;
          width: 100%;
        }
      }
    }
  }
`;

const IFRAME = styled.iframe`
  border:0;
  width: 100%;
  height: 270px;
`;

export default ContactUs;
