import styled from "styled-components";
import { connect } from "react-redux";
import React, { useState } from 'react';

import TextBox from "../../Shared/TextBox";
import GenderBox from "../../Shared/GenderBox";
import DOBBox from "../../Shared/DOBBox";

const Step1 = ({ setPayload, profile, contacts, payload }) => {
  const { display_name, full_name, gender, dob } = profile;
  const mobile = contacts && contacts.mobile ? contacts.mobile : '';
  return (
    <Content id="step">
      <TextBox
        placeholder = 'Full Name ***'
        setPayload = {setPayload}
        name = "full_name"
        value = { display_name || full_name }
        errorMessage="Only alphabets are allowed in full name"
        errorType="onlyAlphabets"
      />
      <GenderBox
        setPayload={setPayload}
        name="gender"
        value={ gender }
      />
      <TextBox
        placeholder = 'Mobile ***'
        setPayload = {setPayload}
        name = "mobile"
        value = {mobile}
        errorMessage = "Mobile number format not valid"
        errorType = "phone"
      />
      <DOBBox
        setPayload={setPayload}
        name="dob"
        value={dob}
      />
    </Content>
  );
};

const Content = styled.div`
  width: 100%;
`;

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    profile: state.profileState.profile,
    contacts: state.profileState.profileContacts,
  };
};

const mapDipatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDipatchToProps)(Step1);
