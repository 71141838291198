import styled from "styled-components";
import pic1 from "../images/wellwishers/portfolio-2.jpeg";
import pic2 from "../images/wellwishers/portfolio-3.jpeg";
import pic3 from "../images/wellwishers/portfolio-4.jpeg";

const WellWishers = (props) => {
  return (
    <Containter>
      <h1>OUR WELL WISHERS</h1>
      <Section>
        <StoryPic src={pic1} />
        <StoryPic src={pic2} />
        <StoryPic src={pic3} />
      </Section>
    </Containter>
  );
};  

const Containter = styled.div`
  text-align: justify;

  h1 {
    text-align: center;
  }
`;

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: 768px) {
    display: block;
  }
`;

const StoryPic = styled.img`
  padding: 10px 10px;
  background: #fff;
  box-shadow: 0 0 29px 0 rgb(18 66 101 / 8%);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  margin: 0 28px;
  max-width: 300px;
  height: auto;
`;


export default WellWishers;
